import React, { useState } from 'react'

// Redux
import { ACTN_show_login, ACTN_hide_register, ACTN_set_loading } from '../../Redux/SettingsReducer';
import { ACTN_logged_in } from '../../Redux/UserReducer';
import { useDispatch } from 'react-redux';

// React Router DOM
import { Link } from 'react-router-dom';

// API
import { API_register_user } from '../../apis';

// Consts
import { password_Regex, email_regex, mobile_regex } from '../../Helpers/consts';

export default function LoginForm() {
    const [fName, setFName] = useState('');
    const [mName, setMName] = useState('');
    const [lName, setLName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [gender, setGender] = useState('');
    const [asInstructor, setAsInstructor] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');

    const [fNameValid, setFNameValid] = useState(false);
    const [mNameValid, setMNameValid] = useState(false);
    const [lNameValid, setLNameValid] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [mobileValid, setMobileValid] = useState(false);

    const [genderValid, setGenderValid] = useState(false);
    const [usernameValid, setUsernameValid] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);
    const [repasswordValid, setRepasswordValid] = useState(false);

    const [errors, setErrors] = useState([]);

    const [agree, setAgree] = useState(false);

    const dispatch = useDispatch();

    const handle_fname_change = e => {
        const input = e.target.value;

        setFName(input)

        if (input.length < 3) {
            setFNameValid(false);
        } else {
            setFNameValid(true);
        }
    }

    const handle_mname_change = e => {
        const input = e.target.value;

        setMName(input)

        if (input.length < 1) {
            setMNameValid(false);
        } else {
            setMNameValid(true);
        }
    }

    const handle_lname_change = e => {
        const input = e.target.value;

        setLName(input)

        if (input.length < 3) {
            setLNameValid(false);
        } else {
            setLNameValid(true);
        }
    }

    const handle_email_change = e => {
        const input = e.target.value;

        setEmail(input)

        if (!email_regex.test(input)) {
            setEmailValid(false);
        } else {
            setEmailValid(true);
        }
    }

    const handle_mobile_change = e => {
        const input = e.target.value;

        setMobile(input)

        if (!mobile_regex.test(input)) {
            setMobileValid(false);
        } else {
            setMobileValid(true);
        }
    }



    const handle_gender_change = e => {
        const input = e.target.value;

        setGender(input)

        if (input !== 'female' && input !== 'male') {
            setGenderValid(false);
        } else {
            setGenderValid(true);
        }
    }

    const handle_username_change = e => {
        const input = e.target.value;

        setUsername(input)

        if (input.length < 6) {
            setUsernameValid(false);
        } else {
            setUsernameValid(true);
        }
    }

    const handle_password_change = e => {
        const input = e.target.value;

        setPassword(input)

        // if (!password_Regex.test(input)) {
        //     setPasswordValid(false);
        // } else {
        setPasswordValid(true);
        // }
    }

    const handle_repassword_change = e => {

        if (password == '') {
            return
        }

        const input = e.target.value;

        setRepassword(input)

        if (input !== password) {
            setRepasswordValid(false);
        } else {
            setRepasswordValid(true);
        }
    }

    const handle_as_instructor = e => {
        setAsInstructor(!asInstructor)
    }

    const user_register = async () => {

        setErrors([]);
        const errors = [];

        // Validate name
        if (fName.length < 3 || mName.length < 1 || lName.length < 3) {
            errors.push('Invalid Name');
        }

        // Validate Email

        if (!email_regex.test(email.toLowerCase())) {
            errors.push('Invalid Email');
        }

        // Validate Mobile
        if (!mobile_regex.test(mobile)) {
            errors.push('Invalid Mobile Number');
        }


        // Validate gender
        if (gender !== 'female' && gender !== 'male') {
            errors.push('Select Your Gender');
        }

        // Validate username
        if (username.length < 6) {
            errors.push('Username must be at least 6 characters long');
        }

        // Validate Password

        // if (!password_Regex.test(password)) {
        //     errors.push('Password must contain upper and lower case letters, numbers, and symbols');
        // }

        // Password Match
        if (password !== repassword) {
            errors.push('Passwords do not Match');
        }

        if (errors.length > 0) {
            setErrors(errors);
            return;
        }

        const data = {
            fName, mName, lName, email, mobile, gender, username, password, asInstructor
        }

        await API_register_user(data)
            .then(res => {

                setErrors([]);

                if (typeof res == 'string') {
                    setErrors([res]);
                    return;
                }

                const user_data = {
                    user_id: res['user_id'],
                    user_f_name: fName,
                    user_name: `${fName} ${mName} ${lName}`,
                    user_role: 'student',
                    user_email: email,
                    user_mobile: mobile,
                    user_gender: gender,
                    personal_token: res['personal_token'],
                    last_used_at: res['last_used_at'],
                    profile_completed: false
                };

                dispatch(ACTN_logged_in({ user_data }));

                dispatch(ACTN_hide_register());
            })
    }

    return (

        <form action="" method="post" className='needs-validation login_form' style={{ overflow: 'auto', }} >
            <div className='form_inner' style={{ top: '100px', overflow: 'auto', }}>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                    <span className='text-danger'></span>
                    <p className="text-center text-dark">Create an Account</p>
                    <span className='text-danger display-3 hand' onClick={() => dispatch(ACTN_hide_register())}>&times;</span>
                </div>

                <div className="form-group m-3">
                    <label htmlFor="fName">First Name</label>
                    <input type="text" id="fName" className={`form-control is-${fNameValid ? 'valid' : 'invalid'}`} value={fName} onChange={handle_fname_change} required minLength={3} />
                </div>

                <div className="form-group m-3">
                    <label htmlFor="mName">Middle Name</label>
                    <input type="text" id="mName" className={`form-control is-${mNameValid ? 'valid' : 'invalid'}`} value={mName} onChange={handle_mname_change} required minLength={1} />
                </div>

                <div className="form-group m-3">
                    <label htmlFor="lName">Last Name</label>
                    <input type="text" id="lName" className={`form-control is-${lNameValid ? 'valid' : 'invalid'}`} value={lName} onChange={handle_lname_change} required minLength={3} />
                </div>

                <div className="form-group m-3">
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" className={`form-control is-${emailValid ? 'valid' : 'invalid'}`} value={email} onChange={handle_email_change} required />
                </div>

                <div className="form-group m-3">
                    <label htmlFor="mobile">Mobile</label>
                    <input type="text" id="mobile" className={`form-control is-${mobileValid ? 'valid' : 'invalid'}`} value={mobile} onChange={handle_mobile_change} required />
                </div>

                <div className="form-group m-3">
                    <label htmlFor="gender">Gender</label>
                    <select className={`form-control is-${genderValid ? 'valid' : 'invalid'}`} value={gender} onChange={handle_gender_change} required>
                        <option value=''>Select Gender</option>
                        <option value='female'>Female</option>
                        <option value='male'>Male</option>
                    </select>
                </div>

                <div className='form-group m-3'>
                    <label>
                        <input type='checkbox' className='form-check-input' onChange={handle_as_instructor} />
                        <span className='mx-2'>Join as Instructor </span>
                    </label>
                </div>

                <fieldset className="form-group m-3 border bg-light shadow-sm">
                    <legend className="small m-2">Login Information</legend>

                    <div className="form-group m-3 ">
                        <label htmlFor="username">Username</label>
                        <input type="text" id="username" className={`form-control is-${usernameValid ? 'valid' : 'invalid'}`} value={username} onChange={handle_username_change} required />
                    </div>

                    <div className="form-group m-3">
                        <label htmlFor="paasswrd">Password</label>
                        <input type="password" name="password" className={`form-control is-${passwordValid ? 'valid' : 'invalid'}`} autoComplete='false' value={password} onChange={handle_password_change} />
                    </div>

                    <div className="form-group m-3">
                        <label htmlFor="repaasswrd">Re-enter Password</label>
                        <input type="password" id="repaasswrd" className={`form-control is-${repasswordValid ? 'valid' : 'invalid'}`} autoComplete='false' value={repassword} onChange={handle_repassword_change} />
                    </div>
                </fieldset>

                <div className="form-group m-3 text-danger">
                    {errors.map((err, i) => <p key={i}>{err}</p>)}
                </div>

                <input type='checkbox' id='agree' className='ms-5 me-2' onClick={() => { setAgree(!agree) }} />
                <label htmlFor='agree'>I have read and agree both:</label>
                <div className='small d-flex justify-content-center align-items-center'>
                    <Link to='/terms-conditions'>Terms and Conditions</Link>
                    <span className='mx-3'>|</span>
                    <Link to='/privacy-policy'>Privacy Policy</Link>
                </div>

                {
                    agree &&
                    <div className="btn-group m-3 d-flex justify-content-between">
                        <input type="button" className="btn btn-primary" value='Create Account' onClick={user_register} />
                    </div>
                }

                <div className="btn-group m-3 d-flex justify-content-between">
                    <button type="button" className="btn btn-warning" onClick={() => { dispatch(ACTN_show_login()) }}>Have Account</button>
                </div>

                <div className="btn-group m-3 d-flex justify-content-between">
                    <button type="button" className="btn btn-danger" onClick={() => dispatch(ACTN_hide_register())}>Cancel</button>
                </div>
            </div>
        </form>
    )
}
