// React Bootstrap
import { Button, Modal, Table } from 'react-bootstrap';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ACTN_show_slot_details } from '../Redux/SettingsReducer';

export default function BookedSlotDetails() {

    const dispatch = useDispatch();

    const { slot_details } = useSelector(state => state.settings);
    const { isTeacher } = useSelector(state => state.user);

    const { course_name, teacher_name, student_name, hours, rate, book_date } = slot_details[0];

    return (
        <Modal show='true' centered>

            <Modal.Header>
                <h2>{course_name}</h2>
            </Modal.Header>

            <Modal.Body>
                <div>
                    {
                        !isTeacher &&
                        <p>Teacher: {teacher_name}</p>
                    }
                    {
                        isTeacher &&
                        <p>Student: {student_name}</p>
                    }
                    <p>Booked Hours: {hours}</p>
                    <p>Hour Rate: {rate}</p>
                </div>

                <h5>Booking Details</h5>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Hours</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            slot_details.map(({ slot_date, booked_slots }, i) =>
                                <tr key={i}>
                                    <td>{slot_date}</td>
                                    <td>{booked_slots}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>

                <footer className='text-end'>
                    <small>Booked on: <span>{book_date}</span></small>
                </footer>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => dispatch(ACTN_show_slot_details(false))}>Close</Button>
            </Modal.Footer>

        </Modal>
    )
}
