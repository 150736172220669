import React, { useEffect, useState } from 'react'

// Libraries
import { Alert, Button, Col, Container, Form, FormControl, FormFloating, FormSelect, Modal, Row, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { ACTN_update_courses } from '../../Redux/CoursesReducer';
import { ACTN_set_loading } from '../../Redux/SettingsReducer';

// APIs
import { API_update_course } from '../../apis';

export default function TableCourses({ childCategories }) {


    const { courses } = useSelector(state => state.courses);

    const [courseName, setCourseName] = useState('');

    const [courseCategory, setCourseCategory] = useState(0);

    const [courseCategoryName, setCourseCategoryName] = useState('');

    const [courseDescription, setCourseDescription] = useState('');

    const [showModal, setShowModal] = useState(false);

    const [activeCourse, setActiveCourse] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {

    }, [courses]);

    const handle_update_course = course => {
        setActiveCourse(course);
        setCourseName(course.course_name);
        setCourseCategory(course.category_id);
        setCourseCategoryName(course.category_name);
        setCourseDescription(course.course_description);
        setShowModal(true);
    }

    const handle_save_changes = async () => {

        if (courseName == '') {
            toast.error('Course name is required');
            return;
        }

        if (courseCategory == 0) {
            toast.error('Category name is required');
            return;
        }


        dispatch(ACTN_set_loading(true));
        setShowModal(false);

        const new_course = {
            course_id: activeCourse.course_id,
            course_name: courseName,
            course_category: courseCategory,
            course_description: courseDescription,
            category_name: courseCategoryName,
        }

        await API_update_course(new_course).then(res => {

            dispatch(ACTN_set_loading(false));

            if (res) {
                dispatch(ACTN_update_courses(new_course));
            }
        })
    }


    const handle_category_changed = e => {
        setCourseCategory(e.target.value);
        setCourseCategoryName(e.target.options[e.target.selectedIndex].text)
    }


    return (
        <Container className='bg-cool_gray  p-3 shadow rounded-3'>

            {/* Update Course Model */}
            <Modal show={showModal ? 'show' : ''} backdrop centered >

                <Modal.Header>
                    <Modal.Title>Update Course</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form className='was-validated'>
                        <FormFloating className='my-3'>
                            <FormControl required type="text" placeholder="Course Name" value={courseName} onChange={e => setCourseName(e.target.value)} />
                            <label>Name</label>
                        </FormFloating>

                        <FormFloating className='my-3'>
                            <FormSelect required placeholder="Course Name" value={courseCategory} onChange={handle_category_changed}>
                                {
                                    childCategories.map(category => {
                                        return <option key={category.category_id} value={category.category_id}>{category.category_name}</option>
                                    })
                                }
                            </FormSelect>
                            <label>Category</label>
                        </FormFloating>

                        <FormFloating className='my-3'>
                            <FormControl as="textarea" placeholder="Course Description" value={courseDescription} onChange={e => setCourseDescription(e.target.value)} />
                            <label>Description</label>
                        </FormFloating>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                    <Button variant="primary" onClick={handle_save_changes}>Save Changes</Button>
                </Modal.Footer>

            </Modal>

            <Container className='bg-white p-4 mt-3 shadow-sm rounded-3'>
                <h2>Courses</h2>
            </Container>

            {
                courses.length == 0 ?
                    <Alert className='my-4'>Add your first course</Alert>
                    :
                    <Container className='bg-white p-2 mt-3 shadow-sm rounded-3'>
                        {courses.map((course, index) => {
                            return (
                                <div key={index} className={`p-2 mb-3 ${index % 2 == 0 ? 'bg-light_gray' : 'bg-light'} rounded-3`} onClick={() => handle_update_course(course)}>
                                    <Row>
                                        <Col>{course.course_name}</Col>
                                        <Col>{course.category_name || '-'}</Col>
                                    </Row>
                                    {
                                        course.course_description &&
                                        <Row>
                                            <Col><small className='text-secondary'>{course.course_description}</small></Col>
                                        </Row>
                                    }
                                </div>
                            )
                        })}
                    </Container>
            }
        </Container>


    )
}
