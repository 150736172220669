import { useEffect, useState } from 'react'
import { Button, Container, FormControl, FormLabel, ListGroup, ListGroupItem, Modal } from 'react-bootstrap';

// APIs
import { API_get_teacher_skills, API_new_skill_request } from '../../apis';

// Redux
import { useSelector } from 'react-redux';

// Helpers
import { hlpr_all_required_pass } from '../../Helpers/Functions';

// react-toastify
import { toast } from 'react-toastify';

export default function Skills() {

    const [skills, setSkills] = useState([]);
    const [pendingSkills, setPendingSkills] = useState([]);
    const [newSkill, setNewSkill] = useState({});
    const [showAddSkillModal, setShowAddSkillModal] = useState(false);
    const [error, setError] = useState('');

    const { id } = useSelector(state => state.user)

    useEffect(() => {
        fnc_get_teacher_skills()
    }, []);

    const fnc_get_teacher_skills = async () => {
        await API_get_teacher_skills(id)
            .then(res => {
                // console.log('res', res)
                Array.isArray(res.skills) && setSkills(res.skills)
                Array.isArray(res.pending_skills) && setPendingSkills(res.pending_skills)
            })
    }

    const handle_skill_rate_changed = e => {
        const k = e.target.name;
        const v = e.target.value;

        setNewSkill({ ...newSkill, ...{ [k]: v } })
    }

    const handle_request_new_skill = async () => {

        if (undefined == newSkill.name || undefined == newSkill.rate || !hlpr_all_required_pass(newSkill)) {
            setError('Add your new skill and its rate')
            return
        }
        setError('')

        await API_new_skill_request({ ...newSkill, teacher_id: id })
            .then(res => {
                if (res) {
                    toast.success('Request Sent Successfully')
                } else {
                    toast.error('Request did not sent, please try again!')
                }
                setShowAddSkillModal(false);
                fnc_get_teacher_skills();
            })
    }


    return (
        <>
            {/* Add New Skill Request Modal */}
            <Modal show={showAddSkillModal} onHide={() => setShowAddSkillModal(false)}>
                <Modal.Header closeButton>
                    Add New Skill Request
                </Modal.Header>
                <Modal.Body>
                    <div className='my-3'>
                        <FormLabel>Skill Name</FormLabel>
                        <FormControl name='name' value={newSkill.name || ''} onChange={handle_skill_rate_changed} />
                    </div>
                    <div className='my-3'>
                        <FormLabel>Skill Rate</FormLabel>
                        <FormControl name='rate' value={newSkill.rate || ''} onChange={handle_skill_rate_changed} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <p className='text-danger'>{error}</p>
                    <Button onClick={handle_request_new_skill}>Send Request</Button>
                </Modal.Footer>
            </Modal>

            <h2 className='d-flex justify-content-between'>
                <span className='my_title'>Active Skills</span>
                <Button onClick={() => setShowAddSkillModal(true)}> + Add New Skill</Button>
            </h2>

            <div className='border p-2 my-3'>
                <ListGroup>
                    <ListGroupItem className='list-group-item-primary d-flex justify-content-between'>
                        <span>Course Name</span>
                        <span>Rate</span>
                    </ListGroupItem>
                    {
                        skills.map(({ course_name, course_id, private_rate }, i) =>
                            <ListGroupItem key={i} className='d-flex justify-content-between'>
                                <span>{course_name}</span>
                                <span>{private_rate}</span>
                            </ListGroupItem>
                        )
                    }
                </ListGroup>
            </div>

            {/* Pending Approve Skills */}
            <div className='border p-2 my-3'>
                <h2 className='my_title'>Pending Approve Skills</h2>
                <ListGroup>
                    <ListGroupItem className='list-group-item-primary d-flex justify-content-between'>
                        <span>Course Name</span>
                        <span>Rate</span>
                    </ListGroupItem>
                    {
                        pendingSkills.map(({ skill, rate }, i) =>
                            <ListGroupItem key={i} className='d-flex justify-content-between'>
                                <span>{skill}</span>
                                <span>{rate}</span>
                            </ListGroupItem>
                        )
                    }
                </ListGroup>
            </div>
        </>
    )
}
