import React, { useEffect, useState } from 'react'

// Skeleton
import Skeleton from './Skeleton';

// react-bootstrap
import { Container, Tab, Tabs } from 'react-bootstrap';

// React Router DOM
import { Link, useParams } from 'react-router-dom';

// APIs
import { API_prepare_booking } from '../apis';

// Redux
import { useSelector } from 'react-redux';

// Components
import TeacherCard from './TeacherCard';
import Reviews from './Teacher/Reviews';
import BookHours from './Teacher/BookHours';
import LoginRequest from './User/LoginRequest';
import IsAdminNotify from './Admin/IsAdminNotify';

export default function OurTeacher() {

    const [isTeacher, setIsTeacher] = useState(true);
    const [ready, setReady] = useState(false);

    const [teacher, setTeacher] = useState({});
    const [skills, setSkills] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [courses, setCourses] = useState([]);
    const [slots, setSlots] = useState([]);

    const teacher_id = useParams()['id']; // teacher id

    const student_id = useSelector(state => state.user.id) // student id

    const { loggedIn, isAdmin } = useSelector(state => state.user);

    useEffect(() => {
        if (student_id != 0) {
            fnc_get_teacher();
        }
    }, []);

    const fnc_get_teacher = async () => {

        await API_prepare_booking(teacher_id, student_id)
            .then(res => {

                // console.log('res:::->>>', res);

                if (res === false || res.info[0] == undefined) {
                    setIsTeacher(false);
                    return;
                }

                setReady(true);
                setIsTeacher(true);

                const teacher_res = res.info[0];
                const skills_res = res.skills;
                const courses_res = res.courses;
                const reviews_res = res.reviews;
                const slots_res = res.slots;

                setTeacher(teacher_res);
                setSkills(skills_res);
                setCourses(courses_res);
                setReviews(reviews_res);
                setSlots(slots_res);

            })
    }

    if (student_id == 0) {
        return <LoginRequest msg='Login First to Book Hours with the Teacher' />
    }


    if (!isTeacher) {
        return (<h2 className='alert alert-danger'>No Teacher Found, <Link className='alert-link' to={'/teachers'}>Go Back</Link> and select a teacher from the list</h2>)
    }

    return (
        <Container fluid className=' '>
            <h2 className='txt-orange text-center my-3'>Book Your Next Skill with</h2>
            {
                isAdmin &&
                <IsAdminNotify msg='This is only a simulation.' />
            }
            <div>
                {
                    !ready ?
                        [...Array(2)].map((_, i) => <Skeleton key={i} />) :
                        <Container>
                            <TeacherCard teacher={teacher} courses={courses} show_enroll_button={false} />
                            <Tabs defaultActiveKey={slots ? 'book_hours' : 'reviews'} >
                                <Tab eventKey='reviews' title='Reviews'>
                                    <Reviews reviews={reviews} />
                                </Tab>
                                {
                                    slots &&
                                    <Tab eventKey='book_hours' title='Book Hours'>
                                        {
                                            loggedIn ?
                                                <BookHours teacher={teacher} skills={skills} availableSlots={slots} />
                                                :
                                                <LoginRequest msg='Login First to Book Hours with the Teacher' />
                                        }
                                    </Tab>
                                }
                            </Tabs>

                        </Container>
                }
            </div>
        </Container>
    )
}