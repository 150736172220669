import React, { useEffect, useState } from 'react'

// React Router DOM
import { useParams } from 'react-router-dom'

// APIs
import { API_get_booking_details } from '../../apis';


export default function Booking() {

    const [booking, setBooking] = useState({});

    const { booking_id } = useParams();

    useEffect(() => {
        fnc_get_booking_details();
    }, [])

    const fnc_get_booking_details = async () => {
        await API_get_booking_details(booking_id).then(res => {

            if (res) {
                setBooking(res);
            }
        })
    }
    return (
        <div>
            <h2>Booking</h2>
            <p>Review: {booking['Review']}</p>
            <p>Admin Notes: {booking['Admin Notes']}</p>
            <p>Book Date: {booking['Book Date']}</p>
            <p>Book Notes: {booking['Book Notes']}</p>
            <p>Booking ID: {booking['Booking ID']}</p>
            <p>Course: {booking['Course']}</p>
            <p>Email: {booking['Email']}</p>
            <p>Gender: {booking['Gender']}</p>
            <p>Hour Rate: {booking['Hour Rate']}</p>
            <p>Hours Booked: {booking['Hours Booked']}</p>
            <p>Mobile: {booking['Mobile']}</p>
            <p>Name: {booking['Name']}</p>
            <p>Nationality: {booking['Nationality']}</p>
            <p>Review: {booking['Review']}</p>
            <p>Schedule: {booking['Schedule']}</p>
            <p>Status: {booking['Status']}</p>
            <p>Teacher: {booking['Teacher']}</p>
        </div>
    )
}
