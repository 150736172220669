import React from 'react'

// Redux
import { useDispatch } from 'react-redux';
import { ACTN_show_login } from '../../Redux/SettingsReducer';

// Images
import login from '../../assets/imgs/login_2.jpg';

export default function LoginRequest({ msg }) {

    const dispatch = useDispatch();

    return (
        <div className='p-5 d-flex flex-column justify-content-center align-items-center' onClick={() => dispatch(ACTN_show_login())}>
            <div className='w-100'>
                <img src={login} className='d-block mw-100' />
            </div>
            <button className='btn text-primary'>
                {msg}
            </button>
        </div>
    )
}
