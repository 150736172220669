import { useState, useEffect } from 'react'

// react-bootstrap
import { Alert, Button, Container, ListGroup, ListGroupItem, Modal, Spinner } from 'react-bootstrap'

// redux
import { useDispatch, useSelector } from 'react-redux';
import { ACTN_set_loading } from '../../Redux/SettingsReducer';

// APIs
import { API_get_signature_list_all_fawry_payment_cards, API_get_signature_delete_fawry_payment_cards } from '../../apis';
import { FAWRY_API_list_all_cards, FAWRY_API_delete_member_payment_card } from '../../apis/fawry';

// images
import { cards_imgs } from '../../assets/imgs';

// icons
import { MdDelete } from 'react-icons/md';
import { BsFillCreditCard2BackFill } from 'react-icons/bs';


export default function PaymentCards() {
    const [myCards, setMyCards] = useState([]);
    const [addingCard, setAddingCard] = useState(false);
    const [cardToDelete, setCardToDelete] = useState({});
    const [showDeleteCardModal, setShowDeleteCardModal] = useState(false);

    const member_id = useSelector(state => state.user.id);

    const merchantCode = process.env.REACT_APP_FAWRY_ACCNO;

    const dispatch = useDispatch();

    let fawryCardTokenURL = '';
    let returnURL = '';

    if (window.location.hostname == 'localhost') {
        returnURL = process.env.REACT_APP_CARD_TOKEN_RETURN_URL_DEV;
        fawryCardTokenURL = process.env.REACT_APP_FAWRY_CARD_TOKEN_URL_DEV;
    } else {
        returnURL = process.env.REACT_APP_CARD_TOKEN_RETURN_URL_PROD;
        fawryCardTokenURL = process.env.REACT_APP_FAWRY_CARD_TOKEN_URL_PROD;
    }

    const cardTokenUrl = `${fawryCardTokenURL}?accNo=${merchantCode}&customerProfileId=${member_id}&returnUrl=${returnURL}/${member_id}`;


    useEffect(() => {
        fnc_get_member_payment_cards();
    }, []);

    const fnc_get_member_payment_cards = async () => {
        dispatch(ACTN_set_loading(true));
        await API_get_signature_list_all_fawry_payment_cards(member_id)
            .then(async signature => {
                dispatch(ACTN_set_loading(false));
                await FAWRY_API_list_all_cards(signature, merchantCode, member_id)
                    .then(cards_res => {
                        if (cards_res && cards_res.statusCode == 200) {
                            setMyCards(cards_res.cards)
                        }
                    })
            })
    }

    const handle_delete_card_clicked = (token, firstSixDigits, lastFourDigits) => {
        setCardToDelete({ member_id, token, firstSixDigits, lastFourDigits })
        setShowDeleteCardModal(true);
    }

    const handle_confirm_delete_card_clicked = async () => {

        await API_get_signature_delete_fawry_payment_cards(cardToDelete.token, member_id)
            .then(async signature => {
                await FAWRY_API_delete_member_payment_card(signature, merchantCode, member_id, cardToDelete.token)
                    .then(res => {
                        // console.log('FAWRY_API_delete_member_payment_card res', res)
                        // setShowDeleteCardModal(false);
                        // fnc_get_member_payment_cards()
                        // toast.success('Card Deleted Successfully.');
                        // toast.error('Card was not deleted. Reload the page and try again or contact system adminstrator');
                    })
            })

    }

    const handle_card_added_clicked = () => {
        setAddingCard(false);
        fnc_get_member_payment_cards()
    }

    return (
        <>
            {/* Modal adding card in progress */}
            <Modal show={addingCard} fullscreen className='alert-primary'>
                <Modal.Header className='alert-primary'>Adding Card </Modal.Header>
                <Modal.Body className='d-flex flex-column align-items-center justify-content-center alert-primary'>
                    <p className='text-center'>After adding your new card, click the button below.</p>
                    <Spinner animation='border' variant='primary'></Spinner>
                </Modal.Body>
                <Modal.Footer className='alert-primary'>
                    <Button variant='primary' onClick={handle_card_added_clicked}>DONE</Button>
                </Modal.Footer>
            </Modal>

            {/* Modal Delete Card Confirm */}
            <Modal show={showDeleteCardModal}>
                <Modal.Header>Confirm Delete a Card</Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete the card {cardToDelete.firstSixDigits}******{cardToDelete.lastFourDigits}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setShowDeleteCardModal(false)}>Close</Button>
                    <Button variant='danger' onClick={handle_confirm_delete_card_clicked}>Yes, delete the Card</Button>
                </Modal.Footer>
            </Modal>

            <h2>Manage My Cards</h2>
            <div>
                <img src={cards_imgs.master} />
                <img src={cards_imgs.visa} />
                <img src={cards_imgs.meeza} />
            </div>
            <Alert className='d-flex align-items-center'>
                <BsFillCreditCard2BackFill className='me-3' />
                <Alert.Link onClick={() => setAddingCard(true)} href={cardTokenUrl} target='_blank' >Add a new card</Alert.Link>
            </Alert>

            {
                myCards.length == 0 &&
                <div>
                    <Alert variant='warning'>
                        <p>
                            You have no cards on your account
                        </p>
                    </Alert>
                </div>
            }
            <ListGroup>
                {
                    myCards.map(({ token, firstSixDigits, lastFourDigits }) => {
                        let card = cards_imgs.master;

                        if (firstSixDigits.substr(0, 1) == '4') {
                            card = cards_imgs.visa;
                        } else if (firstSixDigits.substr(0, 2) == '50') {
                            card = cards_imgs.meeza;
                        }

                        return <ListGroupItem key={token} className='d-flex justify-content-between'>
                            <div>
                                <img src={card} height='24' alt='card_logo' className='me-3' />
                                <span>
                                    {firstSixDigits}******{lastFourDigits}
                                </span>
                            </div>
                            <Button variant='danger' onClick={() => handle_delete_card_clicked(token, firstSixDigits, lastFourDigits)}>
                                <MdDelete />
                            </Button>
                        </ListGroupItem>
                    })
                }
            </ListGroup>
        </>
    )
}
