import { createSlice } from "@reduxjs/toolkit";

const name = 'chat';
const initialState = {
    show_chat_modal: false,
    chat_details: {}
}

const ChatReducer = createSlice({
    name,
    initialState,
    reducers: {
        ACTN_show_chat_modal: (state, action) => {
            state.show_chat_modal = action.payload
        },
        ACTN_set_chat_details: (state, action) => {
            // console.log('ACTN_set_chat_details', action.payload)
            state.chat_details = { ...state.chat_details, ...action.payload }
            // console.log('state.chat_details', state.chat_details)
        }
    }
});

export default ChatReducer.reducer;

export const {
    ACTN_show_chat_modal,
    ACTN_set_chat_details
} = ChatReducer.actions;