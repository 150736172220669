import { useEffect, useState } from 'react'

// APIs
import { API_get_student_all_bookings, API_get_booking_details, API_cancel_booking, API_confirm_booking_payment } from '../../apis';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ACTN_set_loading, ACTN_show_slot_details, ACTN_set_slot_details } from '../../Redux/SettingsReducer';

// React Bootstrap
import { Alert, Button, Container, FormLabel, Modal, Table, FormGroup, FormControl } from 'react-bootstrap';

// React Toastify
import { toast } from 'react-toastify';

// React Router DOM
import { Link, useNavigate } from 'react-router-dom';

// Images
import female from '../../assets/imgs/female.png'
import male from '../../assets/imgs/male.png'

// Icons
import { AiFillEye, AiOutlineCheck } from 'react-icons/ai'
import { HiPencilAlt } from 'react-icons/hi'
import { MdFreeCancellation, MdOutlineDownloadDone } from 'react-icons/md'
import { GiMoneyStack } from 'react-icons/gi'

// Helpers
import { hlpr_sort_dates } from '../../Helpers/Functions';
import FawryForm from '../Payments/FawryForm';

// Components
import PaymentForm from '../Payments/PaymentForm';

export default function AllBookings() {

    const [bookings, setBookings] = useState([]);

    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showPaymentConfirmModal, setShowPaymentConfirmModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);

    const [paymentFormParams, setPaymentFormParams] = useState({})

    const [bookingToConfirm, setBookingToConfirm] = useState({});
    const [bookingToCancel, setBookingToCancel] = useState({});

    const [reload, setReload] = useState(true);
    const [startCountdown, setStartCountdown] = useState(false);
    const [countdown, setCountdown] = useState(5);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const student_id = useSelector(state => state.user.id)

    const { isAdmin } = useSelector(state => state.user)

    useEffect(() => {

        if (!isAdmin) {
            if (reload) {

                dispatch(ACTN_set_loading(true));

                fnc_list_all_bookings();
            }
        }

        if (startCountdown) {
            setTimeout(() => {
                // console.log('countdown', countdown)
                setCountdown(countdown - 1)
                if (countdown <= 0) {
                    setStartCountdown(false)
                }
            }, 1000)
        }


    }, [reload, startCountdown, countdown])

    const fnc_list_all_bookings = async () => {
        await API_get_student_all_bookings(student_id).then(res => {

            // console.log('AllBookings->>>res', res)
            const sorted_bookings = hlpr_sort_dates(res)

            setBookings(sorted_bookings);

            dispatch(ACTN_set_loading(false));

            setReload(false)

        })

    }

    const handle_view_slot_info = async (booking_id, booking_detail_id) => {
        dispatch(ACTN_set_loading(true))

        await API_get_booking_details(booking_id, booking_detail_id, student_id).then(res => {
            dispatch(ACTN_set_loading(false))

            if (res == false) {
                return
            }

            dispatch(ACTN_set_slot_details(res));
            dispatch(ACTN_show_slot_details(true));

        })
    }

    const handle_reschedule_booking = (course_id, teacher_id) => {
        // console.log('course_id, teacher_id', course_id, teacher_id)
        navigate(`/teacher/${teacher_id}/${course_id}`)
    }

    const handle_show_cancel_booking_modal = booking_to_cancel => {
        setBookingToCancel(booking_to_cancel)
        setShowCancelModal(true)
        setCountdown(5)
        setStartCountdown(true)
    }

    const handle_close_cancel_booking_modal = () => {
        setBookingToCancel({})
        setShowCancelModal(false)
        setStartCountdown(false)
        setCountdown(5)
    }

    const handle_cancel_note_changed = e => {
        setBookingToCancel({ ...bookingToCancel, notes: e.target.value })
    }

    const handle_cancel_booking = async () => {

        await API_cancel_booking({ ...bookingToCancel, student_id })
            .then(res => {
                handle_close_cancel_booking_modal()
                setReload(!reload)
            })
    }

    const handel_show_payment_modal = booking => {

        const { booking_id, teacher_id, teacher_name, course_id, course_name, hours, rate } = booking;
        const totalFees = rate * hours;

        setPaymentFormParams({
            showPaymentModal,
            setShowPaymentModal,
            student_id,
            update: true,
            booking_id, rate, hours, teacher_id, teacher_name, course_id, course_name, hours, rate, totalFees
        })

        setShowPaymentModal(true);

    }

    const handle_show_payment_confirm_modal = ({ booking_id }) => {
        setBookingToConfirm({ ...bookingToConfirm, ...{ booking_id } });
        setShowPaymentConfirmModal(true);
    }

    const handle_close_payment_confirm_modal = () => {
        setBookingToConfirm({})
        setShowPaymentConfirmModal(false)
    }

    const handle_confirm_payment_field_changed = e => {
        const k = e.target.name;
        const v = e.target.value;

        setBookingToConfirm({ ...bookingToConfirm, ...{ [k]: v } });
    }

    const handle_confirm_payment = async () => {

        if (!bookingToConfirm.reference_code || bookingToConfirm.reference_code == '') {
            setBookingToConfirm({ ...bookingToConfirm, ...{ error: 'Reference Code is Required!' } });
            return;
        }

        const confirm_data = { ...bookingToConfirm, ...{ student_id } };
        // console.log(confirm_data)

        dispatch(ACTN_set_loading(true));

        await API_confirm_booking_payment(confirm_data).then(res => {
            dispatch(ACTN_set_loading(false));

            if (res) {
                const updated_bookings = bookings.map(booking => {

                    if (booking.booking_id == bookingToConfirm.booking_id) {
                        booking.status_name = 'initiated';
                    }

                    return booking;
                });

                setBookings(updated_bookings);
                fnc_handle_confirm_pending();
            }
        })

        handle_close_payment_confirm_modal()
    }

    const fnc_handle_confirm_pending = () => toast.success('Payment details has been received, we will contact you as soon as payment is confirmed.')


    const Status = ({ booking }) => {
        const { status_name, booking_id } = booking;

        switch (status_name) {
            case 'pending':
                return <span className='hand text-primary' onClick={() => handel_show_payment_modal(booking)}> <GiMoneyStack /> Confirm Payment</span>
            case 'initiated':
                return <span className='hand txt-orange' onClick={fnc_handle_confirm_pending}> <MdOutlineDownloadDone /> Pending</span>
            case 'paid':
                return <span className='txt-forest_green'> <AiOutlineCheck /> Paid </span>
        }
    }

    if (bookings.length == 0) {
        return <Container fluid>
            <h2>My Bookings</h2>
            <Alert><h2>You don't have any bookings, <Link to={'/teachers'} >book your first skill now</Link></h2></Alert>
        </Container>
    }

    return (

        <Container fluid>

            {/* Payment Options Modal */}
            {
                showPaymentModal &&
                <PaymentForm paymentFormParams={paymentFormParams} />
            }

            {/* Cancel Modal */}
            <Modal show={showCancelModal}>
                <Modal.Header>Confirm Cancel Booking</Modal.Header>
                <Modal.Body>
                    <p>You are about to cancel the booking for Course <strong>{bookingToCancel.course_name}</strong> with <strong>{bookingToCancel.teacher_name}</strong></p>
                    <strong>Reason for cancellation</strong>
                    <FormControl as='textarea' onChange={e => handle_cancel_note_changed(e)} value={bookingToCancel.notes}></FormControl>
                </Modal.Body>
                <Modal.Footer>
                    <p>Are you sure?</p>
                    <Button variant='danger' onClick={handle_cancel_booking} disabled={(countdown > 0 || undefined == bookingToCancel.notes || bookingToCancel.notes == '') && 'disabled'}>{countdown > 0 ? `${countdown} Yes, Cancel Booking` : 'Yes, Cancel Booking'}</Button>
                    <Button onClick={handle_close_cancel_booking_modal}>No, do not cancel</Button>
                </Modal.Footer>
            </Modal>

            {/* Payment Confirm Modal */}
            <Modal show={showPaymentConfirmModal}>
                <Modal.Header>Add Payment Confirmation Reference</Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <FormLabel>Payment Reference Code <small className='txt-tomato'>*</small></FormLabel>
                        <FormControl name='reference_code' onChange={handle_confirm_payment_field_changed} />
                    </FormGroup>
                    <p className='txt-tomato'>{bookingToConfirm.error}</p>
                    <FormGroup>
                        <FormLabel>Notes</FormLabel>
                        <FormControl as='textarea' name='notes' onChange={handle_confirm_payment_field_changed} />
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handle_confirm_payment}>Add</Button>
                    <Button onClick={handle_close_payment_confirm_modal} variant='secondary'>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Payment Details Modal */}


            <h2>My Bookings</h2>



            <Table responsive>
                <thead>
                    <tr>
                        <td>Course</td>
                        <td>Teacher</td>
                        <td>Hours</td>
                        <td>Hour Rate</td>
                        <td>Booked on</td>
                        <td>View</td>
                        <td>Reschedule</td>
                        <td>Payment Status</td>
                        <td>Cancel</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        bookings.map(booking => {
                            let { booking_id, course_id, teacher_id, course_name, teacher_name, first_day_id, first_date, last_date, status_name, gender, hours, rate, book_date } = booking

                            first_date = new Date(first_date);
                            last_date = new Date(last_date);
                            const today = new Date();


                            return (
                                <tr key={booking_id}>
                                    <td>{course_name}</td>
                                    <td
                                        className='d-flex flex-row align-items-center hand'
                                        onClick={() => navigate(`/teacher/${teacher_id}`)}
                                    >
                                        <img src={gender == 'Female' ? female : male}
                                            className='img-32' alt='teacher' />
                                        {teacher_name}
                                    </td>
                                    <td>{hours}</td>
                                    <td>{rate}</td>
                                    <td>{book_date}</td>
                                    <td>
                                        <span className='hand text-secondary' onClick={() => { handle_view_slot_info(booking_id, first_day_id) }} >
                                            <AiFillEye />
                                        </span>
                                    </td>
                                    <td>
                                        {
                                            last_date > today && <span className='hand text-primary mx-3' onClick={() => { handle_reschedule_booking(course_id, teacher_id) }} >
                                                <HiPencilAlt />
                                            </span>
                                        }

                                    </td>
                                    <td>
                                        <Status booking={booking} />
                                    </td>
                                    <td>
                                        {
                                            first_date > today && <span className='hand txt-tomato' onClick={() => handle_show_cancel_booking_modal({ booking_id, course_name, teacher_name })}> <MdFreeCancellation /></span>
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>

        </Container >
    )
}
