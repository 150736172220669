import { useState, useEffect } from 'react'
import { Container, FormGroup, Button } from 'react-bootstrap'

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

// Helpers
import { Hours } from "../../Helpers/Hours";
import { date_add, formatDate } from '../../Helpers/Functions';

// APIs
import { API_get_teacher_availability, API_update_slots } from '../../apis';

// Libraries
import { toast } from 'react-toastify';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ACTN_set_loading } from '../../Redux/SettingsReducer';

export default function Availability() {

    const [availableSchedule, setAvailableSchedule] = useState([]);

    const teacher_id = useSelector(state => state.user.id);
    const { app_settings: { payment__general_section_expire_after_days, booking__scheduale_section_availabilty_long } } = useSelector(state => state.settings)

    // console.log('payment__general_section_expire_after_days', payment__general_section_expire_after_days)

    const dispatch = useDispatch();

    useEffect(() => {
        fnc_get_available_schedule();
    }, [payment__general_section_expire_after_days, booking__scheduale_section_availabilty_long]);

    const fnc_get_available_schedule = async () => {
        await API_get_teacher_availability(teacher_id).then(availableScheduleData => {
            // console.log('availableScheduleData:::->>>', availableScheduleData)
            setAvailableSchedule(availableScheduleData);


            if (availableScheduleData.length > 0) {
                availableScheduleData.sort((a, b) => {
                    return new Date(a.slot_date) - new Date(b.slot_date);
                })
            }

            const full_schedule = [];

            const start_after = parseInt(payment__general_section_expire_after_days) + 1;

            // console.log('start_after: ', start_after);

            for (let i = parseInt(payment__general_section_expire_after_days) + 1; i <= parseInt(booking__scheduale_section_availabilty_long); i++) {
                const [d] = formatDate(date_add(i)).split(' ');

                let slot = availableScheduleData.find(av => av.slot_date == d);

                if (slot == undefined) {
                    slot = { slot_date: d, slot_times: null, busy_slots: null, booked_slots: null };
                }

                full_schedule.push(slot)
            }

            setAvailableSchedule(full_schedule);

        });
    }

    const handle_save_availability = async () => {

        dispatch(ACTN_set_loading(true));

        const slots = JSON.stringify(availableSchedule);

        await API_update_slots(teacher_id, slots).then(res => {

            dispatch(ACTN_set_loading(false));

            if (res == true) {
                toast('Saved Successfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }

    const fnc_sort_array = arr => {
        const sorted = arr.sort((a, b) => {
            return a - b;
        }
        );
        return sorted
    };

    const handle_change = (was_selected, date, hour) => {

        if (!date) {
            debugger
        }

        let new_availableSchedule = availableSchedule.map(({ slot_date, slot_times, booked_slots, busy_slots }) => {

            if (slot_date == date) {

                let times_arr = slot_times ? slot_times.split(',') : [];

                if (was_selected) {
                    times_arr = times_arr.filter(time => time != hour)
                } else {
                    times_arr.push(hour)
                    times_arr = fnc_sort_array(times_arr);
                }

                const newSet = new Set(times_arr)
                times_arr = Array.from(newSet);

                slot_times = times_arr.toString();

            }
            return { slot_date, slot_times, booked_slots, busy_slots }

        })

        setAvailableSchedule(new_availableSchedule);
    };

    const handle_available_all_day = () => {

    }

    return (
        <>
            <FormGroup className='border shadow-sm py-2 px-3 mx-0 my-3 rounded'>
                <div className='my-3 d-flex justify-content-between align-items-center'>
                    <span>Available Hours</span>
                    <Button variant='primary' onClick={handle_save_availability}>Save</Button>
                </div>

                <Swiper
                    slidesPerView={3}
                    spaceBetween={5}
                    draggable={false}
                    pagination={{
                        clickable: false,
                    }}
                    modules={[Pagination]}
                    className="rounded d-flex flex-row"
                >
                    {
                        // console.log('availableSchedule....', availableSchedule)
                    }
                    {
                        availableSchedule.map(({ slot_date, slot_times, booked_slots, busy_slots }, i) => {

                            const booked_slots_arr = booked_slots ? booked_slots.split(',') : [];
                            const busy_slots_arr = busy_slots ? busy_slots.split(',') : []

                            const blocked = Array.from(new Set([...booked_slots_arr, ...busy_slots_arr]))

                            const times = slot_times ? slot_times.split(',') : [];

                            return (
                                <SwiperSlide key={i} className='d-flex flex-grow-1 flex-column pb-3 bg-light my-2 rounded'>
                                    <h4>{slot_date}</h4>
                                    <Button onClick={handle_available_all_day}>ALL</Button>
                                    <div>

                                        {
                                            Hours.map(([hour, name]) => {

                                                let selected = 'bg-light_grey txt-light_dim_gray',
                                                    active = false,
                                                    booked = false;

                                                // debugger
                                                // const disabled = booked_times.indexOf(hour) >= 0 ? 'disabled' : '';
                                                if (times.indexOf(hour) >= 0) {
                                                    selected = 'bg-primary text-light';
                                                    active = true;
                                                }

                                                if (blocked.indexOf(hour) >= 0) {
                                                    selected = 'bg-disabled-striped text-dark';
                                                    active = false;
                                                }

                                                return (
                                                    <div
                                                        className={`mb-2 px-2 border d-flex flex-column justify-content-start ${booked !== false && 'opacity-50'} ${selected}`}
                                                        style={{ height: '50px' }}
                                                        key={hour}
                                                        onClick={() => handle_change(active, slot_date, hour)}
                                                        data-active={active}
                                                        data-date={slot_date}
                                                        data-hour={hour}
                                                    >
                                                        <small>
                                                            {name}
                                                        </small>

                                                        <small className='align-self-center txt-tomato'>
                                                            {booked}
                                                        </small>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </FormGroup>
        </ >

    )
}
