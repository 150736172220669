import { useState, useEffect } from "react";

// React Bootstrap
import { Container, Tabs, Tab } from "react-bootstrap";

// Components
import TeacherSummary from "../../Components/Admin/TeacherSummary";
import TeacherInfo from "../../Components/Admin/TeacherInfo";
import StudentsList from "../../Components/StudentsList";
import TeacherAvailability from "../../Components/Admin/TeacherAvailability";
import TeacherSkills from "../../Components/Admin/TeacherSkills";
import TeacherAdministrate from "../../Components/Admin/TeacherAdministrate";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { ACTN_set_current_teacher } from '../../Redux/UserReducer'

// APIs
import { API_get_teacher } from '../../apis';

// Libraries
import { useParams, useNavigate } from "react-router-dom";
import Skeleton from '../../Components/Skeleton';

const Teacher = () => {
    const [personalInfo, setPersonalnfo] = useState({});

    const [availableSchedule, setAvailableSchedule] = useState([]);

    const [skills, setSkills] = useState({});

    const [pendingSkills, setPendingSkills] = useState({});

    const [students, setStudents] = useState([]);

    // const teacher = useSelector(state => state.user.current_teacher);
    const { isAdmin, current_teacher } = useSelector(state => state.user);

    const teacher_id = useParams()['id'];

    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        fnc_get_teacher();
    }, []);


    const fnc_get_teacher = async () => {
        await API_get_teacher(teacher_id)
            .then(res => {

                // console.log('API_get_teacher: res->>>', res)

                if (!res) {
                    navigate('/admin/teachers')
                }

                //  personal_info
                const personal_info = res.personal_info;
                // skills
                const skills = res.skills
                // pending skills
                const pending_skills = res.pending_skills
                // reviews
                const reviews = res.reviews
                // students
                const students_list = res.students
                // slots
                const slots = res.slots;

                setPersonalnfo(personal_info)

                dispatch(ACTN_set_current_teacher(personal_info))

                setAvailableSchedule(slots);

                setStudents(students_list);

                setSkills(skills);

                setPendingSkills(pending_skills);
            });
    }

    if (current_teacher == undefined) {
        return (
            [...Array(1)].map((_, i) => <Skeleton key={i} />)
        )
    } else {
        return (
            <Container>
                <div>
                    <TeacherSummary teacher={personalInfo} />
                </div>

                <Tabs defaultActiveKey='info' >

                    <Tab eventKey='info' title='Info' className="fw-bolder">
                        <TeacherInfo teacher={personalInfo} />
                    </Tab>

                    <Tab eventKey='students' title='Students' className="fw-bolder">
                        <StudentsList students={students} />
                    </Tab>

                    <Tab eventKey='availability' title='Availability' className="fw-bolder">
                        <TeacherAvailability availableScheduleData={availableSchedule} />
                    </Tab>

                    <Tab eventKey='skills' title='Skills'>
                        <TeacherSkills skillsData={skills} pendingSkillsData={pendingSkills} />
                    </Tab>
                    {
                        isAdmin &&
                        <Tab eventKey='admin' title='Administrate'>
                            <TeacherAdministrate teacher={personalInfo} />
                        </Tab>
                    }
                </Tabs>
            </Container>
        );
    }
};

export default Teacher;