import { useState, useEffect } from 'react';

// react-bootstrap
import { Alert, Button, InputGroup, Col, Container, Form, FormCheck, FormControl, FormGroup, FormLabel, Row, FormFloating } from "react-bootstrap";

// Redux
import { useDispatch } from 'react-redux';
import { ACTN_set_loading, ACTN_set_app_settings } from '../../../Redux/SettingsReducer';

// APIs
import { API_save_settings } from '../../../apis'

// react-toastify
import { toast } from 'react-toastify';


export default function Home({ current_settings }) {

    const [settings, setSettings] = useState({})

    const dispatch = useDispatch();


    useEffect(() => {
        setSettings(current_settings);
    }, [current_settings])


    const handle_settings_changed = e => {
        const k = e.target.id;
        // console.log(k)
        let v;

        if (e.target.type == 'checkbox') {
            v = e.target.checked ? 'yes' : 'no';
        } else {
            v = e.target.value;
        }

        // console.log(e.target.checked)
        setSettings({ ...settings, [k]: v })
    }

    const handle_save_setting = async () => {
        // console.log('settings', settings)
        dispatch(ACTN_set_loading(true));

        await API_save_settings(settings).then(res => {
            dispatch(ACTN_set_loading(false));

            if (res) {
                dispatch(ACTN_set_app_settings(settings));
                toast.success('Settings Updated')
            }

        });
    }

    // console.log('current_settings in HOME', current_settings)
    if (!current_settings || Object.keys(current_settings).length == 0) {
        return
    }

    return (
        <Container className='py-3'>


            <h2>Fawry Payment</h2>

            <div className='d-md-flex align-items-end mb-3 pb-2 border-bottom'>
                <Form.Check
                    className='me-3'
                    type="switch"
                    id="payment__fawry_section_enable_fawry"
                    value='yes'
                    label="Enable Fawry Payment"
                    checked={settings.payment__fawry_section_enable_fawry == 'yes' && true}
                    onChange={handle_settings_changed}
                />
            </div>
            {
                settings.payment__fawry_section_enable_fawry == 'yes' &&
                <div>
                    <div className='mb-3'>
                        <FormFloating>
                            <FormControl placeholder=' ' id='payment__fawry_section_merchant_code' value={settings.payment__fawry_section_merchant_code} onChange={handle_settings_changed} />
                            <FormLabel>Merchant code</FormLabel>
                        </FormFloating>
                    </div>
                    <div className='mb-3'>
                        <FormFloating>
                            <FormControl placeholder=' ' id='payment__fawry_section_security_key' value={settings.payment__fawry_section_security_key} onChange={handle_settings_changed} />
                            <FormLabel>Security key</FormLabel>
                        </FormFloating>
                    </div>
                </div>
            }

            <h2>General</h2>

            <div className='mb-3'>
                <FormFloating>
                    <FormControl placeholder=' ' id='payment__general_section_expire_after_days' value={settings.payment__general_section_expire_after_days} onChange={handle_settings_changed} />
                    <FormLabel>Payment Expire After # Day(s)</FormLabel>
                </FormFloating>
            </div>



            <Button className='my-3' onClick={handle_save_setting}>Save Settigns</Button>

        </Container>
    )
}
