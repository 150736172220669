export default function Loading() {
    return (
        <div className="loading">
            <div className="square" ></div>
            <div className="square"></div>
            <div className="square last"></div>
            <div className="square clear"></div>
            <div className="square"></div>
            <div className="square last"></div>
            <div className="square clear"></div>
            <div className="square "></div>
            <div className="square last"></div>
        </div>
    )
}
