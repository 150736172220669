// react-bootstrap
import { Alert, ListGroup, ListGroupItem } from 'react-bootstrap'

// Images
import female from '../assets/imgs/female.png'
import male from '../assets/imgs/male.png'

// react-router-dom
import { useNavigate } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { ACTN_set_current_student } from '../Redux/TeacherReducer';

export default function StudentsList({ students }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handle_view_student = student_id => {

        dispatch(ACTN_set_current_student({ student_id }))
        navigate('/account/student')
    }

    if (students.length == 0) {
        return <Alert variant='warning'><h2>No Students Yet!</h2></Alert>
    }

    return (
        <ListGroup>
            {
                students.map((student, i) =>
                    <ListGroupItem key={i} onClick={() => { handle_view_student(student.student_id) }} className='d-flex flex-row align-items-center'>
                        <div className='me-2'><img className='img-32' src={student.gender == 'Female' ? female : male} alt={student.student_name} /></div>
                        <span>{student.student_name}</span>
                    </ListGroupItem>
                )
            }

        </ListGroup>

    )
}
