import React, { useEffect, useState } from 'react'

// React Bootstrap
import { Card, Col, Modal, Row, Button, FormFloating, FormLabel, FormControl, FormGroup, ListGroup, ListGroupItem, FormSelect, Container } from 'react-bootstrap';

// Toastify
import { toast } from 'react-toastify';

// Helpers
import { hlpr_from_to_slot } from '../../../Helpers/Functions'

// Redux
import { useDispatch } from 'react-redux';
import { ACTN_set_loading } from '../../../Redux/SettingsReducer'

// react icons
import { SiGooglemeet } from 'react-icons/si';
import { GiMoneyStack } from 'react-icons/gi';

// APIs
import { API_reject_booking, API_add_sessions_links, API_view_booking_payment, API_moderate_booking_payment } from '../../../apis';

// Images
import female from '../../../assets/imgs/female.png';
import male from '../../../assets/imgs/male.png';

export default function BookingList({ bookings, fnc_list_all_bookings }) {

    const [showPaidModal, setShowPaidModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [showAddLinksModal, setShowAddLinksModal] = useState(false);

    const [paidBooking, setPaidBooking] = useState({});
    const [rejectedBooking, setRejectedBooking] = useState({});
    const [linksBooking, setLinksBooking] = useState({});

    const [showModerateModal, setShowModerateModal] = useState(false)
    const [bookingToModerate, setBookingToModerate] = useState({})

    const [moderate, setModerate] = useState({})
    const [showModerateConfirm, setShowModerateConfirm] = useState(false)

    const dispatch = useDispatch();

    useEffect(() => {
    }, [bookings]);


    const handle_open_paid_modal = booking_id => {
        setShowPaidModal(true);

        setPaidBooking({ booking_id });
    }

    const handle_open_reject_modal = booking_id => {
        setShowRejectModal(true);

        setRejectedBooking({ booking_id });

        // Add reason for reject mandatory, then send email to all parties
        // Store the rejection in rejectinos table [Create it]
    }

    const handle_close_paid_modal = () => {
        setPaidBooking({})
        setShowPaidModal(false)
    }

    const handle_close_reject_modal = () => {
        setRejectedBooking({})
        setShowRejectModal(false)
    }

    const handle_paid_booking_changed = e => {
        // console.log('e', e)
    }

    const handle_rejected_booking_changed = e => {
        // console.log('e', e)
    }

    const handle_confirm_paid = async () => { }

    const handle_confirm_reject = async () => {

        if (rejectedBooking.rejectReason == '') {
            toast.error('Please type the rejection reason')
            return;
        }

        if (rejectedBooking.booking_id == '') {
            toast.error('Something went wrong, please try again')
            return;
        }

        dispatch(ACTN_set_loading(true));

        await API_reject_booking(rejectedBooking).then(res => {
            dispatch(ACTN_set_loading(false));

            if (res) {
                // console.log(res);
                fnc_list_all_bookings();
            }

            setShowRejectModal(false);
            setRejectedBooking({})
        })
    }


    // Sessions Links
    const handle_open_links_modal = booking => {

        setShowAddLinksModal(true)

        setLinksBooking(booking)
    }

    const handle_close_add_links_modal = () => {
        // setLinksBooking({})
        setShowAddLinksModal(false)
    }

    const handle_links_booking_changed = e => {

        const key = e.target.name;
        const val = e.target.value;

        setLinksBooking({ ...linksBooking, [key]: val })
    }

    const handle_confirm_add_links = async () => {
        await API_add_sessions_links(linksBooking)
            .then(added => {
                if (added) {
                    toast.success('Links updated Successfully.')
                }

                handle_close_add_links_modal();
                fnc_list_all_bookings();
            })
    }

    const handle_show_moderate_modal = async data => {
        setBookingToModerate({ ...bookingToModerate, ...data });

        await API_view_booking_payment(data.booking_id).then(res => {
            if (res) {
                setBookingToModerate({ ...bookingToModerate, ...res, ...data });
            }
        })

        setShowModerateModal(true)
    }

    const handle_booking_to_moderate_changed = e => {
        const k = e.target.name;
        const v = e.target.value;

        setBookingToModerate({ ...bookingToModerate, ...{ [k]: v } });

        // console.log(bookingToModerate);

    }

    const handle_moderate_confirm = confirmed => {

        if (!bookingToModerate.amount || bookingToModerate.amount == 0 || bookingToModerate.amount == '') {
            toast.error('Paid amount is mandatory!')
            return;
        }


        if (!bookingToModerate.currency || bookingToModerate.currency == '') {
            toast.error('currency is mandatory!')
            return;
        }

        // console.log(bookingToModerate);

        setModerate({
            amount: bookingToModerate.amount,
            currency: bookingToModerate.currency,
            moderation_notes: bookingToModerate.moderation_notes,
            pay_details_id: bookingToModerate.pay_details_id,
            vendor_reference: bookingToModerate.vendor_reference,
            booking_id: bookingToModerate.booking_id,
            student_id: bookingToModerate.student_id,
            teacher_id: bookingToModerate.teacher_id,
            confirmed: confirmed,
            confirm: confirmed == 1 ? 'Approve' : 'Reject',
            variant: confirmed == 1 ? 'success' : 'danger'
        });
        setShowModerateConfirm(true)
    }

    const moderate_booking = async () => {

        await API_moderate_booking_payment(moderate)
            .then(res => {
                if (res) {

                    handle_close_moderate_modal();

                    // console.log('res::', res);
                    if (res == 1) {
                        toast.success('Payment Approved Successfully.');
                    } else {
                        toast.success('Payment Rejected Successfully.');
                    }

                    fnc_list_all_bookings();
                } else {

                    toast.error('Something went wrong, please reload the page and try again.');
                }
            })
        setShowModerateConfirm(false);
    }

    const handle_close_moderate_modal = () => {
        setBookingToModerate({});
        setModerate({});
        setShowModerateModal(false);
        setShowModerateConfirm(false);
    }

    return (
        <Row>

            {/* Moderate Modal */}
            <Modal show={showModerateModal}>
                <Modal.Header>Moderate Booking Payment</Modal.Header>
                <Modal.Body>
                    <h4 className='text-info'>Booking Details</h4>
                    <ListGroup>
                        <ListGroupItem className='d-flex justify-content-between'>
                            <span>Booked ON</span>
                            <span>{bookingToModerate.book_date}</span>
                        </ListGroupItem>
                        <ListGroupItem className='d-flex justify-content-between'>
                            <span>Booked Hours</span>
                            <span>{bookingToModerate.hours}</span>
                        </ListGroupItem>
                        <ListGroupItem className='d-flex justify-content-between'>
                            <span>Booked Rate</span>
                            <span>{bookingToModerate.rate}</span>
                        </ListGroupItem>
                        <ListGroupItem className='d-flex justify-content-between'>
                            <span>Amount to Pay</span>
                            <span>{bookingToModerate.rate * bookingToModerate.hours}</span>
                        </ListGroupItem>
                        <ListGroupItem className='d-flex justify-content-between'>
                            <span>System Payment Reference</span>
                            <span>{bookingToModerate.payment_ref}</span>
                        </ListGroupItem>
                        <ListGroupItem className='d-flex justify-content-between'>
                            <span>Vendor Reference</span>
                            <span>{bookingToModerate.vendor_reference}</span>
                        </ListGroupItem>
                        <ListGroupItem className='d-flex justify-content-between'>
                            <span>notes</span>
                            <span>{bookingToModerate.payment_notes}</span>
                        </ListGroupItem>
                    </ListGroup>



                    <hr />

                    <h4 className='text-info'>Moderation Notes</h4>

                    <FormGroup>
                        <FormLabel>Notes</FormLabel>
                        <FormControl as='textarea' name='moderation_notes' value={bookingToModerate.moderation_notes} onChange={handle_booking_to_moderate_changed} />
                    </FormGroup>

                    <FormGroup>
                        <FormLabel>Paid Amount</FormLabel>
                        <FormControl type='text' name='amount' value={bookingToModerate.amount || ''} onChange={handle_booking_to_moderate_changed} />
                    </FormGroup>

                    <FormGroup>
                        <FormLabel>Currency</FormLabel>
                        <FormSelect name='currency' value={bookingToModerate.currency} onChange={handle_booking_to_moderate_changed}>
                            <option value=''>Select Currency</option>
                            <option value='EGP'>EGP</option>
                        </FormSelect>
                    </FormGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant='success' onClick={() => handle_moderate_confirm(1)}>Approve</Button>
                    <Button variant='danger' onClick={() => handle_moderate_confirm(-1)}>Reject</Button>
                    <Button variant='secondary' onClick={handle_close_moderate_modal}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Moderate Confirm Modal */}
            <Modal show={showModerateConfirm} className='shadow-lg'>
                <Modal.Header>
                    Confirm {moderate.confirm} Booking
                </Modal.Header>
                <Modal.Body>
                    <p className='alert alert-warning'>Please note that this action is undone.</p>
                    <h5>Are you sure you want to <span className={`text-${moderate.variant}`}>{moderate.confirm}</span> the booking?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={moderate.variant} onClick={moderate_booking}>Yes {moderate.confirm}</Button>
                    <Button variant='secondary' onClick={() => setShowModerateConfirm(false)}>Do Not {moderate.confirm}</Button>
                </Modal.Footer>
            </Modal>

            {/* Paid Modal */}
            <Modal show={showPaidModal}>
                <Modal.Header>
                    <div className="alert alert-danger">
                        Enter Manualy payment details.
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <FormFloating className="mb-3">
                        <FormControl as='textarea' onChange={e => handle_paid_booking_changed(e)} placeholder=' '>{paidBooking.payment_notes}</FormControl>
                        <FormLabel for="rejectMsg">Payment Notes</FormLabel>
                    </FormFloating>
                    <div className="d-flex justify-content-between">
                        <Button variant='success' onClick={handle_confirm_paid}>Yes, Confirm</Button>
                        <Button variant='secondary' onClick={handle_close_paid_modal}>Close</Button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Reject Modal */}
            <Modal show={showRejectModal}>
                <Modal.Header>
                    <div className="alert alert-danger">
                        Are you sure you want to reject this request?
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <FormFloating className="mb-3">
                        <FormControl as='textarea' onChange={e => handle_rejected_booking_changed(e)} placeholder=' '>{rejectedBooking.rejectReason}</FormControl>
                        <FormLabel for="rejectMsg">Reject Reason</FormLabel>
                    </FormFloating>
                    <div className="d-flex justify-content-between">
                        <Button variant='danger' onClick={handle_confirm_reject}>Yes, Reject</Button>
                        <Button variant='secondary' onClick={handle_close_reject_modal}>Cancel</Button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Add Links Modal */}
            <Modal show={showAddLinksModal}>
                <Modal.Header>
                    <h2>Add Sessions Links</h2>
                </Modal.Header>
                <Modal.Body>
                    <FormFloating className="mb-3">
                        <FormControl name='sessions_link' onChange={e => handle_links_booking_changed(e)} placeholder=' ' value={linksBooking.sessions_link || ''} />
                        <FormLabel htmlFor="rejectMsg">Sessions Link</FormLabel>
                    </FormFloating>
                    <FormFloating className="mb-3">
                        <FormControl name='sessions_alt_link' onChange={e => handle_links_booking_changed(e)} placeholder=' ' value={linksBooking.sessions_alt_link || ''} />
                        <FormLabel htmlFor="rejectMsg">Sessions Alt Link</FormLabel>
                    </FormFloating>
                    <div className="d-flex justify-content-between">
                        <Button variant='info' onClick={handle_confirm_add_links}>Update Links <SiGooglemeet /></Button>
                        <Button variant='secondary' onClick={handle_close_add_links_modal}>Close</Button>
                    </div>
                </Modal.Body>
            </Modal>

            {
                bookings.map(({ book_date, booking_id, course_id, course_name, hours, notes, rate, sessions_link, sessions_alt_link, booked_schedule, status_name, student_gender, student_email, student_mobile, student_nationality, student_id, student_name, status_notes, teacher_gender, teacher_id, teacher_name, teacher_email, teacher_mobile, teacher_nationality }, i) => {

                    let schedule = [];

                    if (booked_schedule) {
                        schedule = booked_schedule.replaceAll(':::,', ':::');
                        schedule = schedule.substr(0, schedule.length - 3);
                        schedule = schedule.split(':::');
                    }

                    const Buttons = () => {

                        const PaidBtn = () => <button className='btn btn-success'>Paid <GiMoneyStack /></button>
                        const ModerateBtn = () => <button className='btn btn-success' onClick={() => handle_show_moderate_modal({ booking_id, student_id, teacher_id })}>Medrate Payment <GiMoneyStack /></button>
                        const RejectBtn = () => <button className='btn btn-danger'>Reject</button>
                        const SessionsLinksBtn = () => <button onClick={() => handle_open_links_modal({ booking_id, sessions_link, sessions_alt_link })} className='btn text-primary'> <SiGooglemeet /> Manage Sessions Links</button>

                        switch (status_name) {
                            case 'pending':
                                return (
                                    <>
                                        <PaidBtn />
                                        <RejectBtn />
                                    </>
                                )

                            case 'paid':
                                return (
                                    <>
                                        <SessionsLinksBtn />
                                    </>
                                )

                            case 'initiated':
                                return (
                                    <>
                                        <ModerateBtn />
                                    </>
                                )
                        }
                    }


                    return (
                        <Col lg={6} xxl={4} className='mb-3' key={i}>
                            <Card>
                                <Card.Header className={`py-0`}>
                                    <p className='d-flex justify-content-between'>
                                        <span className='fw-bold'>#{i + 1}</span>
                                    </p>
                                    <p className='text-end mb-0'>{book_date}</p>
                                </Card.Header>
                                <Card.Body>

                                    <div className='border-bottom'>
                                        <h2>Student</h2>
                                        <div className='d-flex justify-content-between bg-dark text-light p-2'>
                                            <div>
                                                <img className='img-32' src={student_gender == 'female' ? female : male} alt={student_name} />
                                            </div>
                                            <span>{student_name}</span>
                                        </div>
                                        <p className='d-flex justify-content-between'>
                                            <span className='fw-bold'>Email</span>
                                            <span className='align-self-end'>{student_email}</span>
                                        </p>
                                        <p className='d-flex justify-content-between'>
                                            <span className='fw-bold'>Mobile</span>
                                            <span>{student_mobile}</span>
                                        </p>
                                        <p className='d-flex justify-content-between'>
                                            <span className='fw-bold'>Nationality</span>
                                            <span>{student_nationality}</span>
                                        </p>
                                    </div>

                                    <div className='border-bottom'>
                                        <h2>Teacher</h2>
                                        <div className='d-flex justify-content-between bg-dark text-light p-2'>
                                            <div>
                                                <img className='img-32' src={teacher_gender == 'female' ? female : male} alt={teacher_name} />
                                            </div>
                                            <span>{teacher_name}</span>
                                        </div>
                                        <p className='d-flex justify-content-between'>
                                            <span className='fw-bold'>Email</span>
                                            <span className='align-self-end'>{teacher_email}</span>
                                        </p>
                                        <p className='d-flex justify-content-between'>
                                            <span className='fw-bold'>Mobile</span>
                                            <span>{teacher_mobile}</span>
                                        </p>
                                        <p className='d-flex justify-content-between'>
                                            <span className='fw-bold'>Nationality</span>
                                            <span>{teacher_nationality}</span>
                                        </p>
                                    </div>

                                    <div className='border-bottom'>
                                        <h2>Booking</h2>
                                        <p className='d-flex justify-content-between'>
                                            <span className='fw-bold'>Course</span>
                                            <span>{course_name}</span>
                                        </p>
                                        <p className='d-flex justify-content-between'>
                                            <span className='fw-bold'>Hours Booked</span>
                                            <span>{hours}</span>
                                        </p>
                                        <p className='d-flex justify-content-between'>
                                            <span className='fw-bold'>Hour Rate</span>
                                            <span>{rate}</span>
                                        </p>
                                    </div>
                                    {
                                        schedule.length > 0 &&
                                        <div className='d-flex flex-column justify-content-between'>
                                            <span className='fw-bold'>Schedule</span>


                                            {
                                                schedule.map((day, i) => {
                                                    const day_arr = day.split('@');
                                                    const booked_times = day_arr[1].split(',');

                                                    return (
                                                        <Card key={i} className='mb-2 '>
                                                            <Card.Header className={``}>
                                                                {day_arr[0]}
                                                            </Card.Header>
                                                            <Card.Body>
                                                                <Row className='d-flex flex-wrap align-content-center'>
                                                                    {
                                                                        booked_times.map((time, j) =>
                                                                        (
                                                                            <Col md={6} lg={4} className='p-0' key={j}>
                                                                                <span className='border d-flex flex-grow small m-1 p-1' >{hlpr_from_to_slot(time)}</span>
                                                                            </Col>
                                                                        )
                                                                        )
                                                                    }
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                    <hr />

                                    <hr />
                                    <p className='d-flex justify-content-between'>
                                        <span className='fw-bold'>Book Notes</span>
                                    </p>
                                    <p>{notes}</p>
                                    {
                                        status_name == 'rejected' &&
                                        <>
                                            <p className='d-flex justify-content-between'>
                                                <span className='fw-bold'>Rejection Reason</span>
                                            </p>
                                            <p>{status_notes}</p>
                                        </>
                                    }

                                </Card.Body>
                                {
                                    status_name == 'paid' &&
                                    <Card.Footer className='d-flex flex-column justify-content-between'>
                                        <h6>Sessions Links</h6>
                                        <div className='d-flex flex-row justify-content-between'>
                                            {
                                                sessions_link &&
                                                <p>
                                                    <a href={sessions_link} target='_blank' className='text-decoration-none'>
                                                        <SiGooglemeet /> <span>Main</span>
                                                    </a>
                                                </p>
                                            }
                                            {
                                                sessions_alt_link &&
                                                <p>
                                                    <a href={sessions_alt_link} target='_blank' className='text-decoration-none'>
                                                        <SiGooglemeet /> <span>Alt</span>
                                                    </a>
                                                </p>
                                            }
                                        </div>
                                    </Card.Footer>
                                }

                                {
                                    status_name == 'initiated' &&
                                    <Container>
                                        <p>This booking is pending for confirmation.</p>
                                    </Container>
                                }
                                <Card.Footer className='d-flex justify-content-between'>
                                    <Buttons />
                                </Card.Footer>
                            </Card>
                        </Col>
                    )
                }
                )
            }
        </Row >
    )
}


