import { useEffect, useState } from 'react';

// react-bootstrap
import { Modal, CloseButton, FormGroup, FormLabel, FormControl, ButtonGroup, Button, ListGroup, ListGroupItem } from 'react-bootstrap';

// APIs
import { API_send_booking_slot_chat, API_get_booking_slot_chat } from '../../apis';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ACTN_show_chat_modal } from '../../Redux/ChatReducer';
import { ACTN_set_msgs_count } from '../../Redux/UserReducer';
import { ACTN_set_loading } from '../../Redux/SettingsReducer';

// Images
import female from '../../assets/imgs/female.png';
import male from '../../assets/imgs/male.png';

// Helpers
import { hlpr_get_user_messages } from '../../Helpers/Functions'

export default function ChatModal() {
    const [chatMsg, setChatMsg] = useState('');
    const [chats, setChats] = useState([]);

    const dispatch = useDispatch();

    const student_id = useSelector(state => state.user.id);
    const { booking_detail_id, booking_id } = useSelector(state => state.chat.chat_details);

    useEffect(() => {
        fnc_get_booking_slot_chat();
    }, [])

    const fnc_get_booking_slot_chat = async () => {
        await API_get_booking_slot_chat(booking_id, booking_detail_id, student_id, 'S').then(res => {
            dispatch(ACTN_set_loading(false));
            hlpr_get_user_messages(dispatch, ACTN_set_msgs_count, student_id)

            setChats(res);

        });
    }

    const handle_send_msg = async () => {
        await API_send_booking_slot_chat(chatMsg, student_id, 'S', booking_detail_id).then(res => {
            if (res) {
                setChats([...chats, { user_role: 'S', chat_on: 'NOW', msg: chatMsg }])
            }
        })
    }

    return (
        <Modal show='true'>
            <Modal.Header>
                <CloseButton onClick={() => dispatch(ACTN_show_chat_modal(false))}></CloseButton>
            </Modal.Header>
            <Modal.Body>
                <FormGroup>
                    <FormLabel>New Message</FormLabel>
                    <FormControl as='textarea' value={chatMsg} onChange={e => setChatMsg(e.target.value)} />
                </FormGroup>
                <ButtonGroup className='my-3'>
                    <Button onClick={handle_send_msg}>Send</Button>
                </ButtonGroup>
                <ListGroup style={{ overflow: 'auto', height: '100%' }}>
                    {
                        chats.map((chat, i) =>
                            <ListGroupItem key={i} className='mb-3 d-flex flex-column shadow-sm rounded'>
                                <div className='d-flex flex-column border-bottom'>
                                    <div className='d-flex flex-row '>
                                        <div className='d-flex flex-column align-items-center'>
                                            <img src={chat.gender == 'Female' ? female : male} alt={chat.gender} className='img-32' />
                                            <small>{chat.user_role == 'T' ? 'Teacher' : chat.user_role == 'S' ? 'Me' : 'Admin'}</small>

                                        </div>
                                        <small className='ms-2'>{chat.user_name}</small>
                                    </div>
                                </div>

                                <div className='d-flex flex-column flex-grow-1'>
                                    <p>{chat.msg}</p>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <small>
                                            SEEN BY: <span>{chat.admin_seen == 1 && 'Admin'} - {chat.teacher_seen == 1 && 'Teacher'}</span>
                                        </small>
                                        <small className='fs-05 align-self-end'>{chat.chat_on}</small>
                                    </div>
                                </div>

                                <div>
                                </div>
                            </ListGroupItem>
                        )
                    }
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={() => dispatch(ACTN_show_chat_modal(false))}>Close</Button>
            </Modal.Footer>
        </Modal>

    )
}
