import './App.css';
import './App.scss';
import 'animate.css/animate.min.css';
import 'aos/dist/aos.css';

// Pages
import Pages from './Pages';

// Components
import Loading from './Components/Loading';
import Footer from './Components/Footer';
import LoginForm from './Components/User/LoginForm';
import RegisterForm from './Components/User/RegisterForm';
import BookedSlotDetails from './Components/BookedSlotDetails';

// APIs
import { API_load_app_settings } from './apis';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ACTN_logged_in } from './Redux/UserReducer';
import { ACTN_set_app_settings, ACTN_set_loading } from './Redux/SettingsReducer';

// Helpers
import { hlpr_get_localstorage } from './Helpers/Functions';

// libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import '@splidejs/react-splide/css/sea-green';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/pagination';

import { ToastContainer } from 'react-toastify';
import { Container } from 'react-bootstrap';
import ForgetPassForm from './Components/User/ForgetPassForm';
import ChatModal from './Components/Modals/ChatModal';
import { useEffect } from 'react';

function App() {
	const dispatch = useDispatch();

	const { app_settings, loading, show_login, show_register, show_forget_pass, show_reset_pass, show_slot_details } = useSelector((state) => state.settings);
	const { show_chat_modal } = useSelector((state) => state.chat);

	const fnc_check_if_logged_in = async () => {
		const { user_data, type } = hlpr_get_localstorage();

		// console.log('APP->user_data, type', user_data, type)

		// if no storage found
		if (!user_data || undefined == user_data.user_id || user_data.user_id == 0) return;

		dispatch(ACTN_logged_in({ user_data, type }));
	};

	const fnc_load_app_settings = async () => {
		if (!app_settings.loaded) {
			dispatch(ACTN_set_loading(true));
			await API_load_app_settings().then((settings) => {
				dispatch(ACTN_set_loading(false));
				if (settings) {
					settings.loaded = true;
					dispatch(ACTN_set_app_settings(settings));
				}
			});
		}
	};

	useEffect(() => {
		fnc_check_if_logged_in();
		fnc_load_app_settings();
	}, []);

	return (
		<>
			<Container fluid id="root_container" className="p-0 pb-5" style={style}>
				{/* <Header /> */}

				<Pages className="mb-5" />

				<ToastContainer />

				{loading && <Loading />}

				{show_login && <LoginForm />}

				{show_register && <RegisterForm />}

				{show_forget_pass && <ForgetPassForm />}

				{show_slot_details && <BookedSlotDetails />}

				{show_chat_modal && <ChatModal />}
			</Container>

			<Footer />
		</>
	);
}

export default App;

const style = {
	minHeight: '90%',
};
