import React, { useEffect, useState, useRef } from 'react'

// React Bootstrap
import { Alert, Button, Container, Form, FormControl, FormFloating, FormGroup, FormLabel, ListGroup, Modal } from 'react-bootstrap'

// APIs
import { API_get_my_course_booking, API_update_booking } from '../../apis'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { ACTN_set_loading } from '../../Redux/SettingsReducer';

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

// React Router DOM
import { useNavigate } from 'react-router-dom'

// Helpers
import { hlpr_from_to_slot, hlpr_sort_dates, hlpr_sort_array } from "../../Helpers/Functions";

// Libraries
import { useParams } from 'react-router-dom'
import FormRange from 'react-bootstrap/esm/FormRange'
import IsAdminNotify from '../Admin/IsAdminNotify'

// React Toastify
import { toast } from 'react-toastify'

// Components
import PaymentForm from '../Payments/PaymentForm';

export default function BookHours({ teacher, skills, availableSlots }) {
    const [availableSchedule, setAvailableSchedule] = useState([])
    const [paymentOption, setPaymentOption] = useState('')
    const [paymentRef, setPaymentRef] = useState('')
    const [currentBooking, setCurrentBooking] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState('')
    const [selectedCourseName, setSelectedCourseName] = useState('')
    const [neededHours, setNeededHours] = useState(8)
    const [passedHours, setPassedHours] = useState(0)
    const [selectedRate, setSelectedRate] = useState(0)
    const [totalFees, setTotalFees] = useState(0)
    const [selectedHours, setSelectedHours] = useState(0);
    const [notes, setNotes] = useState('');

    const [bookedBefore, setBookedBefore] = useState(false);
    const [bookingId, setBookingId] = useState(null);

    const [showPaymentModal, setShowPaymentModal] = useState(false);

    const [ready, setReady] = useState(false);

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const [error, setError] = useState('');

    const [paymentFormParams, setPaymentFormParams] = useState({})

    const { c_id } = useParams();

    const teacher_id = useParams()['id'];

    const student_id = useSelector(state => state.user.id)

    const { isAdmin } = useSelector(state => state.user)

    const navigate = useNavigate();

    const dispatch = useDispatch()

    const ref_course = useRef('');

    useEffect(() => {
        const sorted = hlpr_sort_dates(availableSlots);
        setAvailableSchedule(sorted)

        fnc_init()

    }, [teacher, availableSlots, skills])

    const fnc_init = async () => {

        if (c_id) {
            setSelectedCourse(c_id)

            const elm = ref_course.current;

            fnc_get_course_data(elm);

        }

    }

    const fnc_get_course_data = async elm => {

        let rate = elm.options[elm.selectedIndex].getAttribute('data-rate'),
            course_id = elm.value,
            course_name = elm.options[elm.selectedIndex].getAttribute('data-name'),
            hours = 8,
            hours_count = 0,
            notes = '';

        setBookedBefore(false);

        if (c_id) {
            setSelectedCourse(c_id);
            course_id = c_id;
        }

        dispatch(ACTN_set_loading(true))

        await API_get_my_course_booking(course_id, teacher_id, student_id).then(res => {
            dispatch(ACTN_set_loading(false))

            if (res == 'Something went wrong') {
                setError('Something went wrong, please reload the page and try again!')
                return;
            }

            const booking_slots = [];

            if (res && res.length > 0) {
                res.forEach(({ slot_date, booked_slots }) => {
                    booking_slots.push({ slot_date, booked_slots });
                })

                // check if the booking is still active or passed

                let passed = 0;

                booking_slots.forEach(slot => {
                    const slot_date = new Date(slot.slot_date);
                    const today = new Date();

                    if (slot_date <= today) {
                        passed += (slot.booked_slots).split(',').length;
                    }
                })

                setPassedHours(passed);

                const book = res[0];

                rate = book.rate;
                hours = book.hours;
                notes = book.notes;

                hours_count = fnc_get_hours_count(res);

                setBookedBefore(true);
                setBookingId(book.booking_id);

            } else if (c_id) {
                rate = elm.options[elm.selectedIndex].getAttribute('data-rate');
                setBookedBefore(false);
            }


            setCurrentBooking(booking_slots);

            setSelectedCourse(course_id);
            setSelectedCourseName(course_name);

            setSelectedRate(rate);

            setNeededHours(hours);

            setNotes(notes);

            setReady(false);

            fnc_calc_fees(rate, hours);

            setSelectedHours(hours_count);

        })
    }

    const fnc_calc_fees = (rate, hours) => {
        //  // console.log('rate, hours', rate, hours)
        const fees = rate * hours
        setTotalFees(fees)
    }

    const fnc_get_hours_count = arr => {

        let hours_count = 0;

        arr.forEach(({ booked_slots }) => {
            let booked_slots_arr = booked_slots.split(',');
            //  // console.log('booked_slots_arr', booked_slots_arr);
            //  // console.log('booked_slots_arr.length', booked_slots_arr.length);

            hours_count += booked_slots_arr.length;
        })

        return hours_count;
    }

    const handle_course_changed = e => {

        const elm = e.target;

        fnc_get_course_data(elm);

    }

    const handle_slot_changed = e => {
        // debugger
        const was_selected = e.target.getAttribute('data-active');
        const disabled = e.target.getAttribute('data-disabled');
        const date = e.target.getAttribute('data-date');
        const hour = e.target.getAttribute('data-hour');

        //  // console.log('was_selected', was_selected == 'false');
        //  // console.log('selectedHours', selectedHours);
        //  // console.log('neededHours', neededHours);

        if (disabled == 'true' || (was_selected == 'false' && (parseInt(selectedHours) >= parseInt(neededHours)))) {
            return;
        }

        let date_exists = false;

        //  // console.log('was_selected', was_selected);
        //  // console.log('date', date);
        //  // console.log('hour', hour);
        //  // console.log('currentBooking:::-->>', currentBooking)

        let new_booking = [];

        new_booking = currentBooking.map(book => {

            //  // console.log('book', book)

            const slot_date = book.slot_date;
            let booked_slots = book.booked_slots;

            //  // console.log('slot_date, booked_slots', slot_date, booked_slots)

            if (slot_date == date) {
                date_exists = true;
                let times_arr = booked_slots.split(',');

                //  // console.log('times_arr before:::-->>', times_arr);

                if (was_selected == 'true') {
                    times_arr = times_arr.filter(time => time != hour)
                } else {
                    // Checks if the selectedHours is equal to neededHours

                    times_arr.push(hour)
                    times_arr = hlpr_sort_array(times_arr);
                }
                //  // console.log('times_arr after:::-->>', times_arr);
                // return;

                const newSet = new Set(times_arr)
                times_arr = Array.from(newSet);

                // console.log('times_arr:::-->>', times_arr);

                booked_slots = (times_arr.toString());

                // console.log('booked_slots:::-->>', booked_slots);

            }
            book.booked_slots = booked_slots;

            return book;

        })


        if (!date_exists) {
            new_booking.push({
                slot_date: date,
                booked_slots: hour
            })
        }

        // let newCurrentBooking = [...currentBooking, ...new_booking];

        // console.log('new_booking::', new_booking)
        //  // console.log('currentBooking::', currentBooking)
        //  // console.log('newCurrentBooking::', newCurrentBooking)

        // let newCurrentBooking

        let c_new_booking = new_booking.filter(book => book.booked_slots != '')

        // console.log('c_new_booking After sort::', c_new_booking)

        c_new_booking = hlpr_sort_dates(c_new_booking);

        const hours_count = fnc_get_hours_count(c_new_booking);
        //  // console.log('hours_count:', hours_count);

        setSelectedHours(hours_count);

        setCurrentBooking(c_new_booking);

        if (hours_count == neededHours) {
            const msg = bookedBefore ? 'You may update your booking details now.' : 'You are ready to book now.';

            toast.info(msg);
            setReady(true)

        } else {
            setReady(false)
        }

    };

    const handle_hours_changed = e => {
        const hours = e.target.value;

        if (selectedHours > hours) {


            // console.log('currentBooking', currentBooking);

            const passed_booking = currentBooking.filter(book => new Date(book.slot_date) <= new Date())

            // Set currentBookin to passed only
            setCurrentBooking(passed_booking);

            // Set SelectedHours = passed Hours
            setSelectedHours(passedHours);
        }

        setReady(false);

        setNeededHours(hours);

        fnc_calc_fees(selectedRate, hours);
    }

    const handle_pay_now_clicked = () => {

        setPaymentFormParams({
            showPaymentModal,
            setShowPaymentModal,
            totalFees,
            student_id,
            teacher_id,
            teacher_name: `${teacher.first_name} ${teacher.middle_name} ${teacher.last_name}`,
            course_name: selectedCourseName,
            course_id: selectedCourse,
            hours: selectedHours,
            rate: selectedRate,
            slots: currentBooking,
            notes,
            update: bookedBefore
        })

        setShowPaymentModal(true);
    }


    // Needs review
    const handle_update_booking = async () => {

        // console.log('isAdmin', isAdmin)
        // If the user is Admin
        if (isAdmin) {
            setShowConfirmModal(true);
            return;
        }

        /**
         * First Check if the new fees is greater than the old
         * Then open payment for to collect the difference
         * NOTE: calc the fees using old rate
         *
         */

        // const booking_data = {
        //     student_id: student_id,
        //     course_id: selectedCourse,
        //     teacher_id: teacher_id,
        //     hours: selectedHours,
        //     slots: currentBooking,
        //     notes: notes,
        //     update: bookedBefore,
        //     bookingId: bookingId
        // }

        // // console.log('booking_data', booking_data);

        // await API_update_booking(booking_data).then(({ updated }) => {
        //     // console.log('API_update_booking res::', res);
        //     if (updated === true) {
        //         setShowConfirmModal(true);
        //     } else {
        //         setError('Sorry, we are unable to set your booking right now, please reload the page and try again!')
        //     }
        // })
    }


    return (
        <Container className='mt-4'>

            {
                // Payment Options Modal
                showPaymentModal &&
                <PaymentForm paymentFormParams={paymentFormParams} />
            }

            {/* Needs Review */}

            {/* Confirm Booking Modal */}
            <Modal show={showConfirmModal} onHide={() => navigate('/account')}>
                <Modal.Header closeButton>
                    <Modal.Title>Booking {bookedBefore && 'Update'} Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        error &&
                        <Alert variant='danger' className='my-2'>
                            {error}
                        </Alert>
                    }
                    {
                        !error &&
                        <>
                            <p>Your Booking has been {bookedBefore ? 'updated' : 'confirmed'}.</p>
                            {
                                (!bookedBefore && paymentOption.value == 1) &&
                                <div>
                                    <p>Your Payment Reference is: {paymentRef}</p>
                                    <p>You should pay within 24 hours</p>
                                    <p>Account Number: <strong>+201024750245</strong></p>
                                </div>
                            }

                            <p>You will be contacted shortly.</p>
                            {isAdmin && <IsAdminNotify msg='Student will get a message like this' />}
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        (!bookedBefore && paymentOption.value == 2) ?
                            <></>
                            :
                            <Button variant="primary" onClick={() => navigate('/account')}>
                                Thank You
                            </Button>
                    }
                </Modal.Footer>
            </Modal>

            {/* Main Form */}
            <Form>
                <FormFloating>
                    <Form.Select value={selectedCourse} onChange={handle_course_changed} ref={ref_course} disabled={(c_id && bookedBefore) && 'disabled'}>
                        <option value=''>Select a course</option>
                        {
                            skills.map(({ course_id, course_name, rate }, i) => (
                                <option key={i} value={course_id} data-rate={rate} data-name={course_name} >{course_name}</option>
                            ))
                        }
                    </Form.Select>

                    <label>Course</label>

                </FormFloating>

                {
                    error &&
                    <Alert variant='danger' className='my-2'>
                        {error}
                    </Alert>
                }

                {
                    selectedCourse !== '' &&
                    <Container className='mt-4'>

                        <FormGroup>
                            <FormRange title={neededHours} min={8} max={80} step={4} value={neededHours} onChange={handle_hours_changed} />
                            <FormLabel>Hours to Book {neededHours}</FormLabel>
                        </FormGroup>

                        <ListGroup>
                            <ListGroup.Item className='bg-dark text-light'>
                                <h4>Booking Details</h4>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <span>Course Rate EGP {selectedRate}</span>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <span>Hours {neededHours}</span>
                            </ListGroup.Item>
                            {
                                (bookedBefore) &&
                                <ListGroup.Item>
                                    <span className='text-primary'>Passed Hours {passedHours}</span>
                                </ListGroup.Item>
                            }
                            <ListGroup.Item>
                                <span className={`text-${selectedHours == neededHours ? 'success' : 'danger'}`}>Booked Hours {selectedHours}</span>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <span>Total Fees <strong>EGP {totalFees}</strong></span>
                            </ListGroup.Item>

                            <ListGroup.Item className='py-4'>
                                <FormLabel>Additional Notes to Instructor <small className='text-primary'>(Max Letters 1000)</small></FormLabel>
                                <FormControl as='textarea' rows='3' value={notes} onChange={e => setNotes(e.target.value)} />
                            </ListGroup.Item>

                            {
                                ready &&
                                <ListGroup.Item className='d-flex justify-content-end'>
                                    {
                                        bookedBefore &&
                                        <Button variant='primary' className='mt-3' onClick={handle_update_booking}>
                                            All done, update my booking
                                        </Button>
                                    }

                                    {
                                        !bookedBefore &&
                                        <Button variant='primary' className='mt-3' onClick={handle_pay_now_clicked}>
                                            This is OK, Book Now
                                        </Button>
                                    }
                                </ListGroup.Item>
                            }
                        </ListGroup>


                        <div className='py-4'>
                            {
                                availableSchedule.length > 0 ?
                                    <>
                                        <h3 className='m-0'>Plan Your Schedule</h3>
                                        <h6 className='ms-4 p-4 d-flex flex-row justify-content-between align-items-center'>
                                            Match your schedule with your teacher
                                        </h6>
                                    </>
                                    :
                                    <>
                                        <h3 className='alert alert-warning m-0'>No available slots to schedule</h3>
                                    </>
                            }
                            {
                                error &&
                                <Alert variant='danger'>
                                    {error}
                                </Alert>
                            }
                            {
                                <Swiper
                                    slidesPerView={3}
                                    spaceBetween={5}

                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    className="rounded d-flex flex-row"
                                >
                                    {
                                        availableSchedule.map(({ booked_slots, ins_busy_slots, slot_date, slot_id, slot_times, std_busy_slots, std_as_ins_booked_slots }, i) => {

                                            const slot_times_arr = slot_times ? slot_times.split(',') : [];
                                            const booked_slots_arr = booked_slots ? booked_slots.split(',') : [];
                                            const ins_busy_slots_arr = ins_busy_slots ? ins_busy_slots.split(',') : [];
                                            const std_busy_slots_arr = std_busy_slots ? std_busy_slots.split(',') : []
                                            const std_as_ins_booked_slots_arr = std_as_ins_booked_slots ? std_as_ins_booked_slots.split(',') : []

                                            let this_course_slots = [];

                                            let blocked = [], free = [];

                                            blocked = Array.from(new Set([...booked_slots_arr, ...ins_busy_slots_arr, ...std_busy_slots_arr, ...std_as_ins_booked_slots_arr]));
                                            // console.log('Blocked slots on:', slot_date, '->>', blocked)
                                            return (
                                                <SwiperSlide key={i} className='d-flex flex-grow-1 flex-column pb-3 bg-light my-2 rounded'>
                                                    <h4>{slot_date}</h4>
                                                    <div>
                                                        {
                                                            //  // console.log('slot_date', slot_date)
                                                        }
                                                        {
                                                            //  // console.log('slot_times_arr.map')
                                                        }
                                                        {
                                                            slot_times_arr.map(time => {
                                                                //  // console.log('time::', time)
                                                                // debugger
                                                                let busy_time = false,
                                                                    current_time = false,
                                                                    disabled = false,
                                                                    css_class = '',
                                                                    active = false;

                                                                // myBusySchedule.forEach(busy => {
                                                                //     if (busy.slot_date == slot_date) {
                                                                //         const busy_hours = busy.booked_slots.split(',');
                                                                //         //  // console.log(time)
                                                                //         if (busy_hours.indexOf(time) >= 0) {
                                                                //             // css_class = 'bg-disabled-striped';
                                                                //             // active = false;
                                                                //             // disabled = true
                                                                //             busy_time = true;
                                                                //             return;
                                                                //         }
                                                                //     }
                                                                // })

                                                                //  // console.log('currentBooking', currentBooking)
                                                                currentBooking.forEach(book => {
                                                                    if (book.slot_date == slot_date) {
                                                                        const booked_hours = book.booked_slots.split(',');
                                                                        //  // console.log('booked_hours.indexOf(time)', booked_hours.indexOf(time))
                                                                        if (booked_hours.indexOf(time) >= 0) {
                                                                            // css_class = 'bg-primary text-light';
                                                                            // active = true;
                                                                            current_time = true;
                                                                            return;
                                                                        }
                                                                    }
                                                                })


                                                                if (blocked.indexOf(time) >= 0) {
                                                                    busy_time = true;
                                                                    // disabled = true;
                                                                    // css_class = '';
                                                                    // active = false;
                                                                }

                                                                // currentBooking.forEach(book => {
                                                                //     if (book.slot_date == slot_date) {
                                                                //         this_course_slots = book.booked_slots.split(',');
                                                                //     }
                                                                // })


                                                                // current and busy: this booking -> only primary
                                                                // busy only: another booking -> disabled
                                                                // nor of both -> normal

                                                                // 'bg-primary text-light'
                                                                // 'bg-disabled-striped'

                                                                // console.log('busy_time, current_time, busy_time && current_time', busy_time, current_time, busy_time && current_time)
                                                                if (current_time && busy_time) {
                                                                    active = true;
                                                                    disabled = false;
                                                                    css_class = 'bg-success text-light';
                                                                } else if (busy_time) {
                                                                    active = false;
                                                                    disabled = true;
                                                                    css_class = 'bg-disabled-striped';
                                                                } else if (current_time) {
                                                                    active = true;
                                                                    disabled = false;
                                                                    css_class = 'bg-primary text-light';
                                                                } else {
                                                                    active = false;
                                                                    disabled = false;
                                                                    css_class = 'bg-light_grey txt-light_dim_gray';
                                                                }


                                                                return (
                                                                    <small
                                                                        className={`mb-2 px-2 border d-flex flex-column justify-content-start ${css_class}`}
                                                                        style={{ height: '50px' }}
                                                                        key={time}
                                                                        onClick={handle_slot_changed}
                                                                        data-active={active}
                                                                        data-date={slot_date}
                                                                        data-hour={time}
                                                                        data-disabled={disabled}
                                                                    >
                                                                        {hlpr_from_to_slot(time)}
                                                                    </small>
                                                                )
                                                            })}

                                                    </div>
                                                </SwiperSlide>

                                            )
                                        })
                                    }
                                </Swiper>
                            }

                        </div>

                    </Container>
                }
            </Form>

        </Container >
    )
}
