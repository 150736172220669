import { useEffect, useState } from 'react'

// react-bootstrap
import { Alert, Button, FormGroup, FormLabel, FormSelect, Modal, Toast } from 'react-bootstrap'

// React-icons
import { MdOutlineArrowForwardIos } from 'react-icons/md'

// react-toastify
import { toast } from 'react-toastify'

// APIs
import { API_get_payment_options, API_update_profile } from '../../apis'

// Components
import FawryForm from '../Payments/FawryForm';
import VodafoneForm from '../Payments/VodafoneForm';

// redux
import { useSelector } from 'react-redux';
import store from '../../Redux/store';
import { ACTN_update_user_keys } from '../../Redux/UserReducer'

export default function PaymentForm(props) {

    const {
        showPaymentModal,
        setShowPaymentModal,
        totalFees,
        booking_id,
        student_id,
        teacher_id,
        teacher_name,
        course_name,
        course_id,
        hours,
        rate,
        update
    } = props.paymentFormParams;

    const [error, setError] = useState('');
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [paymentOption, setPaymentOption] = useState('');
    const [showFawryPaymentModal, setShowFawryPaymentModal] = useState(false);
    const [showVodafonePaymentModal, setShowVodafonePaymentModal] = useState(false);

    // payment
    const [nationality, setNationality] = useState('');
    const [nidpassport, setNidpassport] = useState('');
    const [nationalityValid, setNationalityValid] = useState(false);
    const [nidpassportValid, setNidpassportValid] = useState(false);
    const [ready, setReady] = useState(false);
    const [errors, setErrors] = useState([]);

    const { profile_completed } = useSelector(state => state.user);
    // console.log('profile_completed payment form', profile_completed);

    useEffect(() => {
        fnc_get_payment_options();

        setShowPaymentModal(true);
    }, []);


    const fnc_get_payment_options = async () => {
        await API_get_payment_options().then(options => setPaymentOptions(options))
    }

    const handle_payment_option_changed = e => {
        const selectedOption = e.target.value;
        setPaymentOption(selectedOption);
    }

    const handle_ready_to_pay_clicked = async () => {

        if (!profile_completed) {

            // Validate nationality
            if (nationality.length < 3) {
                toast.error('Invalid Nationality');
                return;
            }

            if (nidpassport.length < 6) {
                toast.error('Invalid NID or Passport Number');
                return;
            }

            await API_update_profile({ nationality, nidpassport })
                .then(res => {
                    if (res) {
                        store.dispatch(ACTN_update_user_keys({ profile_completed: true }))
                    } else {
                        toast.error('Something went wrong, please reload the page and try again.')
                        return;
                    }
                })
        }
        fnc_show_payment_modal()

    }

    const fnc_show_payment_modal = () => {
        switch (paymentOption) {
            case '1':
                setShowFawryPaymentModal(true);
                break;
            case '2':
                setShowVodafonePaymentModal(true);
        }
    }

    const hide_fawry_form = () => {
        // setShowFawryPaymentModal(false);
    }

    const hide_vodafone_form = () => {
        // setShowVodafonePaymentModal(false);
    }

    const handle_nationality_change = e => {
        const input = e.target.value;

        setNationality(input)

        if (input.length < 3) {
            setNationalityValid(false);
            setReady(false);
        } else {
            setReady(nidpassportValid);
            setNationalityValid(true);
        }
    }

    const handle_nidpassport_change = e => {
        const input = e.target.value;

        setNidpassport(input)

        if (input.length < 6) {
            setNidpassportValid(false);
            setReady(false);
        } else {
            setReady(nationalityValid);
            setNidpassportValid(true);
        }
    }

    return (
        <>
            {/* Fawry Payment Modal */}
            <FawryForm showFawryPaymentModal={showFawryPaymentModal} setShowFawryPaymentModal={setShowFawryPaymentModal} booking_data={props.paymentFormParams} totalFees={totalFees.toFixed(2)} hide_fawry_form={hide_fawry_form} />

            {/* Vodafone Payment Modal */}
            <VodafoneForm showVodafonePaymentModal={showVodafonePaymentModal} setShowVodafonePaymentModal={setShowVodafonePaymentModal} booking_data={props.paymentFormParams} totalFees={totalFees.toFixed(2)} hide_vodafone_form={hide_vodafone_form} />

            <Modal show={true} onHide={() => setShowPaymentModal(false)} backdrop='static' keyboard={false}>
                <Modal.Header closeButton>
                    <h2>Select a Payment Option</h2>
                </Modal.Header>
                <Modal.Body>
                    <p>I am ready to pay<strong> EGP {totalFees}</strong></p>
                    <FormGroup>
                        <FormLabel>Payment Option</FormLabel>
                        <FormSelect value={paymentOption} onChange={handle_payment_option_changed}>
                            <option value=''>Select Payment Option</option>
                            {
                                paymentOptions.map(({ id, payment_option_vendor }) => {

                                    return (<option
                                        key={id}
                                        value={id}>
                                        {payment_option_vendor}
                                    </option>)
                                })
                            }
                        </FormSelect>

                        {
                            (!profile_completed && paymentOption) &&
                            <div className='border my-3'>
                                <Alert variant='warning'>Complete your profile</Alert>
                                <div className="form-group m-3">
                                    <label htmlFor="nationality">Nationality</label>
                                    <input type="text" id="nationality" className={`form-control is-${nationalityValid ? 'valid' : 'invalid'}`} value={nationality} onChange={handle_nationality_change} required />
                                </div>

                                <div className="form-group m-3">
                                    <label htmlFor="nidpassport">NID/Passport Number</label>
                                    <input type="text" id="nidpassport" className={`form-control is-${nidpassportValid ? 'valid' : 'invalid'}`} value={nidpassport} onChange={handle_nidpassport_change} required />
                                </div>
                            </div>
                        }

                        {
                            error &&
                            <Alert variant='danger' className='my-2'>
                                {error}
                            </Alert>
                        }
                    </FormGroup>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant='danger' onClick={() => setShowPaymentModal(false)}>Cancel</Button>
                    {
                        (paymentOption && (ready || profile_completed)) &&
                        <Button variant='success' onClick={handle_ready_to_pay_clicked}>Proceed <MdOutlineArrowForwardIos /></Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}
