// redux
import store from '../Redux/store';
import { ACTN_logged_in, ACTN_logged_out } from '../Redux/UserReducer';
import { ACTN_set_loading } from '../Redux/SettingsReducer';

// Consts
import { baseURL } from '../Helpers/consts'

const fetch_url = async (url, postdata = {}, has_uploads = false, names = []) => {

    // console.log('--------------------Start OF API--------------------')

    const { id, personal_token, last_used_at } = store.getState().user;
    // console.log('id, personal_token, last_used_at', id, personal_token, last_used_at);


    // console.log('postdata', postdata);

    let body_data = '';

    if (has_uploads) {
        const fd = new FormData();

        postdata.forEach((file, i) => {
            fd.append(names[i], file, file.upload_name)
        });

        fd.append('user_id', id);
        fd.append('personal_token', personal_token);
        fd.append('last_used_at', last_used_at);

        body_data = fd;
    } else {

        postdata['user_id'] = id;
        postdata['personal_token'] = personal_token;
        postdata['last_used_at'] = last_used_at;

        body_data = JSON.stringify(postdata);
    }

    const init = {
        method: 'POST',
        mode: 'cors',
        headers: {
            // 'Accept': 'application/json',
            // 'Content-Type': 'application/json, multipart/form-data; boundary=----Wet'
            // 'Content-Type': 'multipart/form-data; boundary=----Wet'
        },
        body: body_data
    }

    store.dispatch(ACTN_set_loading(true));

    const errorTimer = setTimeout(() => {
        store.dispatch(ACTN_set_loading(false));
        // toast.error('Something went wrong, please reload the page and ry again.')
    }, 5000)

    return await fetch(baseURL + url, init)
        .then(res => {
            clearTimeout(errorTimer);
            store.dispatch(ACTN_set_loading(false));
            return res.json()
        })
        .then(async api_res => {
            // console.log('api_res', api_res);

            if (api_res.status == 401) {
                store.dispatch(ACTN_logged_out())
            }

            let data = [], request = [];

            if (undefined != api_res.data) {

                request = await JSON.parse(api_res.request)
                data = await JSON.parse(api_res.data)

                // console.log('API res->request>>', request);
                // console.log('API res->data>>', data);

                // const request = await JSON.parse(api_res.request)
                // console.log('JSON.parse(api_res.data):::->>>', data);
            }

            if (url == 'auth' && undefined != data.loggedin && data.loggedin !== false) {
                store.dispatch(ACTN_logged_in({ user_data: data }))
            }
            // console.log('--------------------END OF API--------------------')
            return data
        });
}


/*

*   --- TOC ---   *

// Settings
// Auth
// Booking
// Category
// Course
// Index
// Member
// Payment
// Review
// Teacher

*/

// Settings
export const API_load_app_settings = async () => {
    const url = `setting`;

    return await fetch_url(url);

}

export const API_get_all_settings = async () => {
    const url = `setting/all`;

    return await fetch_url(url);
}

export const API_save_settings = async settings => {
    const url = `/setting/store`;

    return await fetch_url(url, settings);
}

// Attendance

export const API_save_attendance = async data => {
    const url = 'attendance/save';

    return await fetch_url(url, data);
}

export const API_daily_attendance = async data => {
    const url = 'attendance/daily';

    return await fetch_url(url, data);
}


// Auth

export const API_login_user = async user_data => {
    const url = `auth`;

    return await fetch_url(url, user_data);

}

export const API_register_user = async user_data => {
    const url = `auth/register`;

    return await fetch_url(url, user_data);
}

export const API_change_password = async (info) => {
    const url = `auth/change-password`;

    return await fetch_url(url, info);
}

export const API_reset_password = async data => {

    const url = `auth/reset-password`;

    return await fetch_url(url, data);
}


// Booking

//////////////////////// UNDER ADJUST /////////////////////////

export const API_accept_booking = async (booking_id) => {
    const url = `booking/accept-booking/${booking_id}`;

    return await fetch_url(url);
}

export const API_reject_booking = async (booking_id, rejectReason) => {
    const url = `booking/reject-booking/`;

    return await fetch_url(url, { booking_id, rejectReason });
}

export const API_completed_booking = async (booking_id) => {
    const url = `booking/completed-booking/${booking_id}`;

    return await fetch_url(url);
}

export const API_approve_booking_review = async (booking_id) => {
    const url = `booking/approve-booking-review/${booking_id}`;

    return await fetch_url(url);
}
//////////////////////// END-UNDER ADJUST /////////////////////////


export const API_add_sessions_links = async sessions_links => {
    const url = `booking/add-sessions-links`;

    return await fetch_url(url, sessions_links);
}

export const API_list_all_bookings = async () => {
    const url = `booking`;

    return await fetch_url(url);
}

export const API_prepare_booking = async (teacher_id, student_id) => {
    const url = `booking/prepare-booking`;

    return await fetch_url(url, { teacher_id, student_id });
}

export const API_get_my_course_booking = async (course_id, teacher_id, student_id) => {
    const url = `booking/show-book`;

    return await fetch_url(url, { course_id, teacher_id, student_id });

}

// export const API_get_my_busy_schedule = async (user_id) => {
//     const url = `booking/busy-schedule/${user_id}`;

//     const data = await fetch_url(url);

//     return await data;
// }

export const API_create_new_booking = async (booking_data) => {
    const url = `booking/create-new-booking`;

    return fetch_url(url, booking_data);

}

export const API_update_booking = async (booking_data) => {
    const url = `booking/update`;

    return await fetch_url(url, booking_data);

}

export const API_get_student_calendar = async (student_id) => {
    const url = `booking/student-calendar/${student_id}`;

    return await fetch_url(url);
}

export const API_get_student_all_bookings = async (user_id) => {
    const url = `booking/student-all-bookings/${user_id}`;

    return await fetch_url(url);
}

export const API_get_booking_details = async (booking_id, booking_detail_id, user_id) => {
    const url = `booking/slot`;

    const data = {
        booking_id, booking_detail_id, user_id
    }

    return await fetch_url(url, data);
}

export const API_get_member_bookings = async id => {
    const url = `booking/student/${id}`;

    return await fetch_url(url);
}

export const API_cancel_booking = async (cancel_data) => {

    const url = `booking/cancel`;

    return await fetch_url(url, cancel_data);
}

export const API_get_teacher_sessions = async ins_id => {
    const url = `booking/teacher-sessions/${ins_id}`;

    return await fetch_url(url);
}

export const API_cancel_slot_by_teacher = async (bookingDetailToCancel, user_id, student_id) => {
    const url = `booking/teacher-cancel-slot`;

    const data = { bookingDetailToCancel, user_id, student_id }

    return await fetch_url(url, data);
}


// Category

export const API_get_all_categories = async () => {
    const url = `category`;

    return await fetch_url(url);
}

export const API_get_active_categories = async () => {
    const url = `category/active-all`;

    return await fetch_url(url);
}

export const API_get_child_categories = async () => {
    const url = `category/children`;

    return await fetch_url(url);
}

export const API_upload_image = async (image, name) => {
    const url = 'setting/upload-image';

    return await fetch_url(url, [image], true, [name]);
}

export const API_upload_category_image = async categoryImage => {
    const url = 'category/upload-image';

    return await fetch_url(url, [categoryImage], true, ['cat_img']);
}

export const API_add_category = async (categoryName, parentId, categoryDescription) => {
    const url = `category/add`;

    const data = await fetch_url(url, { categoryName, parentId, categoryDescription });

    return data;
}

export const API_update_category = async (category) => {
    const url = `category/update`;

    const data = await fetch_url(url, category);

    return await data;
}

export const API_delete_category = async cat_id => {
    const url = `category/delete/${cat_id}`;

    return await fetch_url(url);
}

export const API_get_active_child_categories = async () => {
    const url = `category/active-children`;

    return await fetch_url(url);
}


// Chat

export const API_get_user_messages_count = async user_id => {
    const url = `chat/user-msgs-count/${user_id}`;

    return await fetch_url(url);
}

export const API_get_booking_slot_chat = async (booking_id, booking_detail_id, user_id, who) => {
    const url = `chat/chat`;

    const data = {
        booking_id, booking_detail_id, user_id, who
    }

    return await fetch_url(url, data);
}

export const API_send_booking_slot_chat = async (chatMsg, user_id, who, bookingDetailId) => {
    const url = `chat/store-chat`;

    const data = {
        chatMsg, user_id, who, bookingDetailId
    }

    return await fetch_url(url, data);
}

export const API_get_student_msgs = async student_id => {
    const url = `chat/get-student-msgs/${student_id}`;

    return await fetch_url(url);
}


// Course

export const API_get_all_courses = async () => {
    const url = `course`;

    return await fetch_url(url);
}

export const API_add_course = async (courseName, categoryId, courseDescription) => {
    const url = `course/add`;

    const data = await fetch_url(url, { courseName, categoryId, courseDescription });

    return data;
}

export const API_get_active_courses_by_category = async category_id => {

    const url = `course/active/${category_id}`;

    return await fetch_url(url);
}

export const API_update_course = async (course) => {
    const url = `course/update`;

    const data = await fetch_url(url, course);

    return await data;
}

export const API_get_courses_by_category = async category_id => {

    const url = `course/category/${category_id}`;

    return await fetch_url(url);
}

// Index

export const API_home_page_statistics = async () => {
    const url = '';

    return await fetch_url(url);
}


// Member

export const API_student_info = async (std_id, isTeacher) => {
    const url = `member/show`;

    return await fetch_url(url, { std_id, isTeacher });
}

export const API_save_member_info = async (member_data) => {
    const url = `member/update`;

    // const data = await fetch_url(url, member_data);

    // return data;

    return await fetch_url(url, member_data);
}

export const API_update_profile = async (data) => {
    const url = 'member/update-info';

    return await fetch_url(url, data);
}


// Payment

export const API_get_vodafone_pay_settings = async () => {
    const url = 'payment/get-vodafone-pay-settings';

    return await fetch_url(url)
}

export const API_get_payment_options = async () => {
    const url = 'payment/get-payment-options';

    return await fetch_url(url);
}

export const API_confirm_booking_payment = async bookingToConfirm => {
    const url = `payment/confirm-payment`;

    return await fetch_url(url, bookingToConfirm);
}

export const API_view_booking_payment = async booking_id => {
    const url = `payment/show/${booking_id}`;

    return await fetch_url(url);
}

export const API_moderate_booking_payment = async bookingToModerate => {
    const url = `payment/moderate-payment`;

    return await fetch_url(url, bookingToModerate);
}

export const API_get_fawry_payment_signature = async initData => {
    const url = `payment/get-fawry-payment-signature`;

    return await fetch_url(url, initData);
}

export const API_save_fawry_payment_response = async (fawry_response, transaction_data) => {

    // لازم يكونو معايا الريف بتاع التاجر والبياانانت اللي هاتأكد بيها ان الداتا هي نفسها

    const url = 'payment/store-fawry-payment-response';

    return await fetch_url(url, { fawry_response, transaction_data })
}

export const API_get_signature_list_all_fawry_payment_cards = async (member_id) => {
    const url = 'payment/get-signature-list-all-fawry-payment-cards';

    return fetch_url(url, { member_id });
}

export const API_get_signature_delete_fawry_payment_cards = async (cardToken, member_id) => {
    const url = 'payment/get-signature-delete-fawry-payment-card';

    return fetch_url(url, { cardToken, member_id });
}

export const API_save_vodafone_payment_reference = async data => {
    const url = 'payment/save-vodafone-payment-reference';

    return await fetch_url(url, data);
}


// Review


// Teacher

export const API_approve_teacher = async (teacher_id, approve) => {
    const url = `teacher/approve`;

    const data = {
        teacher_id,
        approve
    }

    return await fetch_url(url, data);
}

export const API_get_teachers_list = async () => {
    const url = `teacher/list`;

    // const data = await fetch_url(url);
    // return data;
    return await fetch_url(url);
}

export const API_add_teacher = async (teacher_data) => {
    const url = `teacher/create`;

    const data = await fetch_url(url, teacher_data);

    return data;
}

export const API_get_teachers_by_course = async (student_id, course_id) => {
    const url = `teacher`;
    // console.log('student_id, course_id', student_id, course_id)
    return await fetch_url(url, { student_id, course_id });
}

export const API_get_working_teachers = async student_id => {
    const url = `teacher`;

    return await fetch_url(url, { student_id });
}

export const API_get_teacher = async teacher_id => {
    const url = `teacher/administrate/${teacher_id}`;

    return await fetch_url(url);
}

export const API_get_teacher_skills = async teacher_id => {
    const url = `teacher/get-teacher-skills/${teacher_id}`;

    return await fetch_url(url);
}
export const API_moderate_instructor_skill = async skill_id => {
    const url = 'teacher/moderate-instructor-skill';

    return await fetch_url(url, { skill_id });
}

export const API_new_skill_request = async skill => {
    const url = 'teacher/new-skill-request';

    return await fetch_url(url, skill);
}

export const API_update_slots = async (teacher_id, slots) => {
    const url = `teacher/update-slots`;

    return await fetch_url(url, { teacher_id, slots });

}

export const API_update_skills = async (id, skills) => {
    const url = `teacher/update-skills`;

    const data = await fetch_url(url, { id, skills });

    return await data;
}

export const API_get_teacher_students = async (teacher_id) => {
    const url = `teacher/students/${teacher_id}`;

    return await fetch_url(url);
}

export const API_get_teacher_student = async (teacher_id, student_id) => {
    // console.log('teacher_id, student_id', teacher_id, student_id)
    const url = `teacher/student`;

    const data = { teacher_id, student_id }

    return await fetch_url(url, data);
}

export const API_join_as_teacher_request = async data => {
    const url = `teacher/join-as-teacher`;

    return await fetch_url(url, data);
}

export const API_get_teacher_availability = async teacher_id => {
    const url = `teacher/get-teacher-availability/${teacher_id}`;

    return await fetch_url(url);
}
