import { useState } from 'react'

// APIs
import { API_get_signature_list_all_fawry_payment_cards, API_get_fawry_payment_signature, API_save_fawry_payment_response } from '../../apis';

import { FAWRY_API_list_all_cards } from '../../apis/fawry';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ACTN_set_loading } from '../../Redux/SettingsReducer'

// react-bootstrap
import { Alert, Button, ButtonGroup, CloseButton, Container, FormControl, FormFloating, FormLabel, ListGroup, ListGroupItem, Modal, Spinner, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

// react-router-dom
import { Link, useNavigate } from 'react-router-dom';

// Payment Cards Logos
import visa from '../../assets/imgs/logo_card_visa.png';
import master from '../../assets/imgs/logo_card_master.png';
import meeza from '../../assets/imgs/logo_card_meeza.png';

export default function FawryForm({ totalFees, booking_data, hide_fawry_form, showFawryPaymentModal, setShowFawryPaymentModal }) {

    const { id, name, mobile, email } = useSelector(state => state.user);
    const { app_settings: { payment__general_section_expire_after_days, payment__fawry_section_enable_fawry, payment__fawry_section_merchant_code } } = useSelector(state => state.settings);
    // console.log(payment__general_section_expire_after_days)

    // const cardTokenUrl = `https://atfawry.fawrystaging.com/atfawry/plugin/card-token?accNo=+/IAAY2notjBISFhI9pvIg==&customerProfileId=${id}&returnUrl=http://localhost/SkillsDynamix/SkillsVantage/Booking_hours/v5/Back/payment/fawry-save-card-token?id=${id}`;
    const merchantCode = process.env.REACT_APP_FAWRY_ACCNO
    const cardTokenUrl = `https://atfawry.fawrystaging.com/atfawry/plugin/card-token?accNo=${merchantCode}&customerProfileId=${id}&returnUrl=http://localhost/SkillsDynamix/SkillsVantage/Booking_hours/v5/Back/payment/fawry-save-card-token/${id}`;
    const amount = parseFloat(totalFees).toFixed(2).toString();
    const currency = 'EGP';

    const paymentMethods = [
        { name: 'Pay at Fawry', value: 'PAYATFAWRY', description: 'This option will give you a reference number to pay with at any shop has Fawry logo' },
        { name: 'Card', value: 'CARD', description: 'Pay online with your Card (Safe and fast)' }
    ];

    const fawry_state = process.env.REACT_APP_FAWRY_STATE;
    let charge_url;

    if (fawry_state === 'development') {
        charge_url = process.env.REACT_APP_FAWRY_CHARGE_URL_DEV
    } else {
        charge_url = process.env.REACT_APP_FAWRY_CHARGE_URL_PROD
    }

    // console.log('fawry_state', fawry_state)
    // console.log('charge_url', charge_url)



    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
    const [errorMsg, setErrorMsg] = useState('');
    const [referenceNumber, setReferenceNumber] = useState('');
    const [paymentCards, setPaymentCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState([]);
    const [cvv, setCvv] = useState('');
    const [waitForNewCard, setWaitForNewCard] = useState(false);
    const [done, setDone] = useState(false);
    const [readyToPay, setReadyToPay] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('');
    const [waitForPayment, setWaitForPayment] = useState(false);
    const [redirectBtn, setRedirectBtn] = useState(false);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    /////////////////////////////////////////
    /**
     * Check all functions here that may add new booking
     * and disable if the update is set to true
     */


    const fnc_get_member_payment_cards = async () => {
        dispatch(ACTN_set_loading(true));
        await API_get_signature_list_all_fawry_payment_cards(id)
            .then(async signature => {
                dispatch(ACTN_set_loading(false));
                await FAWRY_API_list_all_cards(signature, merchantCode, id)
                    .then(cards_res => {
                        if (cards_res && cards_res.statusCode == 200) {
                            setPaymentCards(cards_res.cards)
                        }
                    })
            })
    }

    const handle_payment_method_changed = async option => {
        setErrorMsg('')
        setSelectedPaymentMethod(option)

        if (option.value == 'CARD') {
            fnc_get_member_payment_cards()
        }
    }

    const handle_card_changed = (token, firstSixDigits, lastFourDigits) => {

        setCvv('');
        setSelectedCard({ token, firstSixDigits, lastFourDigits })
    }

    const handle_card_added_clicked = () => {
        setWaitForNewCard(false)
        fnc_get_member_payment_cards()
    }

    const fnc_save_fawry_response = async (fawry_response, transaction_data) => {
        dispatch(ACTN_set_loading(true));

        await API_save_fawry_payment_response(fawry_response, transaction_data)
            .then(res => dispatch(ACTN_set_loading(false)))
    }

    const handle_pay_with_payatfawry_clicked = async () => {

        const paymentMethod = selectedPaymentMethod.value;

        if (!selectedPaymentMethod.value) {
            setErrorMsg('Select Payment Method');
            return;
        }

        dispatch(ACTN_set_loading(true));

        let initData = { ...booking_data, ...{ id, paymentMethod, amount } }

        await API_get_fawry_payment_signature(initData).then(async data => {

            if (data.length == 0) {
                setErrorMsg('Something went wrong, please try again or reload the page');
            }

            const signature = data.signature;
            const merchantRefNum = data.merchantRefNum;
            const booking_id = data.booking_id;
            const returnUrl = data.returnUrl;

            initData = { ...initData, booking_id, merchantRefNum, signature };

            // const signature = sha(`${merchantCode}${merchantRefNum}${id}${paymentOption}${amount}30ce3d98-ce5c-40e2-8b09-119ce1d5f7d1`);
            // const url = 'https://atfawry.fawrystaging.com/fawrypay-api/api/payments/init';

            const transaction_data = {
                "merchantCode": merchantCode,
                "customerName": name,
                "customerMobile": mobile,
                "customerEmail": email,
                "customerProfileId": id,
                "merchantRefNum": merchantRefNum,
                "amount": amount,
                "paymentExpiry": 24 * 60 * 60 * 1000 * payment__general_section_expire_after_days,
                "currencyCode": "EGP",
                "language": "en-gb",
                "chargeItems": [
                    {
                        "itemId": merchantRefNum,
                        "description": "Booked Hours",
                        "price": amount,
                        "quantity": "1"
                    }
                ],
                "signature": signature,
                "paymentMethod": paymentMethod,
                "description": "Booked Hours Payment",
                "authCaptureModePayment": false,
                "returnUrl": returnUrl
            }

            const transaction_data_json = JSON.stringify(transaction_data);


            // send to Fawry and get response


            await fetch(charge_url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: transaction_data_json,
            })
                .then(async res => await res.json())
                .then(async fawry_response => {
                    dispatch(ACTN_set_loading(false));
                    setDone(true);

                    /**
                    * Save response to database
                    */

                    transaction_data.booking_id = booking_id;

                    fnc_save_fawry_response(fawry_response, transaction_data)


                    if (fawry_response.statusCode == 9949) {
                        // statusDescription: "Request already processed, merchantRefNum value should be changed with each request"
                        setErrorMsg(`Your Booking has been created and set to pending, please visit your bookings page and request a payment reference.`);
                        setRedirectBtn(true)
                        return;
                    }

                    if (fawry_response.statusCode == 200) {
                        setReferenceNumber(fawry_response.referenceNumber);
                    }

                })
        })

    }

    const handle_pay_with_card_token_clicked = async () => {
        setErrorMsg('');

        const paymentMethod = selectedPaymentMethod.value;

        let initData = { ...booking_data, ...{ id, paymentMethod, amount, cvv, selectedCard } }

        // console.log('initData', initData)

        dispatch(ACTN_set_loading(true));
        await API_get_fawry_payment_signature(initData)
            .then(async data => {
                dispatch(ACTN_set_loading(false));
                if (data.length == 0) {
                    setErrorMsg('Something went wrong, please try again or reload the page');
                    return
                }

                const signature = data.signature;
                const merchantRefNum = data.merchantRefNum;
                const booking_id = data.booking_id;
                const cardToken = data.cardToken;
                const returnUrl = data.returnUrl;

                const transaction_data = {
                    "merchantCode": merchantCode,
                    "customerName": name,
                    "customerMobile": mobile,
                    "customerEmail": email,
                    "cvv": cvv,
                    "amount": amount,
                    "currencyCode": "EGP",
                    "language": "en-gb",
                    "chargeItems": [
                        {
                            "itemId": merchantRefNum,
                            "description": "Booked Hours",
                            "price": amount,
                            "quantity": "1"
                        }
                    ],
                    "paymentMethod": "CARD",
                    "description": "Booked Hours Payment",
                    "customerProfileId": id,
                    "merchantRefNum": merchantRefNum,
                    "cardToken": cardToken,
                    "signature": signature,
                    "enable3DS": true,
                    "authCaptureModePayment": false,
                    "returnUrl": returnUrl
                }

                const transaction_data_json = JSON.stringify(transaction_data);

                dispatch(ACTN_set_loading(true));

                await fetch(charge_url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: transaction_data_json,
                })
                    .then(async res => await res.json())
                    .then(async fawry_response => {
                        dispatch(ACTN_set_loading(false));
                        setDone(true);

                        /**
                         * Save response to database
                         */

                        // console.log('fawry_response', fawry_response)

                        transaction_data.booking_id = booking_id;


                        if (fawry_response.statusCode == 9949) {
                            // statusDescription: "Request already processed, merchantRefNum value should be changed with each request"
                            setErrorMsg(`Your Booking has been created and set to pending, please visit your bookings page and request a payment reference.`);
                            setRedirectBtn(true)

                            return;
                        }

                        if (fawry_response.statusCode == 200) {

                            setRedirectUrl(fawry_response.nextAction.redirectUrl)
                            setReadyToPay(true);


                        }
                    })
            })

    }

    const reload_page = () => {
        window.location.reload();
    }

    return (
        <Modal show={showFawryPaymentModal} backdrop='static' onHide={() => setShowFawryPaymentModal(false)} keyboard={false} >
            <Modal.Header>
                Pay Your Booking Fees by Fawry
                <CloseButton onClick={reload_page}></CloseButton>
            </Modal.Header>

            <Modal.Body>

                <Container>
                    <h5>Booking Details</h5>
                    <ListGroup>
                        <ListGroupItem className='d-flex justify-content-between'>
                            <span>Subject</span>
                            <span>{booking_data.course_name}</span>
                        </ListGroupItem>
                        <ListGroupItem className='d-flex justify-content-between'>
                            <span>Instructor</span>
                            <span>{booking_data.teacher_name}</span>
                        </ListGroupItem>
                        <ListGroupItem className='d-flex justify-content-between'>
                            <span>Hours</span>
                            <span>{booking_data.hours}</span>
                        </ListGroupItem>
                        <ListGroupItem className='d-flex justify-content-between'>
                            <span>Hour Rate</span>
                            <span>{currency} {booking_data.rate}</span>
                        </ListGroupItem>
                        <ListGroupItem className='d-flex justify-content-between bg-primary text-light'>
                            <span>I am ready to pay</span>
                            <span>{currency} {`${booking_data.rate * booking_data.hours}`}</span>
                        </ListGroupItem>

                    </ListGroup>
                </Container>

                {
                    !referenceNumber &&
                    <>
                        {
                            waitForNewCard &&
                            <Container className='p-5 text-center d-flex flex-column align-items-center justify-content-center'>
                                <p className='text-center'>After adding your new card, click the button below.</p>
                                <Spinner animation='border' variant='primary' className='my-5'></Spinner>
                                <Button onClick={handle_card_added_clicked}>DONE</Button>
                            </Container>
                        }

                        {
                            (!readyToPay && !waitForNewCard) &&
                            <>

                                <div>
                                    <div className='my-3'>
                                        <small>Select Payment Method</small>
                                    </div>

                                    <ButtonGroup>
                                        {paymentMethods.map((radio, idx) => (
                                            <ToggleButton
                                                key={idx}
                                                id={`radio-${idx}`}
                                                type="radio"
                                                variant='outline-primary'
                                                name="radio"
                                                value={radio.value}
                                                checked={selectedPaymentMethod.value === radio.value}
                                                onChange={() => handle_payment_method_changed(radio)}
                                            >
                                                {radio.name}
                                            </ToggleButton>
                                        ))}
                                    </ButtonGroup>
                                    {
                                        selectedPaymentMethod.description &&
                                        <Alert variant='info' className='mt-3'>{selectedPaymentMethod.description}</Alert>
                                    }
                                </div>
                                <p className='text-danger'>{errorMsg}</p>
                                {
                                    redirectBtn &&
                                    <Link to='/account/bookings'>Get a payment reference</Link>
                                }

                                {
                                    (selectedPaymentMethod.value == 'CARD' && !readyToPay && !redirectBtn) &&
                                    <div>
                                        <a href={cardTokenUrl} target='_blank' onClick={() => setWaitForNewCard(true)}>+ Add New Card</a>
                                        {
                                            paymentCards.length > 0 &&
                                            <div>
                                                <div className='mt-3 d-flex justify-content-between align-items-center'>
                                                    <p>Or select a card from your list</p>
                                                </div>
                                                <ListGroup>
                                                    {paymentCards.map(({ token, firstSixDigits, lastFourDigits }, i) => {
                                                        let card = master;

                                                        if (firstSixDigits.substr(0, 1) == '4') {
                                                            card = visa;
                                                        } else if (firstSixDigits.substr(0, 2) == '50') {
                                                            card = meeza;
                                                        }

                                                        return (<ListGroupItem key={i} onClick={() => handle_card_changed(token, firstSixDigits, lastFourDigits)} className='d-flex align-items-center justify-content-between'>
                                                            <div className='d-flex'>
                                                                <img src={card} height='24' alt='card_logo' className='me-3' />
                                                                <span>
                                                                    {`${firstSixDigits}*********${lastFourDigits}`}
                                                                </span>
                                                            </div>
                                                        </ListGroupItem>)
                                                    }
                                                    )}
                                                </ListGroup>
                                                {
                                                    selectedCard.token &&
                                                    <FormFloating className='my-3'>
                                                        <FormControl type='text' placeholder=' ' value={cvv} onChange={e => setCvv(e.target.value)} style={{ width: '150px' }} />
                                                        <FormLabel>CVV</FormLabel>
                                                    </FormFloating>
                                                }
                                                {
                                                    (cvv && cvv.length == 3) &&
                                                    <Button onClick={handle_pay_with_card_token_clicked}>Pay {`${booking_data.rate * booking_data.hours}`}</Button>
                                                }

                                            </div>
                                        }
                                    </div>
                                }

                                {
                                    selectedPaymentMethod.value == 'PAYATFAWRY' &&
                                    <div className='d-flex justify-content-end'>
                                        <ButtonGroup>
                                            {/* <Button variant='danger' onClick={() => hide_fawry_form(false)}>Cancel</Button> */}
                                            <Button variant='primary' onClick={handle_pay_with_payatfawry_clicked}>Book Now</Button>
                                        </ButtonGroup>
                                    </div>
                                }


                            </>
                        }
                    </>
                }

                {
                    referenceNumber &&
                    <div className='text-center'>
                        <p>Your booking has been stored and set as</p>
                        <h4>pending</h4>
                        <span>for</span>
                        <h5>{payment__general_section_expire_after_days} days</h5>
                        <p>untill you complete the payment process using the following reference number</p>
                        <h3 className='text-primary'>{referenceNumber}</h3>
                        <Button onClick={() => { navigate('/account') }}>OK</Button>
                    </div>
                }

                {
                    (readyToPay && !waitForPayment) &&
                    <Alert variant='success' className='m-3'>
                        Your payment card is accepted, click <a href={redirectUrl} target='_blank' className='alert-link' onClick={() => setWaitForPayment(true)}>here</a> to pay.
                    </Alert>
                }

                {
                    waitForPayment &&
                    <Button variant='success' className='m-3' onClick={() => navigate('/account/bookings')}>Check Your Booking</Button>
                }
            </Modal.Body>
        </Modal>
    )
}
