// react-router-dom
import { useNavigate } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ACTN_show_register } from '../../Redux/SettingsReducer';

// Icons
import { BsCalendarCheck } from 'react-icons/bs';
import { MdOutlinePermDataSetting } from 'react-icons/md';
import { FaPeopleArrows } from 'react-icons/fa';
import { RiHomeWifiLine } from 'react-icons/ri';

import bannerImg from '../../assets/imgs/banner_07.jpg'

export default function SectionHero() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { loggedIn } = useSelector((state) => state.user);

	const { instructor_label, home__banner_img } = useSelector(
		(state) => state.settings.app_settings
	);

	return (
		<div className="flex gap-4 flex-col lg:flex-row-reverse bg-gray-200">
			{/* Home Banner */}
			<div className="lg:w-7/12">
				<img src={bannerImg} className="w-full h-full object-cover" />
			</div>

			<div className="lg:w-5/12 flex flex-col  p-4">
				{/* Features Section */}
				<div className="flex flex-col gap-12 flex-grow">
					<h3 className="mb-3  text-sky-600">
						Contact your teacher now<br />
						and increase your skills
					</h3>

					<div className="flex flex-col gap-8">
						<div className="flex flex-col gap-8">
							<div className="flex items-center">
								<BsCalendarCheck className="text-lime-700" size={40} />
								<h5 className="mx-3">Create your own schedule</h5>
							</div>

							<div className="flex items-center">
								<MdOutlinePermDataSetting className="text-lime-700" size={40} />
								<h5 className="mx-3">Customize the Course Content</h5>
							</div>
						</div>

						<div className="flex flex-col gap-8">
							<div className="flex items-center">
								<FaPeopleArrows className="text-lime-800" size={40} />
								<h5 className="mx-3">Private One-to-One Sessions</h5>
							</div>

							<div className="flex items-center">
								<RiHomeWifiLine className="text-lime-800  display-6" size={40} />
								<h5 className="mx-3">Learn From Your Comfort Zone</h5>
							</div>
						</div>
					</div>

					{/* <div className="flex">
						<button disabled
							onClick={() => {
								loggedIn
									? navigate('/account/teachers')
									: dispatch(ACTN_show_register());
							}}
							className="text-2xl py-2 px-4 rounded-lg text-white bg-sky-600 shadow"
						>
							Start Now
						</button>
					</div> */}


				</div>
			</div>
		</div>
	);
}
