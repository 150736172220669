const password_Regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&+\*])(?=.{8,})");

const email_regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

const url_regex = new RegExp('^(https?|chrome):\/\/[^\s$.?#].[^\s]*$');

const mobile_regex = new RegExp('^.*[+0][0-9](?=.{8,})');

const genders = [
    { name: 'Female', value: 'Female' },
    { name: 'Male', value: 'Male' },
];

let baseURL;

if (window.location.hostname == 'localhost') {
    baseURL = process.env.REACT_APP_BASE_URL_DEV;
} else {
    baseURL = process.env.REACT_APP_BASE_URL_PROD;
}

const publicURL = `${baseURL}public/`;
const imgsURL = `${publicURL}images/`;
const categoriesURL = `${imgsURL}categories/`;
const defaultCategoryImg = `${categoriesURL}default.png`;


export {
    password_Regex,
    email_regex,
    url_regex,
    mobile_regex,
    genders,
    baseURL,
    publicURL,
    imgsURL,
    categoriesURL,
    defaultCategoryImg
};