import React, { useEffect, useState } from 'react'

// APIs
import { API_get_student_calendar } from '../../apis';

// Redux
import { useSelector } from 'react-redux';

// React Bootstrap
import { Alert, Container } from 'react-bootstrap';

// Components
import Schedule from './Schedule'

// Helpers
import { hlpr_sort_dates } from '../../Helpers/Functions';
import { Link } from 'react-router-dom';

export default function Calendar() {

    const [bookings, setBookings] = useState([]);

    const student_id = useSelector(state => state.user.id)

    const { isAdmin } = useSelector(state => state.user)

    useEffect(() => {
        if (!isAdmin) {
            fnc_list_upcoming_bookings();
        }
    }, [])

    const fnc_list_upcoming_bookings = async () => {
        await API_get_student_calendar(student_id)
            .then(data => {
                const sorted_bookings = hlpr_sort_dates(data)

                const schdl = {};

                sorted_bookings.forEach(book => {
                    if (Array.isArray(schdl[book.slot_date])) {
                        schdl[book.slot_date].splice(0, 0, book);
                    } else {
                        schdl[book.slot_date] = [book];
                    }

                })

                setBookings(schdl);

            })

    }

    return (
        <Container>
            <h2>My Upcoming Sessions</h2>
            {
                Object.keys(bookings).length == 0 &&
                <Alert>
                    <h2>You don't have any bookings, <Link to={'/teachers'} >book your first skill now</Link></h2>
                </Alert>
            }
            <Schedule bookings={bookings} />

        </Container>
    )
}
