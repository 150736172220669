import { API_get_user_messages_count } from '../apis'

export const hlpr_booking_schedule = ({ date }) => {

    let start_time = '';
    let end_time = '';
    let item = [];

    const create_time = time => {
        const hour = time.slice(0, 2);

        let hour_int = parseInt(hour);
        hour_int = hour_int + 1;
        hour_int = hour_int.toString();

        if (hour_int.length === 1) {
            hour_int = '0' + hour_int;
        }

        return hour_int + time.slice(2);
    }

    // console.log(date)
    return (
        <div>
            <h6>Times</h6>
            {
                // ['09 AM', '10 AM', '11 AM', '12 PM', '01 PM', '02 PM', '03 PM', '04 PM']
                // Slot '10 AM';
                // start_time = '09 AM';
                // end_time = '10 AM';

                date.forEach(slot => {

                    if (start_time === '') {
                        start_time = slot;
                        end_time = create_time(slot);
                    } else {
                        // If the slot is equal to the end_time, then we need to create a new item
                        if (slot === end_time) {
                            end_time = create_time(slot);
                        } else { // If the slot is not equal to the end_time, then we need to create a new item
                            end_time = create_time(slot);
                            item.push(<p>{start_time} - {end_time}</p>);
                            // Then reset start_time to empty and end_time to empty
                            start_time = '';
                            end_time = '';
                        }
                    }

                    // item.push(<h4>{start_time} - N - {end_time}</h4>)
                })
            }
            {item}

        </div>
    )

}

export const hlpr_from_to_slot = slot => {
    if (slot == 11) {
        return `${slot} AM - 12 PM`
    } else if (slot == 23) {
        slot = slot - 12
        return `${slot} PM - 12 AM`
    } else if (slot == 12) {
        return `${slot} PM - 01 PM`
    } else if (slot > 12) {
        let from = slot - 12;
        let to = padTo2Digits(from + 1);
        from = padTo2Digits(from);

        return `${from} PM - ${to} PM`

    } else {
        let from = parseInt(slot);
        let to = from + 1;

        from = padTo2Digits(from);
        to = padTo2Digits(to);
        return `${slot} AM - ${to} AM`

    }

}

// export const hlpr_from_to_slot = slot => {
//     if (slot == '11 AM') {
//         return `${slot} - 12 PM`
//     } else if (slot == '11 PM') {
//         return `${slot} - 12 AM`
//     } else {
//         let from = parseInt(slot.substr(0, 2)) + 1;

//         from = from > 11 ? from - 12 : from;

//         from = from.toString();

//         from = from.length == 1 ? '0' + from : from;

//         const to = from + slot.substr(2);
//         return `${slot} - ${to}`
//     }
// }
export const date_add = days => {
    const today = new Date();
    return new Date(today.setDate(today.getDate() + days))
}

export const formatDate = date => {
    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-') +
        ' ' +
        [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            // padTo2Digits(date.getSeconds()),  // 👈️ can also add seconds
        ].join(':')
    );
}

export const hlpr_sort_dates = arr => {
    // console.log('hlpr_sort_dates-->>arr', arr);
    let sorted = [];

    if (Array.isArray(arr)) {
        sorted = arr.sort((a, b) => new Date(a.slot_date) - new Date(b.slot_date));
    }
    return sorted
};

export const hlpr_sort_array = arr => {
    const sorted = arr.sort((a, b) => {
        return a - b;
    }
    );
    return sorted
};

export const hlpr_sort_array_by_ref = (arr, fld) => {
    const sorted = arr.sort((a, b) => {
        return a[fld] - b[fld];
    }
    );
    return sorted
};

export const hlpr_get_user_messages = async (dispatch, ACTN_set_msgs_count, id) => {
    await API_get_user_messages_count(id).then(msgs => {
        // console.log('msgs', msgs)

        let student_all = 0;
        let student_new = 0;
        let teacher_all = 0;
        let teacher_new = 0;

        // let all_msgs = 0;
        // let new_msgs = 0;

        if (Object.keys(msgs).length > 0) {
            //     all_msgs = msgs.length;
            student_all = msgs.student_all || 0;
            student_new = msgs.student_seen ? msgs.student_all - msgs.student_seen : 0;
            teacher_all = msgs.teacher_all || 0;
            teacher_new = msgs.teacher_seen ? msgs.teacher_all - msgs.teacher_seen : 0;

            //     new_msgs = msgs.filter(msg => msg == 0).length
        }


        dispatch(ACTN_set_msgs_count({
            student_all, student_new, teacher_all, teacher_new
        }));


    })
}

export const hlpr_get_localstorage = () => {

    let user_data = false, type = '';

    if (localStorage.getItem('svbh_loggedin') || sessionStorage.getItem('temp_svbh_loggedin')) {
        if (localStorage.getItem('svbh_loggedin')) {
            user_data = localStorage.getItem('svbh_loggedin');
            type = 'localstorage';
        } else {
            user_data = sessionStorage.getItem('temp_svbh_loggedin');
            type = 'sessionstorage';
        }
        user_data = JSON.parse(user_data);
    }
    // console.log('hlpr_get_localstorage->>user_data, type', user_data, type)
    // debugger
    return { user_data, type };
}

export const get_video_part = url => {
    // console.log('url', url)
    if (url) {
        return url.substr(url.lastIndexOf('/') + 1).replace('watch?v=', '').replace('embed/', '');
    }
    return '';
}

const padTo2Digits = num => num.toString().padStart(2, '0');

export const hlpr_all_required_pass = obj => {

    let pass = true;

    Object.keys(obj).map(k => obj[k] == '' && (pass = false))

    return pass
}