import { useEffect, useState } from 'react'

// APIs
import { API_get_all_categories } from '../../apis';

// Libraries
import { Container } from 'react-bootstrap';

// Components
import TableCategories from '../../Components/Admin/TableCategories';
import AddCategory from '../../Components/Admin/AddCategory';

// Consts
import { categoriesURL, defaultCategoryImg } from '../../Helpers/consts'

// Redux
import store from '../../Redux/store';
import { ACTN_set_categories } from '../../Redux/CoursesReducer';

export default function Categories() {

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fnc_get_all_categories()
    }, []);

    const fnc_get_all_categories = async () => {
        await API_get_all_categories()
            .then(res => {
                setCategories(res)
                store.dispatch(ACTN_set_categories(res));
            })
    }

    return (
        <Container>
            <AddCategory default_img={defaultCategoryImg} />

            <TableCategories categoriesURL={categoriesURL} default_img={defaultCategoryImg} />

        </Container>
    )
}
