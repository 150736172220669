import Header from '../Components/Header';
import OurTeacher from '../Components/OurTeacher';

export default function Teachers() {

    return (
        <>
            <Header />
            <OurTeacher />
        </>
    )
}






