import { useState, useEffect } from 'react'

// Consts
import { genders, url_regex } from '../../Helpers/consts'

// Redux
import { useDispatch } from 'react-redux'
import { ACTN_set_loading } from '../../Redux/SettingsReducer'
import { ACTN_set_current_teacher } from '../../Redux/UserReducer'

// React Router DOM
import { useParams } from 'react-router-dom'

// Redux
import { useSelector } from 'react-redux';

// react-bootstrap
import {
    Col, Container, Row, FormFloating, FormControl, FormLabel, Button, Form, ButtonGroup, ToggleButton, FormGroup
} from 'react-bootstrap'

import { API_save_member_info } from '../../apis'
import { toast } from 'react-toastify'

export default function TeacherInfo({ teacher }) {

    // const teacher = useSelector(state => state.user.current_teacher);

    // console.log('TeacherInfo->>>teacher::', teacher)
    // 'member_id': teacher.member_id,

    const [teacherData, setTeacherData] = useState({});

    const [valid, setValid] = useState({
        'first_name': false,
        'middle_name': false,
        'last_name': false,
        'email': false,
        'mobile': false,
        'nationality': false,
        'bio': false,
        'promo_video': false,
        'gender': false,
    })

    const { id } = useParams();

    const [updateMsg, setUpdateMessage] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        setTeacherData({ ...teacher, ...{ 'member_id': id, 'isTeacher': true } });
    }, [id, teacher]);


    const handle_teacher_data = evt => {
        let id = evt.target.id;
        const val = evt.target.value;

        if (id.indexOf('gender') == 0) {
            id = 'gender'
        } else if (id == 'promo_video') {
            setValid({
                ...valid, ...{ [id]: url_regex.test(val) }
            })
        }
        // console.log(id, val)

        setTeacherData({ ...teacherData, ...{ [id]: val } })
    }

    const handle_save_info = async e => {
        // console.log('handle_save_info Function ->  teacherData:', teacherData)

        e.preventDefault();

        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.stopPropagation();

            toast.error('All Fields are required!!!')
            return;
        }


        await API_save_member_info(teacherData).then(response => {

            let msg = "";

            if (response) {
                msg = "Teacher's Info Updated Successfully";
                dispatch(ACTN_set_current_teacher(teacherData))
                setUpdateMessage(<div className='alert alert-success'>
                    <h3>{msg}</h3>
                </div>)
                toast.success(msg)
            } else {
                msg = "Teacher's Info was not updated successfully, please reload the page and try again!";
                setUpdateMessage(<div className='alert alert-danger'>
                    <h3>{msg}</h3>
                </div>)
                toast.error(msg);
            }
        })
    }

    return (
        <Container className='m-3 border shadow-sm bg-light'>
            {updateMsg}
            <Form noValidate onSubmit={handle_save_info} method='post' validated={true}>

                <input type='hidden' name='isTeacher' id='isTeacher' value='true' />
                <Row>
                    <Col md={4}>
                        <FormFloating className='my-3'>
                            <FormControl type='text' placeholder=' ' required minLength={3} maxLength={15} value={teacherData.first_name || ''} id='first_name' onChange={e => handle_teacher_data(e)} />
                            <FormLabel>First Name</FormLabel>
                            <p className='invalid-feedback'>Between 3 and 15 Charachters</p>
                        </FormFloating>
                    </Col>

                    <Col md={4}>
                        <FormFloating className='my-3'>
                            <FormControl type='text' placeholder=' ' required minLength={3} maxLength={15} value={teacherData.middle_name || ''} id='middle_name' onChange={e => handle_teacher_data(e)} />
                            <FormLabel>Middle Name</FormLabel>
                            <p className='invalid-feedback'>Between 3 and 15 Charachters</p>
                        </FormFloating>
                    </Col>

                    <Col md={4}>
                        <FormFloating className='my-3'>
                            <FormControl type='text' placeholder=' ' required minLength={3} maxLength={15} value={teacherData.last_name || ''} id='last_name' onChange={e => handle_teacher_data(e)} />
                            <FormLabel>Last Name</FormLabel>
                            <p className='invalid-feedback'>Between 3 and 15 Charachters</p>
                        </FormFloating>
                    </Col>
                </Row>

                <Row>
                    <Col>

                        <FormFloating className='my-0'>
                            <FormControl type='text' placeholder=' ' required value={teacherData.mobile || ''} id='mobile' onChange={e => handle_teacher_data(e)} />
                            <FormLabel>Mobile</FormLabel>
                            <p className='invalid-feedback'>Required</p>
                        </FormFloating>

                        <FormFloating className='my-3'>
                            <FormControl type='text' placeholder=' ' required value={teacherData.nationality || ''} id='nationality' onChange={e => handle_teacher_data(e)} />
                            <FormLabel>Nationality</FormLabel>
                            <p className='invalid-feedback'>Required</p>
                        </FormFloating>

                        <FormFloating className='my-3'>
                            <FormControl type='text' placeholder=' ' required value={teacherData.promo_video || ''} id='promo_video' onChange={e => handle_teacher_data(e)} />
                            <FormLabel>Youtube Promo Video URL</FormLabel>
                            <p className='invalid-feedback'>Required</p>
                        </FormFloating>

                        <FormGroup className='d-flex flex-column my-3'>
                            <FormLabel>Bio</FormLabel>
                            <textarea as='textarea' placeholder=' ' required minLength={20} maxLength={500} value={teacherData.bio || ''} id='bio' rows={15} onChange={e => handle_teacher_data(e)}>
                            </textarea>
                            <p className='invalid-feedback'>Between 20 and 500 Charachters</p>
                        </FormGroup>


                    </Col>
                </Row>

                <Row>
                    <Col>
                        <fieldset>
                            <legend>Gender</legend>
                            <ButtonGroup className="mb-2">
                                {genders.map((radio, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        id={`gender-${idx}`}
                                        type="radio"
                                        variant={idx % 2 ? 'outline-success' : 'outline-danger'}
                                        name="gender"
                                        value={radio.value}
                                        checked={teacherData.gender === radio.value}
                                        onChange={(e) => handle_teacher_data(e)}
                                        required
                                    >
                                        {radio.name}
                                    </ToggleButton>
                                ))}

                            </ButtonGroup>

                            <p className='text-danger'>{teacherData.gender == '' && 'Required'}</p>
                        </fieldset>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='d-flex justify-content-end py-4'>
                            <Button variant='primary' type='submit'>Save Updates</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}
