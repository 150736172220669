import { useEffect, useState } from "react";

// react-bootstrap
import { Alert, Button, ButtonGroup, Container, Form, InputGroup, ToggleButton } from "react-bootstrap";

// APIs
import { API_approve_teacher } from '../../apis';

// redux
import { useDispatch } from "react-redux";
import { ACTN_set_loading } from '../../Redux/SettingsReducer'

export default function TeacherAdministrate({ teacher }) {

    const [variant, setVariant] = useState('outline-warning');
    const [selectedApprove, setSelectedApprove] = useState('');
    const [updated, setUpdated] = useState(false);
    const [ready, setReady] = useState(false);

    const approves = [
        { name: 'Approve', value: '1', selected: false },
        { name: 'Pending', value: '0', selected: false },
        { name: 'Reject', value: '-1', selected: false },
    ];

    const dispatch = useDispatch();

    useEffect(() => {
        if (updated) {
            setTimeout(() => {
                setUpdated(false)
            }, 2000)
        }

        // if (!ready) {
        fnc_update_state(teacher.approved);
        // }
    }, [teacher, ready])

    const fnc_update_state = stat => {
        setSelectedApprove(stat);
        setReady(true);

        switch (stat) {
            case 0:
            case '0':
                setVariant('warning')
                break;
            case 1:
            case '1':
                setVariant('success')
                break;
            case -1:
            case '-1':
                setVariant('danger')
                break;
        }
    }

    const handle_save_clicked = async () => {

        dispatch(ACTN_set_loading(true));
        await API_approve_teacher(teacher.member_id, selectedApprove).then(res => {
            dispatch(ACTN_set_loading(false));

            if (res) {
                setUpdated(true);
                setSelectedApprove(selectedApprove);
            }
        });
    }

    const handle_approve_changed = e => {
        const stat = e.target.value;
        fnc_update_state(stat);
    }

    if (Object.keys(teacher).length == 0) { return }

    return (
        <Container>
            <p>Current Status for Join as Teacher</p>
            <ButtonGroup>
                {approves.map((stat, i) => {
                    return (
                        <ToggleButton
                            key={i}
                            id={`radio-${i}`}
                            type="radio"
                            variant={`outline-${variant}`}
                            name="radio"
                            value={stat.value}
                            checked={selectedApprove == stat.value}
                            onChange={handle_approve_changed}
                        >
                            {stat.name}
                        </ToggleButton>
                    )
                })}
            </ButtonGroup>
            <div className="my-3">
                <Button onClick={handle_save_clicked}>Update Status</Button>
            </div>

            {
                updated &&
                <Alert variant="success">
                    Status Updated Successfully
                </Alert>
            }
        </Container>
    )
}