import { useState, useEffect } from 'react'

// react-bootstrap
import { Button, Form, InputGroup } from 'react-bootstrap'

// toast
import { toast } from 'react-toastify'

// APIs
import { API_upload_image } from '../apis'

// Icons
import { FiCheckCircle } from 'react-icons/fi'

export default function ImageUpload({ label, image_name, img_path }) {

  const [image, setdImage] = useState(null);

  const [imagePreview, setdImagePreview] = useState(null);

  const [uploaded, setUploaded] = useState(true);

  const [imagePath, setImagePath] = useState(img_path);

  useEffect(() => {

    setdImagePreview(img_path);

    if (image) {
      const reader = new FileReader();

      reader.onloadend = e => {
        setdImagePreview(reader.result)
      };

      reader.readAsDataURL(image);

    } else {
      setdImagePreview(imagePath)
    }
  }, [image, img_path]);

  const handle_image_selected = e => {
    setUploaded(false)
    const file = e.target.files[0];

    if (file && file.type.substr(0, 5) === 'image') {
      setdImage(file)
    } else {
      setdImage(null)
    }
  }

  const upload_image = async () => {
    await API_upload_image(image, image_name)
      .then(async upload_res => {
        if (upload_res === false) {
          toast.error('Image not accepted for unknown reason, please select another image!');
          return
        }
        if (upload_res) {
          toast.success('Image uploaded successfully');
          setImagePath(upload_res);
          setUploaded(true)
        }
      })
  }

  return (
    <div className='img_uploader'>
      <span>{label}</span>

      {
        !uploaded &&
        <strong onClick={upload_image}>Save Image</strong>
      }

      <div className='preview'>
        <label htmlFor='uploader'>
          <img src={imagePreview} />
          {uploaded && <span><FiCheckCircle /></span>}
        </label>
      </div>

      <input type="file" id='uploader' accept='image/png' onChange={handle_image_selected} required />

    </div>
  )
}
