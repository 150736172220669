// react-bootstrap
import { Alert, Container, ListGroup, ListGroupItem } from 'react-bootstrap'

// Components
import Header from '../Components/Header'

export default function TaC() {
    return (
        <>
            <Header />
            <Container>
                <h1>Terms and Conditions</h1>
                <h6>Dear Learners of <strong>Learn 4 Skills</strong>,</h6>
                <p>The following terms and conditions have been laid out to guarantee the success of the learning process. You are expected to adhere to these terms during your subscription with <strong>Learn 4 Skills</strong>.</p>
                <h3>Terms & Conditions:</h3>

                <ListGroup>

                    <ListGroupItem>
                        1: The learner may cancel & reschedule up to 2 sessions from every 8 Hour level as long as the cancellation happened at least 8 hours before the time of the session.
                    </ListGroupItem>
                    <ListGroupItem>
                        2: If a learner cancels a session less than 8 hours before the time of the session, the session will not be rescheduled or refunded.
                    </ListGroupItem>
                    <ListGroupItem>
                        3: The learner may be refunded for 1 session only for every 8 Hour level due to technical problems such as: internet connectivity, audio/video issues, hardware problems. The learner must make sure that their internet connection is stable and has a working mic and earphones. Please make sure you always take your private sessions in a quiet environment.
                    </ListGroupItem>
                    <ListGroupItem>
                        4: The learner may book their sessions in 2 methods:
                        <ListGroup>
                            <ListGroupItem>
                                A: Booking all course sessions in advance with the same instructor.
                            </ListGroupItem>
                            <ListGroupItem>
                                B: Booking a session at a time with a maximum of 2 instructors per level.
                            </ListGroupItem>
                        </ListGroup>
                    </ListGroupItem>
                    <ListGroupItem>
                        5: Sessions are booked at least 24 hours in advance.
                    </ListGroupItem>
                    <ListGroupItem>
                        6: The learner has the right to record the session or ask the teacher to record the session on their behalf. We have the right to retain all session recordings for quality and training purposes. <strong>Learn 4 Skills</strong> does not guarantee the recording of the learners’ sessions.
                    </ListGroupItem>
                    <ListGroupItem>
                        7: The duration of an 8-hour level is 60 days from the date of the level purchase.
                    </ListGroupItem>
                    <ListGroupItem>
                        8: Changing the instructor policy:
                        <ListGroup>
                            <ListGroupItem>
                                A: The learner has the right to replace the instructor if they do not feel comfortable with the instructor’s ability to teach them only after the 1st session. The session will be refunded with a new instructor of the learner’s choice. The learner may request an instructor change up to 2 times after the first session of every 8-hour level.
                            </ListGroupItem>
                            <ListGroupItem>
                                B: The learner has the right to replace the instructor if the instructor did not show up in the 1st session.
                            </ListGroupItem>
                            <ListGroupItem>
                                C: The learner has the right to replace the instructor if the instructor did not show up 2 times during the 8-hour level.
                            </ListGroupItem>
                            <ListGroupItem>
                                D: The learner has the right to replace the instructor if the instructor rescheduled more than 2 sessions during the 8-hour level.
                            </ListGroupItem>
                            <ListGroupItem>
                                E: In the case of no noticeable improvement after 16 sessions with the same instructor, we will change the instructor.
                            </ListGroupItem>
                        </ListGroup>
                    </ListGroupItem>
                    <ListGroupItem>
                        9: The learner may freeze once per course for a maximum of 1 month. After the freeze period the course will expire.
                    </ListGroupItem>
                    <ListGroupItem>
                        10: If a learner does not show up for 2 sessions in a row, the rest of the schedule will be automatically dropped from the instructor’s schedule. No shows are never refunded, and the 2 sessions will be considered as canceled sessions.
                    </ListGroupItem>
                    <ListGroupItem>
                        11: <strong>Learn 4 Skills</strong> reserves the right to replace the instructor without prior notice, however, clear reasons will be given. In most cases this will happen to guarantee a better learning process for the learner.
                    </ListGroupItem>
                    <ListGroupItem>
                        12: If an instructor cancels a booking less than 8 hours before the session time, the session will be refunded for the learner and the learner will receive 1 extra bonus hours.
                    </ListGroupItem>
                    <ListGroupItem>
                        13: If an instructor cancels a booking 8 hours or more before the session, only the canceled session will be refunded.
                    </ListGroupItem>
                    <ListGroupItem>
                        14: If an instructor cancels a booking 8 hours or more before the session more than 2 times per 8-hour level, the learner may change the instructor.
                    </ListGroupItem>
                    <ListGroupItem>
                        <h6>15: Lateness Policy:</h6>

                        Being late is not considered as welcomed behavior. Being punctual and respecting appointments is essential to have a successful learning process.
                        <ListGroup>
                            <ListGroupItem>
                                A: The learner and the instructor are allowed up to 5 minutes to start the 1-hour session.
                            </ListGroupItem>
                            <ListGroupItem>
                                B: If the learner is late from 6 to 20 minutes, the session will end on time, and the learner may not ask for the lost time.
                            </ListGroupItem>
                            <ListGroupItem>
                                C: If the instructor is late from 6 to 10 minutes 2 times or more, the learner will receive 1 bonus session for every 2 times.
                            </ListGroupItem>
                            <ListGroupItem>
                                D: If the instructor is late from 11 to 15 minutes, the session will be continued, however the learner has the right to claim an extra bonus hour at the end of the level.
                            </ListGroupItem>
                            <ListGroupItem>
                                E: If the instructor is late more than 15 minutes, the session is canceled and refunded, and the learner receives 1 extra bonus hour.
                            </ListGroupItem>
                            <ListGroupItem>
                                F: If the learner is late for more than 20 minutes, the session is canceled and will not be refunded.
                            </ListGroupItem>
                        </ListGroup>
                    </ListGroupItem>
                    <ListGroupItem>
                        <h6>16: Cancellation & Refund Policy</h6>
                        <ListGroup>
                            <ListGroupItem>
                                A: The learner may receive a full refund with no deductions in the following conditions:
                                <ListGroup>
                                    <ListGroupItem>
                                        A.1: If the requested schedule is not fulfilled and the learner isn’t able to start for more than 14 days from the request.
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        A.2: If the student is not satisfied with more than 2 instructors after the trial of 2 hours.
                                    </ListGroupItem>
                                </ListGroup>
                            </ListGroupItem>
                        </ListGroup>
                    </ListGroupItem>
                    <ListGroupItem>
                        B: The learner may receive a refund with 10% administrative fees if the refund was requested within 14 days of purchase and the learner hasn’t started.
                    </ListGroupItem>
                    <ListGroupItem>
                        C: The learner has the right to refund the remaining sessions of a course as long as the course is not expired. The student will shoulder the 10% administrative fee of the whole course.
                    </ListGroupItem>
                    <ListGroupItem>
                        D: The learner may not request a refund for an expired course.
                    </ListGroupItem>
                    <ListGroupItem>
                        E: The learner may not request a refund for any other reasons.
                    </ListGroupItem>
                    <ListGroupItem>
                        F: The refund will be processed in the same payment method or by cash. In the case of “Fawry” payments, only cash refunds are possible. Please allow up to 30 days from the date of the request for the refund to be processed.
                    </ListGroupItem>
                </ListGroup>
                <Alert className='my-4'>
                    <strong>Notice: </strong>
                    The terms &amp; conditions mentioned above are not absolute and they could be updated and improved periodically.
                </Alert>

                {/* Teacher Application
            Terms & Policy

            Terms & Conditions
            Privacy Policy
            Contact Us
            Address

            Visa
            mastercard
            fawry
            meeza
            orange
            etisalate
            vodafone cash
            small wallet
            Powered By */}

            </Container>
        </>
    )
}
