import React, { useEffect, useState } from 'react'

// Redux
import { useSelector } from 'react-redux';

// React Bootstrap
import { Alert, Container } from 'react-bootstrap';

// APIs
import { API_get_teachers_by_course } from '../apis'

// ICONS
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';

// Components
import Skeleton from './Skeleton';
import TeacherCard from './TeacherCard';

// react-router-dom
import { useParams, useNavigate } from 'react-router-dom';

export default function OurTeachers() {

    const [teachers, setTeachers] = useState([]);
    const [courses, setCourses] = useState([]);

    const { id, isAdmin } = useSelector(state => state.user);
    const { current_course } = useSelector(state => state.courses);

    const course_id = useParams()['c_id'];

    const navigate = useNavigate();

    useEffect(() => {
        fnc_get_teachers();

    }, []);

    const fnc_get_teachers = async () => {
        const student_id = isAdmin ? 0 : id;
        await API_get_teachers_by_course(student_id, course_id)
            .then(res => {
                // console.log('ins res', res)
                setTeachers(res.instructors);
                setCourses(res.courses);
            }
            )
    }

    if (teachers.length === 0) {
        return <Alert variant='warning'>
            No Available instructors found at the time, please visit the page tomorrow.
        </Alert>
    }


    return (
        <Container className='p-3'>
            {
                course_id &&
                <>
                    <h2>
                        <small className='hand txt-tomato d-flex justify-content-between'>
                            <span onClick={() => { navigate(-1) }}><AiOutlineArrowLeft /> Back </span>
                            <span onClick={() => { navigate('/teachers') }}>All Teachers <AiOutlineArrowRight /></span>
                        </small>
                    </h2>
                    <h5 className='text-center py-4'>Instructors teaching {current_course.course_name}</h5>
                </>
            }
            {
                !course_id &&
                <h2 className='text-center my-3'>Our Instructors</h2>
            }
            <div>
                {
                    teachers.length === 0 ?
                        Array(3).map(i => <Skeleton key={i} />
                        ) : (
                            teachers.map((teacher, index) => {

                                return (
                                    <TeacherCard key={index} teacher={teacher} courses={courses} />
                                )
                            }
                            )
                        )
                }
            </div>
        </Container >
    )
}






