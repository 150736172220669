import { useEffect, useState } from 'react';

// Components
import Home from '../../Components/Admin/Settings/Home';
import General from '../../Components/Admin/Settings/General';
import Payment from '../../Components/Admin/Settings/Payment';

// APIs
import { API_get_all_settings } from '../../apis';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { ACTN_set_loading } from '../../Redux/SettingsReducer'

// react-bootstrap
import { Container, Tab, Tabs } from "react-bootstrap";

export default function Settings() {

    const [settings, setSettings] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        fnc_get_all_settings();
    }, []);

    const fnc_get_all_settings = async () => {


        await API_get_all_settings().then(sets => {

            setSettings(sets);
        });
    }

    return (
        <Container>
            <h1>Settings Page</h1>

            <Tabs defaultActiveKey='home'>
                <Tab eventKey='home' title='Home' className="fw-bolder" >
                    <Home current_settings={settings} />
                </Tab>
                <Tab eventKey='general' title='General' className="fw-bolder" >
                    <General current_settings={settings} />
                </Tab>
                <Tab eventKey='payment' title='Payment' className="fw-bolder" >
                    <Payment current_settings={settings} />
                </Tab>
            </Tabs>
        </Container>
    )
}
