import React, { useEffect } from 'react'

// Libraries
import Skeleton from '../Skeleton';
import Stars from '../Stars';

export default function Reviews({ reviews }) {

    useEffect(() => {
    }, [reviews]);


    if (!reviews || undefined === reviews) {
        return (
            <h2 className='alert alert-warning'>No Comments Yet</h2>
        )
    }

    return (

        <div className='p-3'>
            <h2>Comments</h2>
            <div>


                {
                    reviews.length === 0 ?
                        [...Array(2)].map((_, i) => <Skeleton key={i} />) :
                        reviews.map(({ review_content, stars }, i) => (
                            <div className='card mb-3' key={i} >
                                <div className='card-header'>
                                    <Stars stars={stars} />
                                </div>
                                <div className='card-body'>
                                    {review_content}
                                </div>
                            </div>
                        ))
                }
            </div>
        </div>
    )
}