// react-router-dom
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <footer className='bg-light py-4 d-md-flex justify-content-end align-items-center'>
      <div className='text-center mx-3'>
        <Link to='/terms-conditions'>Terms and Conditions</Link>
        <span className='mx-3'>|</span>
        <Link to='/privacy-policy'>Privacy Policy</Link>
      </div>

      <div className='d-flex flex-column align-items-center flex-md-row mx-3'>
        <span>All Rights Reserved &copy; {new Date().getFullYear()}</span>
        <span className='mx-3 d-none d-md-flex'>|</span>
        <span className='mx-1'>Powered by <a href='https://mwjb.net' target='_blank'>MWJB</a>
        </span>
      </div>
    </footer>
  )
}