import { useEffect, useState } from 'react'

// react-bootstrap
import { Button, ButtonGroup, Col, Container, Form, FormControl, FormFloating, FormGroup, FormSelect, InputGroup, ListGroup, ListGroupItem, Row } from 'react-bootstrap'

// APIs
import { API_get_active_child_categories, API_get_courses_by_category, API_update_skills, API_moderate_instructor_skill } from '../../apis';

// Icons
import { IoTrashBinSharp, IoCheckmark } from 'react-icons/io5';
import { ImPencil2 } from 'react-icons/im';

// Libraries
import { toast } from 'react-toastify';

// Redux
import { ACTN_set_loading } from '../../Redux/SettingsReducer';

// React Router Dom
import { useParams } from 'react-router-dom';

export default function TeacherSkills({ skillsData, pendingSkillsData }) {

    const [skills, setSkills] = useState([]);
    const [pendingSkills, setPendingSkills] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [courses, setCourses] = useState([]);
    const [selectedCourseId, setSelectedCourseId] = useState('');
    const [selectedCourseName, setSelectedCourseName] = useState('');
    const [privateRate, setPrivateRate] = useState('');
    const [publicRate, setPublicRate] = useState('');

    const { id } = useParams();

    useEffect(() => {
        setSkills(skillsData);
        setPendingSkills(pendingSkillsData);

        fnc_get_active_childcategories();
    }, [skillsData]);

    const fnc_get_active_childcategories = async () => {


        await API_get_active_child_categories().then(data => {


            setCategories(data);
        })
    }

    const fnc_get_courses = async $cat_id => {


        await API_get_courses_by_category($cat_id).then(data => {

            setCourses(data);
        })
    }

    const handle_category_changed = e => {
        setSelectedCategory(e.target.value);
        fnc_get_courses(e.target.value);
    }

    const handle_course_changed = e => {
        const { course_id, course_name } = courses.find(course => course.course_id === e.target.value);
        setSelectedCourseId(course_id);
        setSelectedCourseName(course_name);
    }

    const handle_add_skill = async () => {

        const skill = {
            course_id: selectedCourseId,
            course_name: selectedCourseName,
            private_rate: privateRate,
            public_rate: publicRate
        }

        const filtered_skills = skills.filter(skill => skill.course_id != selectedCourseId)

        const new_skills = [...filtered_skills, skill];

        setSkills(new_skills);

        handle_save_skills(new_skills);

        toast.success('Skill added to the list.');
        toast.warning('Do not forget to Save Skills');
    }

    const handle_change_skill_private_rate = (course_id, rate) => {

        // console.log(skills)
        const skill_to_update = skills.find(skill => skill.course_id == course_id);

        skill_to_update.private_rate = rate;

        setSkills([...skills]);
    }

    const handle_change_skill_public_rate = (course_id, rate) => {

        const skill_to_update = skills.find(skill => skill.course_id == course_id);

        skill_to_update.public_rate = rate;

        setSkills([...skills]);
    }

    const handle_remove_skill = (course_id) => {

        setSkills(skills.filter(skill => {
            return skill.course_id != course_id
        }));
    }

    const handle_save_skills = async () => {


        const ready_skills = [];

        skills.forEach(skl => ready_skills.push({ [skl.course_id]: [skl.private_rate, skl.public_rate] }))

        const json_skills = JSON.stringify(ready_skills);

        await API_update_skills(id, json_skills).then(res => {
            if (res) {
                toast.success('Updated Successfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }

    const handle_moderate_skill = async skill_id => {
        await API_moderate_instructor_skill(skill_id)
            .then(res => {
                if (res) {
                    const skls = pendingSkills;
                    const filtered = skls.filter(skill => skill.skill_id != skill_id)
                    setPendingSkills(filtered)

                }
                // console.log('API_moderate_instructor_skill -> res::', res)
            })
    }

    const categories_options = categories.map(({ category_id, category_name }, index) => {
        return <option key={index} value={category_id}>{category_name}</option>
    });

    return (
        <Container className='py-3'>
            <Row>
                <Col md={8}>
                    <Form className='border p-3 bg-light'>

                        <h2>Add New Skill</h2>

                        <FormFloating className='my-3'>
                            <FormSelect value={selectedCategory} onChange={handle_category_changed}>
                                <option>Select Course Category</option>
                                {categories_options}
                            </FormSelect>
                            <label>Category</label>
                        </FormFloating>

                        <FormFloating className='my-3'>
                            <FormSelect value={selectedCourseId} onChange={handle_course_changed}>
                                <option>Select Course</option>
                                {
                                    courses.length > 0 && courses.map(({ course_id, course_name, category_parent }, index) => {
                                        return <option key={index} value={course_id}>{course_name}</option>
                                    }
                                    )
                                }
                            </FormSelect>
                            <label>Course</label>
                        </FormFloating>

                        <FormFloating className='my-3'>
                            <FormControl type="number" min='10' label="Private Rate" placeholder=' ' value={privateRate} onChange={e => setPrivateRate(e.target.value)} />
                            <label>Private Rate/Hour</label>
                        </FormFloating>

                        <FormFloating className='my-3'>
                            <FormControl type="number" min='10' label="Public Rate" placeholder=' ' value={publicRate} onChange={e => setPublicRate(e.target.value)} />
                            <label>Public Rate/Hour</label>
                        </FormFloating>

                        <ButtonGroup className='my-3'>
                            <Button variant="primary" onClick={handle_add_skill}>Add</Button>
                        </ButtonGroup>

                    </Form>
                </Col>
                <Col md={4}>
                    {
                        pendingSkills.length > 0 &&
                        <div className='border p-3 bg-light'>
                            <h2>Pending Skills</h2>
                            <div className='d-flex flex-column'>
                                <ListGroupItem className='d-flex flex-row justify-content-between align-items-center my-2 bg-primary'>
                                    <div className='d-flex flex-grow-1 justify-content-between pe-5'>
                                        <div>Course and Rate</div>
                                    </div>
                                </ListGroupItem>
                                {
                                    pendingSkills.map(({ skill_id, skill, rate }) => {
                                        return <ListGroup key={skill_id} className=''>
                                            <ListGroupItem className='d-flex flex-row justify-content-between align-items-center my-2'>
                                                <div className='d-flex flex-grow-1 flex-column flex-md-row justify-content-between align-items-start align-items-md-center pe-5'>
                                                    <div>{skill} <br /> {rate}</div>
                                                </div>
                                                <button className='btn btn-info' onClick={() => handle_moderate_skill(skill_id)} >
                                                    <IoCheckmark className='text-dark pointer' />
                                                </button>
                                            </ListGroupItem>
                                        </ListGroup>
                                    })
                                }

                            </div>

                            <Button variant='success' onClick={handle_save_skills}>Save Skils</Button>

                        </div>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        skills.length > 0 &&
                        <div className='border p-3 bg-light mt-3'>
                            <h2>Skills</h2>
                            <div className='d-flex flex-column'>
                                <ListGroupItem className='d-flex flex-row justify-content-between align-items-center my-2 bg-primary text-light'>
                                    Courses and Rates
                                </ListGroupItem>

                                <div className=''>
                                    {
                                        skills.map(({ course_id, course_name, private_rate, public_rate }, index) =>
                                            <div key={index} className='rounded p-3 skill-card'>
                                                <div className='p-1 rounded d-flex flex-row justify-content-between align-items-center '>
                                                    <span>{course_name}</span>
                                                    <span className='p-2 text-danger'>
                                                        <IoTrashBinSharp className='pointer' onClick={() => handle_remove_skill(course_id)} />
                                                    </span>
                                                </div>

                                                <Row className='py-3'>
                                                    <Col md={6}>
                                                        <InputGroup className='mb-2'>
                                                            <InputGroup.Text className='w-50 d-flex justify-content-between'>
                                                                <span>Private Rate</span>
                                                                <ImPencil2 className='text-warning' />
                                                            </InputGroup.Text>

                                                            <FormControl type='number' className='' value={private_rate} onChange={e => handle_change_skill_private_rate(course_id, e.target.value)} />
                                                        </InputGroup>
                                                    </Col>

                                                    <Col md={6}>
                                                        <InputGroup>
                                                            <InputGroup.Text className='w-50 d-flex justify-content-between'>
                                                                <span>Public Rate</span>
                                                                <ImPencil2 className='text-warning' />
                                                            </InputGroup.Text>

                                                            <FormControl type='number' className='' value={public_rate} onChange={e => handle_change_skill_public_rate(course_id, e.target.value)} />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    }
                                </div>

                            </div>

                            <Button variant='success' onClick={handle_save_skills}>Save Skils</Button>

                        </div>
                    }
                </Col>
            </Row>



        </Container>
    )
}
