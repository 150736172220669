import React, { useEffect, useState } from 'react'
import { Alert, Button, Container, Image, ListGroup, ListGroupItem } from 'react-bootstrap';

// images
import female from '../../assets/imgs/female.png'
import male from '../../assets/imgs/male.png'

// APIs
import { API_get_teachers_list } from '../../apis';

// React Router Dom
import { useNavigate } from 'react-router-dom';

export default function Teachers() {

    const [teachers, setTeachers] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        fnc_get_teachers();
    }, []);

    const fnc_get_teachers = async () => {
        const teachers = await API_get_teachers_list();
        setTeachers(teachers);
    }


    return (
        <Container className='my-5'>

            <h1 className='d-flex flex-row justify-content-between'>
                <span>Instructors</span>
                <Button variant='outline-danger' value='Add Instructors' onClick={() => navigate('/admin/teachers/add')}>Add Instructor</Button>
            </h1>


            {
                teachers.length === 0 ?
                    <Alert variant='warning' className='my-4'>No Instructors Found</Alert>
                    :
                    teachers.map(({ first_name, member_id, last_name, middle_name, nationality, gender, approved }) => (
                        <ListGroup key={member_id} className='hand'>
                            <ListGroupItem className='d-flex flex-row justify-content-between align-items-center mb-3 shadow-sm rounded-3' onClick={() => navigate(`/admin/teacher/${member_id}`)}>
                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                    <img src={gender == 'Female' ? female : male}
                                        className='rounded-circle' width={46} alt='teacher' />
                                    <div className='d-flex flex-column justify-content-between'>
                                        <strong className='ms-3'>{first_name} {middle_name} {last_name}</strong>
                                        {
                                            approved == 0 &&
                                            <small className='txt-tomato'>Pending Approve</small>
                                        }
                                    </div>
                                </div>
                                <small className='ms-3'>{nationality}</small>
                            </ListGroupItem>
                        </ListGroup>
                    ))
            }
        </Container >
    )
}
