import { useEffect, useState } from 'react';

// easy-tailwind
import { e } from 'easy-tailwind';

// Imgs
import logo from '../assets/imgs/logo.jpg';

// react-router-dom
import { Link } from 'react-router-dom';

// Icons
import { AiFillMessage } from 'react-icons/ai';
import { FiMenu } from 'react-icons/fi';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ACTN_show_login, ACTN_show_register } from '../Redux/SettingsReducer';
import { ACTN_logged_out } from '../Redux/UserReducer';

export default function MainHeader() {

	return (
		<div className="flex flex-col sticky top-0 z-50 bg-white">
			{/* Logo and Title and Muenu Button */}
			<div className="flex justify-between">
				{/* Logo and Title */}
				<div className="flex items-center gap-2">
					<img src={logo} alt='logo' width="256px" />
				</div>
			</div>


		</div>
	);
}
