import { useEffect, useState } from 'react';

import { e } from 'easy-tailwind';

// Redux
import {
	ACTN_hide_login,
	ACTN_show_register,
	ACTN_show_forget_pass,
} from '../../Redux/SettingsReducer';
import { ACTN_logged_in } from '../../Redux/UserReducer';
import { useDispatch } from 'react-redux';

// react-router-dom
import { useNavigate, useLocation } from 'react-router-dom';

// API
import { API_login_user, API_get_user_messages_count } from '../../apis';

export default function LoginForm() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [remember, setRemember] = useState(false);
	const [error, setError] = useState('');

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const { pathname } = useLocation();

	// console.log("pathname.indexOf(' / teacher')", pathname.indexOf('/teacher'))

	useEffect(() => {}, []);

	const handle_remember = () => {
		setRemember(!remember);
	};

	const handle_user_login = async () => {
		// Validate Email
		const email_regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');

		if (!email_regex.test(email.toLowerCase())) {
			setError('Invalid Email');
			return;
		}

		const data = {
			email: email,
			password: password,
		};

		await API_login_user(data).then(async (res) => {
			if (undefined !== res.loggedin || res.loggedin === false) {
				setError(res.msg);
				return;
			}

			const user_data = { ...res, user_email: email, remember };

			// get messages

			const user_id = res.user_id;

			dispatch(ACTN_logged_in({ user_data, type: '' }));

			await API_get_user_messages_count(user_id).then(async (msgs) => {
				// console.log('msgs', msgs);
			});

			// let is_admin = false;
			// let is_teacher = false;
			// let is_pending_teacher = false;

			if (pathname.indexOf('/teacher') == -1) {
				let redirect_to = '';

				switch (user_data.user_role) {
					case 'admin':
						// is_admin = true;
						redirect_to = '/admin';
						break;
					case 'instructor':
						// is_teacher = true;
						redirect_to = '/account/sessions';
						break;
					// case 'pending_instructor':
					// is_pending_teacher = true;
					// redirect_to = '/account';
					// break;
					default:
						redirect_to = '/account';
				}
				window.location.href = redirect_to;
			}

			dispatch(ACTN_hide_login());
		});
	};

	return (
		<form action="" method="post" className="was-validated login_form shadow">
			<div className="border-[6px] border-sky-900 border-opacity-40 shadow p-2 rounded w-full max-w-md">
				<div className="p-4 flex flex-col bg-gray-50 gap-3  ">
					<span className="text-center text-dark text-2xl">
						Sign in to your account
					</span>

					<div className="flex flex-col gap-1">
						<label htmlFor="email">Email</label>
						<input
							type="email"
							id="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							className="form-control"
							required
						/>
					</div>

					<div className="flex flex-col gap-1">
						<label htmlFor="paasswrd">Password</label>
						<input
							type="password"
							id="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							className="form-control"
							autoComplete="false"
							required
						/>
					</div>

					<div className="flex justify-between items-center">
						<label className="flex gap-2">
							<input
								type="checkbox"
								className="form-check-input"
								onChange={handle_remember}
							/>
							<span>💾 Remember me </span>
						</label>

						<small
							className={e('hand', {
								hover: 'text-deep-orange-600',
							})}
							onClick={() => dispatch(ACTN_show_forget_pass())}
						>
							Forget Password?
						</small>
					</div>

					<div
						className={e(
							'hand flex items-center justify-center text-center bg-sky-600 text-sky-200 flex-grow p-2 rounded',
							{ hover: 'bg-sky-900 text-white' }
						)}
						onClick={handle_user_login}
					>
						Login
					</div>

					<div className="flex flex-col">
						<span className="text-danger">{error}</span>
					</div>

					<div
						className={e('text-center px-3 text-sky-900 py-1 rounded hand', {
							hover: 'text-deep-orange-800',
						})}
						onClick={() => dispatch(ACTN_show_register())}
					>
						Don't have account, create one
					</div>

					<span
						className={e('self-end px-3 text-red-900 py-1 rounded hand', {
							hover: 'text-white bg-red-800',
						})}
						onClick={() => dispatch(ACTN_hide_login())}
					>
						Close
					</span>
				</div>
			</div>
		</form>
	);
}
