import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import store from './Redux/store';

import { ThemeProvider } from '@material-tailwind/react';

// Router Dom
import { BrowserRouter } from 'react-router-dom';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<ThemeProvider>
				<App />
			</ThemeProvider>
		</BrowserRouter>
	</Provider>
);
