import { useEffect, useState } from 'react'

// Libraries
import { Form, Button, Col, Container, FormControl, FormFloating, FormSelect, Modal, Row, Table, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { ACTN_update_categories, ACTN_delete_category } from '../../Redux/CoursesReducer';
import { ACTN_set_loading } from '../../Redux/SettingsReducer';

// APIs
import { API_update_category, API_delete_category } from '../../apis';

export default function TableCategories({ categoriesURL, default_img }) {

    const { categories } = useSelector(state => state.courses);

    const [parentCategories, setParentCategories] = useState([]);

    const [categoryName, setCategoryName] = useState('');

    const [categoryParent, setCategoryParent] = useState(0);

    const [categoryParentName, setCategoryParentName] = useState('');

    const [categoryDescription, setCategoryDescription] = useState('');

    const [categoryChildsCount, setCategoryChildsCount] = useState(0);

    const [categoryCoursesCount, setCategoryCoursesCount] = useState(0);

    const [showModal, setShowModal] = useState(false);

    const [activeCategory, setActiveCategory] = useState(null);


    useEffect(() => {
        setParentCategories(categories.filter(category => category.category_parent == 0))
    }, [categories]);

    const dispatch = useDispatch();

    const handle_update_category = category => {
        setActiveCategory(category);
        setCategoryName(category.category_name);
        setCategoryParent(category.category_parent);
        setCategoryParentName(category.parent_name);
        setCategoryDescription(category.category_description);
        setCategoryChildsCount(category.childs_count || 0);
        setCategoryCoursesCount(category.courses_count || 0);
        setShowModal(true);
    }

    const handle_save_changes = async () => {
        if (categoryName == '') {
            toast.error('Category name cannot be empty')
            return;
        }
        dispatch(ACTN_set_loading(true));
        setShowModal(false);

        const new_category = {
            category_id: activeCategory.category_id,
            category_name: categoryName,
            category_parent: categoryParent,
            category_description: categoryDescription,
            parent_name: categoryParentName,
        }

        await API_update_category(new_category).then(res => {

            dispatch(ACTN_set_loading(false));
            if (res) {
                dispatch(ACTN_update_categories(new_category));
            }
        });
    }

    const handle_category_parent_changed = e => {
        setCategoryParent(e.target.value);
        setCategoryParentName(e.target.options[e.target.selectedIndex].text)
    }

    const handle_delete_category = async () => {
        dispatch(ACTN_set_loading(true));
        // console.log('activeCategory', activeCategory);

        await API_delete_category(activeCategory.category_id).then(async res => {
            dispatch(ACTN_set_loading(false));

            if (res > 0) {
                // Remove Category from Category list
                dispatch(ACTN_delete_category(activeCategory.category_id))
            }

            setShowModal(false);

            // console.log('Delete res', res);
        })

    }

    return (
        <Container className='bg-cool_gray  p-3 shadow rounded-3'>
            {/* Update Category Modal */}
            <Modal show={showModal ? 'show' : ''} centered onEscapeKeyDown={() => setShowModal(false)}>

                <Modal.Header>
                    <Modal.Title>Update Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form className='was-validated'>
                        <FormFloating className='my-3'>
                            <FormControl required type="text" placeholder="Category Name" value={categoryName} onChange={e => setCategoryName(e.target.value)} />
                            <label>Name</label>
                        </FormFloating>

                        <FormFloating className='my-3'>
                            {categoryParent > 0 &&
                                <FormSelect placeholder="Category Name" value={categoryParent} onChange={handle_category_parent_changed}>
                                    {
                                        parentCategories.map(category => {
                                            return <option key={category.category_id} value={category.category_id}>{category.category_name}</option>
                                        })
                                    }
                                </FormSelect>}
                            <label>Parent Category</label>
                        </FormFloating>

                        <FormFloating className='my-3'>
                            <FormControl as="textarea" placeholder="Category Description" value={categoryDescription} onChange={e => setCategoryDescription(e.target.value)} />
                            <label>Description</label>
                        </FormFloating>
                    </Form>

                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-between'>
                    <div>
                        {categoryChildsCount == 0 && categoryCoursesCount == 0 &&
                            <Button variant="danger" onClick={handle_delete_category}>Delete</Button>
                        }
                    </div>
                    <div>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                        <Button variant="primary" onClick={handle_save_changes}>Save changes</Button>
                    </div>
                </Modal.Footer>

            </Modal>

            <Container className='bg-white p-4 mt-3 shadow-sm rounded-3'>
                <h2>Categories</h2>
            </Container>

            {
                categories.length == 0 ?
                    <Alert className='my-4'>Add your first category</Alert>
                    :
                    <Container className='bg-white p-2 mt-3 shadow-sm rounded-3'>
                        {categories.map((category, index) => {
                            return (
                                <div key={index} className={`p-3 mb-30 ${index % 2 == 0 ? 'bg-light_gray' : 'bg-white'} borders rounded-3`} onClick={() => handle_update_category(category)}>
                                    <Row>
                                        <Col md='3'>{category.category_name}</Col>
                                        <Col md='9'>{category.parent_name}</Col>
                                    </Row>
                                    <Row>
                                        <Col md='3'>
                                            <img
                                                src={`${categoriesURL}${category.category_id}.png`}
                                                className='d-block mw-100 img-thumbnail'
                                                onError={
                                                    ({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = `${default_img}`;
                                                    }} />
                                        </Col>
                                        <Col md='9'>
                                            <small className='text-secondary'>{category.category_description || '...'}</small>
                                        </Col>
                                    </Row>
                                    <Row className='txt-maroon fw-bold'>
                                        <Col md='3'>
                                            <small>Courses ({category.courses_count || 0})</small>
                                        </Col>
                                        {
                                            category.category_parent == 0 &&
                                            <Col md='9'>
                                                <small>Sub Categories ({category.childs_count || 0})</small>
                                            </Col>
                                        }
                                    </Row>
                                </div>
                            )
                        })}
                    </Container>
            }
        </Container>
    )
}
