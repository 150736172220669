import { Container } from 'react-bootstrap'

// variables
import { categoriesURL, defaultCategoryImg } from '../Helpers/consts';

export default function CategoriesList({ categories, handle_category_changed }) {

    const handle_img_error = ({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = defaultCategoryImg
    }

    return (
        <Container fluid>
            <div className='categories_box'>
                {
                    categories.map(({ category_id, category_name, category_parent, category_description }) =>
                        <div className='category_box' key={category_id} onClick={() => handle_category_changed(category_id, category_name)}>
                            <h3>{category_name}</h3>
                            <div>
                                <img src={`${categoriesURL}${category_id}.png`} onError={handle_img_error} />
                                {category_description}
                            </div>
                        </div>
                    )
                }
            </div>
        </Container>

    )
}
