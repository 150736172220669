const fetch_fawry = async (url, method, row_body) => {
    let request_url;

    const init = {
        method: method,
        headers: {
            'Content-Type': 'application/json'
        },
    }

    if (method == 'GET' || method == 'DELETE') {
        request_url = `${url}?`;

        Object.keys(row_body).forEach(key => request_url += `${key}=${row_body[key]}&`);
    } else {
        init.body = JSON.stringify(row_body);
        request_url = url;
    }

    // console.log('method', method)
    // console.log('request_url', request_url)

    return await fetch(request_url, init)
        .then(res => res.json())
        .then(data => { return data })
}

export const FAWRY_API_list_all_cards = async (signature, merchantCode, customerProfileId) => {
    const url = 'https://atfawry.fawrystaging.com/ECommerceWeb/Fawry/cards/cardToken';
    const method = 'GET';
    const row_body = {
        signature, merchantCode, customerProfileId
    }

    return await fetch_fawry(url, method, row_body);
}

export const FAWRY_API_delete_member_payment_card = async (signature, merchantCode, customerProfileId, cardToken) => {
    const url = 'https://atfawry.fawrystaging.com/ECommerceWeb/Fawry/cards/cardToken';
    const method = 'DELETE';
    const row_body = {
        signature, merchantCode, customerProfileId, cardToken
    }
    return await fetch_fawry(url, method, row_body);
}