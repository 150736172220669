import { useState, useEffect } from 'react';

// react-bootstrap
import { Alert, Button, InputGroup, Col, Container, Form, FormCheck, FormControl, FormGroup, FormLabel, Row, FormFloating } from "react-bootstrap";

// APIs
import { API_save_settings } from '../../../apis'

// Redux
import { useDispatch } from 'react-redux';
import { ACTN_set_loading, ACTN_set_app_settings } from '../../../Redux/SettingsReducer';

// react-toastify
import { toast } from 'react-toastify';

export default function General({ current_settings }) {

    const [settings, setSettings] = useState({})

    const dispatch = useDispatch();

    useEffect(() => {
        setSettings(current_settings);
    }, [current_settings])

    const handle_settings_changed = e => {
        const k = e.target.id;
        // console.log(k)
        let v;

        if (e.target.type == 'checkbox') {
            v = e.target.checked;
        } else {
            v = e.target.value;
        }

        // console.log(e.target.checked)
        setSettings({ ...settings, [k]: v })
    }

    const handle_save_setting = async () => {
        // console.log('settings', settings)
        dispatch(ACTN_set_loading(true));

        await API_save_settings(settings).then(res => {
            dispatch(ACTN_set_loading(false));

            if (res) {
                dispatch(ACTN_set_app_settings(settings));
                toast.success('Settings Updated')
            }

        });
    }

    if (!current_settings || Object.keys(current_settings).length == 0) {
        return
    }

    return (
        <Container className='py-3'>

            <FormGroup>
                <FormLabel>App Name</FormLabel>
                <FormControl types='text' id='app_name' value={settings.app_name} onChange={handle_settings_changed} required />
            </FormGroup>

            <FormGroup>
                <FormLabel>Greeting Word</FormLabel>
                <FormControl types='text' id='greeting_word' placeholder='HOWDY' value={settings.greeting_word} onChange={handle_settings_changed} required />
            </FormGroup>


            <Button className='my-3' onClick={handle_save_setting}>Save Settigns</Button>
        </Container>
    )
}
