import { createSlice } from "@reduxjs/toolkit";

const name = 'courses';
const initialState = {
    categories: [],
    courses: [],
    current_course: {}
}

export const CoursesReducer = createSlice({
    name,
    initialState,
    reducers: {
        ACTN_set_categories: (state, action) => {
            state.categories = action.payload;
        },
        ACTN_set_courses: (state, action) => {
            state.courses = action.payload;
        },
        ACTN_add_category: (state, action) => {
            state.categories.push(action.payload);
        },
        ACTN_add_course: (state, action) => {
            state.courses.push(action.payload);
        },
        ACTN_update_categories: (state, action) => {
            // console.log('action.payload', action.payload);
            const current = state.categories;
            current.forEach((item, index) => {
                if (item.category_id === action.payload.category_id) {
                    current[index] = action.payload;
                }
            });
            state.categories = current;
        },
        ACTN_delete_category: (state, action) => {
            state.categories = state.categories.filter(cat => cat.category_id !== action.payload)
        },
        ACTN_update_courses: (state, action) => {
            const current = state.courses;
            current.forEach((item, index) => {
                if (item.course_id === action.payload.course_id) {
                    current[index] = action.payload;
                }
            });
            state.courses = current;
        },
        ACTN_set_current_course: (state, action) => {
            state.current_course = action.payload
        }
    }
});

export default CoursesReducer.reducer;
export const {
    ACTN_set_categories,
    ACTN_set_courses,
    ACTN_add_category,
    ACTN_add_course,
    ACTN_update_categories,
    ACTN_delete_category,
    ACTN_update_courses,
    ACTN_set_current_course
} = CoursesReducer.actions;
