import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Routes
import Home from './Home';
import Teachers from './Teachers';
import Teacher from './Teacher';
import AddTeacher from './AddTeacher';
import Attendance from './Attendance';
import Categories from './Categories';
import Courses from './Courses';
import Booking from './Booking';
import OurTeachers from '../Teachers';

//react-bootstrap
import { Alert } from 'react-bootstrap';

//Redux
import { useSelector } from 'react-redux';
import LoginRequest from '../../Components/User/LoginRequest';
import Settings from './Settings';

export default function Index() {

    const { role, loggedIn } = useSelector(state => state.user);

    if (!loggedIn) {
        return (
            <LoginRequest msg="Please login First" />
        )
    }

    if (role != 'admin') {
        return (
            <Alert variant='danger'>
                <h2>Please Contact Our Admins</h2>
            </Alert>
        )
    }

    return (
        <Routes>
            <Route path="/" element={<Home />} >
                <Route path="/attendance" element={<Attendance />} />
                <Route path="/categories" element={<Categories />} />
                <Route path="/courses" element={<Courses />} />
                <Route path="/booking/:booking_id" element={<Booking />} />
                <Route path="/teachers" element={<Teachers />} />
                <Route path="/teachers/:add" element={<AddTeacher />} />
                <Route path="/teacher/:id" element={<Teacher />} />
                <Route path="our-teachers" element={<OurTeachers />} />
                <Route path="settings" element={<Settings />} />
                <Route path="/*" element={<Home />} />
            </Route>
        </Routes>
    )
}
