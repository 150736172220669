import React, { useEffect, useState } from 'react'

// Components
import BookingList from './BookingList'

// APIs
import { API_list_all_bookings } from '../../../apis';

// React Bootstrap
import { Container, Tab, Tabs, Alert } from 'react-bootstrap';

export default function Bookings() {

    const [statuses, setStatuses] = useState([]);
    const [bookings, setBookings] = useState([]);

    useEffect(() => {
        fnc_list_all_bookings();
    }, [])

    const filter_bookings = (bookings, st) => bookings.filter(b => b.status_name == st)

    const fnc_list_all_bookings = async () => {
        await API_list_all_bookings().then(data => {

            let stats = [];

            data.forEach(b => { stats.push(b.status_name) })

            stats = Array.from(new Set(stats));

            setStatuses(stats);

            setBookings(data);

        })
    }


    if (bookings.length == 0) {
        return (
            <Alert variant='warning'>
                <h3>No Bookings Yet!</h3>
            </Alert>
        )

    }

    return (
        <Container>
            <h2>Bookings</h2>
            <Tabs>
                {
                    statuses.map((st, i) => {
                        const all = filter_bookings(bookings, st);

                        return <Tab eventKey={st} title={st} key={i}>
                            <BookingList bookings={all} fnc_list_all_bookings={fnc_list_all_bookings} />
                        </Tab>
                    })
                }
            </Tabs>
        </Container>
    )
}
