import { useState } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ACTN_set_loading } from '../Redux/SettingsReducer'
import { ACTN_update_user_keys } from '../Redux/UserReducer'

// API
import { API_join_as_teacher_request } from '../apis';

// react-router-dom
import { useNavigate } from 'react-router-dom';

// Consts
import { url_regex } from '../Helpers/consts';

// react-bootstrap
import { Alert, Col, Container, ListGroup, ListGroupItem, Row } from 'react-bootstrap';

// images
import banner from '../assets/imgs/bg-01-dark.jpg';

export default function JoinAsTeacher() {

    const [bio, setBio] = useState('');
    const [bioValid, setBioValid] = useState(false);
    const [bioLength, setBioLength] = useState(0);


    const [error, setError] = useState('');

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const student_id = useSelector(state => state.user.id)

    const handle_bio_change = e => {
        const val = e.target.value;
        const val_len = val.length;

        setBioLength(val_len)
        setBio(val);

        setBioValid(val_len >= 100 && val_len <= 500)

    }

    const send_request = async () => {

        setError('');

        dispatch(ACTN_set_loading(true));

        await API_join_as_teacher_request({ bio, student_id }).then(res => {
            dispatch(ACTN_set_loading(false));

            if (res) {
                dispatch(ACTN_update_user_keys(
                    {
                        'isPendingTeacher': true,
                        'user_role': 'pending instructor'
                    }
                ))
                navigate('/account');
            } else {
                setError(res);
            }

        })
    }

    return (

        <Container fluid style={{ overflow: 'auto', }} >

            <div className='banner_plus_title'>
                <img src={banner} className='w-100' />
                <h1 className='text-light'>Join as Teacher</h1>
            </div>

            <div className='my-3'>
                <Row>
                    <Col lg='8'>
                        <Alert variant='secondary'>
                            <h4>Instructions</h4>
                        </Alert>
                        <p>Communicative language teaching is at the forefront of our teaching philosophy. Our student-focused 1:1 classes are designed to get our learners talking using materials that transport our students into authentic language production situations guided by our team of experienced instructors. Our instructors are on hand to give real-time individualized feedback and advice and implement personalized learning strategies aiding improvement and success. Our swift ascent is a result of our unparalleled language management technology, hard work, and high standards, our diversity, our understanding of our learner's needs, and our in-depth knowledge of English Language production and nuance.</p>
                        <h3>Instructions</h3>
                        <ListGroup>
                            <ListGroupItem>Proin pulvinar dui vitae tincidunt malesuada.</ListGroupItem>
                            <ListGroupItem>Proin at orci porttitor, ultrices felis rhoncus, facilisis massa.</ListGroupItem>
                            <ListGroupItem>Vestibulum in enim a diam ornare congue at a libero.</ListGroupItem>
                            <ListGroupItem>Nulla eu eros a nunc feugiat luctus.</ListGroupItem>
                            <ListGroupItem>Cras tincidunt turpis eget egestas pulvinar.</ListGroupItem>
                            <ListGroupItem>Nullam auctor mauris vitae elit feugiat viverra.</ListGroupItem>
                            <ListGroupItem>Cras vitae justo vitae eros molestie lobortis quis eget tellus.</ListGroupItem>
                            <ListGroupItem>Etiam id neque a ligula efficitur convallis.</ListGroupItem>
                        </ListGroup>
                    </Col>

                    <Col lg='4'>
                        <Alert variant='success'>
                            <h4>Request Form</h4>
                        </Alert>
                        <div className="form-group m-3">
                            <label htmlFor="bio">Let us know more about you <small className={`text-${bioLength < 100 || bioLength > 500 ? 'danger' : 'success'}`}>100 - 500 Letters ({500 - bioLength})</small></label>
                            <textarea id="bio" className={`form-control is-${bioValid ? 'valid' : 'invalid'}`} onChange={handle_bio_change} required minLength={100}>{bio}</textarea>
                        </div>

                        <div className="form-group m-3 text-danger">{error}</div>

                        <div className="btn-group m-3 d-flex justify-content-between">
                            <input type="button" className="btn btn-success" value='Send Request' onClick={send_request} />
                        </div>
                    </Col>
                </Row>




            </div>
        </Container>
    )
}
