import { useEffect, useState } from 'react'

// APIs
import { API_get_all_courses, API_get_child_categories } from '../../apis';

// Libraries
import { Alert, Container } from 'react-bootstrap';

// Components
import TableCourses from '../../Components/Admin/TableCourses';
import AddCourse from '../../Components/Admin/AddCourse';

// React Router DOM
import { Link } from 'react-router-dom'

// Redux
import { useDispatch } from 'react-redux';
import { ACTN_set_courses } from '../../Redux/CoursesReducer';

export default function Courses() {

    const [childCategories, setChildCategories] = useState([]);
    const [ready, setReady] = useState(false);
    const [courses, setCourses] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        prepare_courses();
    }, []);

    const prepare_courses = async () => {
        await API_get_child_categories()
            .then(async res => {
                setChildCategories(res)
                await API_get_all_courses()
                    .then(res => {
                        setReady(true);
                        setCourses(res);
                        dispatch(ACTN_set_courses(res));
                    })
            })
    }

    if (childCategories.length == 0 && ready) {
        return <Alert>Add Sub Categories First <Link to='/admin/categories'>Here</Link></Alert>
    }

    return (
        <Container>
            <AddCourse childCategories={childCategories} courses={courses} />

            <TableCourses childCategories={childCategories} />
        </Container>
    )
}
