import { useState, useRef } from 'react'

// React Bootstrap
import { Button, ButtonGroup, Container, Form, FormControl, FormFloating, FormLabel, ToggleButton } from 'react-bootstrap';

// React Router DOM
import { useNavigate } from 'react-router-dom';

// APIs
import { API_add_teacher } from '../../apis';

// Consts
import { genders } from '../../Helpers/consts'

// redux
import { useDispatch } from 'react-redux';
import { ACTN_set_loading } from '../../Redux/SettingsReducer';

export default function AddTeacherForm() {

    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [nationality, setNationality] = useState('');
    const [nidpassport, setNidpassport] = useState('');
    const [loginName, setLoginName] = useState('');
    const [gender, setGender] = useState('');
    const [bio, setBio] = useState('');
    const [promoVideo, setPromoVideo] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [patternOk, setPatternOk] = useState(false);
    const [passMatch, setPassMatch] = useState(false);

    const [addMessage, setAddMessage] = useState('');
    const [added, setAdded] = useState(false);

    const navigate = useNavigate();

    const form_ref = useRef();

    const handle_add_new_teacher = async e => {
        e.preventDefault();

        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.stopPropagation();
            form.classList.add('was-validated')
            return;
        }

        const teacher = {
            fName: firstName,
            mName: middleName,
            lName: lastName,
            email: email,
            mobile: mobile,
            nationality: nationality,
            nidpassport: nidpassport,
            gender: gender,
            username: loginName,
            bio: bio,
            promoVideo: promoVideo,
            password: password,
        }
        // console.log(teacher); return;

        await API_add_teacher(teacher)
            .then(response => {
                if (typeof (response) == 'string') {
                    setAddMessage(<div className='alert alert-danger'>
                        <h3>{response}</h3>
                    </div>)
                } else {
                    setAdded(true)
                    setAddMessage(<div className='alert alert-success'>
                        <h3>Instructor Added Successfully</h3>
                        <div>
                            <button className='alert-link p-3 m-3 btn btn-outline-success' onClick={() => handle_add_skills(response)}>Add Skills and Availability</button>
                            <button className='alert-link p-3 m-3 btn btn-outline-success' onClick={handle_new_teacher}>Add a New Instructor</button>
                        </div>
                    </div>)
                }
            })



    };

    const handle_new_teacher = () => {
        setFirstName('');
        setMiddleName('');
        setLastName('');
        setEmail('');
        setMobile('');
        setNationality('');
        setNidpassport('');
        setLoginName('');
        setGender('');
        setBio('');
        setPromoVideo('');
        setPassword('');
        setConfirmPassword('');
        setPassMatch(false);
        setAddMessage('');
        setAdded(false)

        form_ref.current.classList.remove('was-validated')
    }

    const handle_add_skills = ({ user_id }) => {
        navigate(`/admin/teacher/${user_id}`);
    }

    const handle_password_changed = e => {
        // const pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!@#$%^&*()-_=+'";:/?.>,<\\|]).{6,}/g;
        // const regex = new RegExp(pattern);
        // const pass = e.target.value;
        // const match = regex.test(pass);
        // console.log(match);
        // setPatternOk(match);

        // setPassword(pass);
        // setPassMatch(false);
        // pass === confirmPassword ? setPassMatch(true) : setPassMatch(false);

        // Password with no validation
        const pass = e.target.value;
        setPassword(pass);
        setPassMatch(false);
        setPatternOk(true);
    }

    const handle_confirm_password_changed = e => {
        const confPassword = e.target.value;
        setConfirmPassword(confPassword);
        setPassMatch('');
        password === confPassword ? setPassMatch(true) : setPassMatch(false);
    }

    return (
        <Form noValidate onSubmit={handle_add_new_teacher} method='post' ref={form_ref} >

            <Container className='bg-white p-4 mt-3 shadow-sm rounded-3'>
                <h3>Add New Instructor Account</h3>
            </Container>


            <Container className='bg-white p-4 mt-3 shadow-sm rounded-3'>
                <FormFloating className='my-3'>
                    <FormControl type='text' placeholder=' ' required minLength={3} maxLength={15} value={firstName} onChange={e => setFirstName(e.target.value)} />
                    <FormLabel>First Name</FormLabel>
                    <p className='invalid-feedback'>Between 3 and 15 Charachters</p>
                </FormFloating>

                <FormFloating className='my-3'>
                    <FormControl type='text' placeholder=' ' required minLength={1} maxLength={15} value={middleName} onChange={e => setMiddleName(e.target.value)} />
                    <FormLabel>Middle Name</FormLabel>
                    <p className='invalid-feedback'>Between 1 and 15 Charachters</p>
                </FormFloating>

                <FormFloating className='my-3'>
                    <FormControl type='text' placeholder=' ' required minLength={3} maxLength={15} value={lastName} onChange={e => setLastName(e.target.value)} />
                    <FormLabel>Last Name</FormLabel>
                    <p className='invalid-feedback'>Between 3 and 15 Charachters</p>
                </FormFloating>

                <FormFloating className='my-3'>
                    <FormControl type='email' placeholder=' ' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required value={email} onChange={e => setEmail(e.target.value)} />
                    <FormLabel>Email</FormLabel>
                    <p className='invalid-feedback'>Required</p>
                </FormFloating>

                <FormFloating className='my-3'>
                    <FormControl type='text' placeholder=' ' required value={mobile} onChange={e => setMobile(e.target.value)} />
                    <FormLabel>Mobile</FormLabel>
                    <p className='invalid-feedback'>Required</p>
                </FormFloating>

                <FormFloating className='my-3'>
                    <FormControl type='text' placeholder=' ' required value={nationality} onChange={e => setNationality(e.target.value)} />
                    <FormLabel>Nationality</FormLabel>
                    <p className='invalid-feedback'>Required</p>
                </FormFloating>

                <FormFloating className='my-3'>
                    <FormControl type='text' placeholder=' ' required value={nidpassport} onChange={e => setNidpassport(e.target.value)} />
                    <FormLabel>NID/Passport</FormLabel>
                    <p className='invalid-feedback'>Required</p>
                </FormFloating>

                <FormFloating className='my-3'>
                    <FormControl type='text' placeholder=' ' required value={promoVideo} onChange={e => setPromoVideo(e.target.value)} />
                    <FormLabel>Youtube Promo Video URL</FormLabel>
                    <p className='invalid-feedback'>Required</p>
                </FormFloating>

                <FormFloating className='my-3'>
                    <FormControl as='textarea' placeholder=' ' required minLength={20} maxLength={500} value={bio} onChange={e => setBio(e.target.value)} />
                    <FormLabel>Bio</FormLabel>
                    <p className='invalid-feedback'>Between 20 and 500 Charachters</p>
                </FormFloating>

                <fieldset>
                    <legend>Gender</legend>
                    <ButtonGroup className="mb-2">
                        {genders.map((radio, idx) => (
                            <ToggleButton
                                key={idx}
                                id={`gender-${idx}`}
                                type="radio"
                                variant={idx % 2 ? 'outline-success' : 'outline-danger'}
                                name="gender"
                                value={radio.value}
                                checked={gender === radio.value}
                                onChange={(e) => setGender(e.currentTarget.value)}
                                required
                            >
                                {radio.name}
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                    <p className='text-danger'>{gender == '' && 'Required'}</p>
                </fieldset>

                <FormFloating className='my-3'>
                    <FormControl type='text' placeholder=' ' required minLength={10} maxLength={45} value={loginName} onChange={e => setLoginName(e.target.value)} />
                    <FormLabel>Login Name</FormLabel>
                    <p className='invalid-feedback'>Between 10 and 45 Charachters</p>
                </FormFloating>

                <FormFloating className='my-3'>
                    <FormControl type='password' placeholder=' ' name='password' onChange={handle_password_changed} required={!patternOk} value={password} minLength={patternOk ? 0 : 200} />
                    <FormLabel>Password</FormLabel>
                    <p className='text-danger'>{!patternOk && 'Password should Contain Minimum of 6 Charachters (Upper, Lower, Number, Symbol)'}</p>
                </FormFloating>

                <FormFloating className='my-3'>
                    <FormControl type='password' placeholder=' ' name='confirmPassword' onChange={handle_confirm_password_changed} value={confirmPassword} required />
                    <FormLabel>Re-Password</FormLabel>
                    <p className='invalid-feedback'>Required</p>
                </FormFloating>

                <p>
                    {
                        passMatch ?
                            <span className='text-success'>Passwords Match</span> :
                            <span className='text-danger'>Passwords did not match</span>
                    }
                </p>

                {addMessage}

                {
                    !added &&
                    <div className='d-flex justify-content-end py-4'>
                        <Button variant='primary' type='submit'  >Add Instructor</Button>
                    </div>
                }
            </Container>
        </Form>
    )
}
