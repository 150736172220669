// React Bootstrap
import { Navbar, Container, Nav } from 'react-bootstrap';

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { ACTN_show_login, ACTN_show_register } from '../Redux/SettingsReducer';
import { ACTN_logged_out } from '../Redux/UserReducer';

// Icons
import { AiFillMessage } from 'react-icons/ai';

// React Router Dom
import { Link } from 'react-router-dom'

export default function TopNavbar() {

    const { app_title } = useSelector(state => state.vars)

    const { name, msgs_count } = useSelector(state => state.user)

    const dispatch = useDispatch();

    const { loggedIn } = useSelector(state => state.user);

    return (
        <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark" sticky='top' className='py-2'   >
            <Container fluid>
                <Link className='navbar-brand' to="/">{app_title}</Link>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Link className='nav-link' to="/account">My Account</Link>
                        <Link className='nav-link' to="/courses">Our Courses</Link>
                        <Link className='nav-link' to="/teachers">Our Teachers</Link>

                    </Nav>
                    <Nav>
                        {
                            loggedIn ?
                                <>
                                    <span className='navbar-text text-warning'>HOWDY {name} 💪</span>
                                    <div className='text-info'>
                                        <span className='notification mx-2'>
                                            <AiFillMessage />
                                            {
                                                msgs_count.student_new > 0 &&
                                                <small>
                                                    {msgs_count.student_new}
                                                </small>
                                            }
                                        </span>
                                    </div>

                                    <a className='nav-link text-danger hand' onClick={() => dispatch(ACTN_logged_out())}>Logout</a>
                                </> :
                                <>
                                    <a className='nav-link' onClick={() => dispatch(ACTN_show_login())}>Login</a>
                                    <a className='nav-link' onClick={() => dispatch(ACTN_show_register())}>Register</a>
                                </>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
