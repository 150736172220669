import { useState } from "react";

// React Bootstrap
import { Button, Container, Form, FormControl, FormFloating, FormGroup, FormLabel } from "react-bootstrap";

// React Router DOM
import { useNavigate, useParams } from "react-router-dom"

// Redux
import { useDispatch } from "react-redux";
import { ACTN_set_loading } from '../../Redux/SettingsReducer';

// Toastify
import { toast } from "react-toastify";

// APIs
import { API_reset_password } from "../../apis"

// consts

import { password_Regex } from '../../Helpers/consts';

export default function ResetPassword() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [msg, setMsg] = useState([]);

    const { activationkey } = useParams();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handle_reset_password = async () => {
        dispatch(ACTN_set_loading(true));

        const errors = [];

        const key = activationkey.split("=")[1];
        // console.log(key);

        // Validate activation key
        if (key == undefined) {
            errors.push('Please Use Link Sent to Your Email');
        }

        // Validate Email
        const email_regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

        if (!email_regex.test(email.toLowerCase())) {
            errors.push('Invalid Email');
        }

        // Validate Password

        if (!password_Regex.test(password)) {
            errors.push('Invalid Password, password must contain upper and lower case letters, numbers, and symbols');

        }

        // Validate Password Match
        if (password !== rePassword) {
            errors.push('Passwords do not Match');
        }

        const data = {
            email, password, key
        }

        if (errors.length > 0) {

            setMsg(errors);

            errors.map(error => {
                toast.error(error);
            });

            dispatch(ACTN_set_loading(false));
            return
        }

        await API_reset_password(data).then(async res => {

            dispatch(ACTN_set_loading(false));


            setMsg(res.msg);

            if (res.success) {
                toast.success(res.msg);
                navigate('/');
            } else {
                toast.error(res.msg);
            }
        })


    }

    return (

        <div className="mt-3 border shadow-sm p-4 rounded">
            <h2>Rest Your Password</h2>

            <FormFloating className='my-2'>
                <FormControl id='email' value={email} onChange={e => setEmail(e.target.value)} placeholder=' ' />
                <FormLabel htmlFor='email'>Your Email</FormLabel>
            </FormFloating>

            <FormFloating className='my-2'>
                <FormControl id='password' type='password' value={password} onChange={e => setPassword(e.target.value)} placeholder=' ' />
                <FormLabel htmlFor='password'>New Password</FormLabel>
            </FormFloating>

            <FormFloating className='my-2'>
                <FormControl id='rePassword' type='password' value={rePassword} onChange={e => setRePassword(e.target.value)} placeholder=' ' />
                <FormLabel htmlFor='rePassword'>Re-enter New Password</FormLabel>
            </FormFloating>

            <FormGroup className='my-2 d-flex justify-content-end'>
                <Button onClick={handle_reset_password}>Reset</Button>
            </FormGroup>

            <FormGroup className='my-2'>
                {msg.map(err => <p>{err}</p>)}
            </FormGroup>

        </div>

    )
}
