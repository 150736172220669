import { configureStore } from '@reduxjs/toolkit';

// Import all reducers
import UserReducer from './UserReducer';
import TeacherReducer from './TeacherReducer';
import CoursesReducer from './CoursesReducer';
import SettingsReducer from './SettingsReducer';
import ChatReducer from './ChatReducer';
import VarsReducer from './VarsReducer';

const store = configureStore({

    reducer: {
        user: UserReducer,
        teacher: TeacherReducer,
        courses: CoursesReducer,
        chat: ChatReducer,
        settings: SettingsReducer,
        vars: VarsReducer
    }
});

export default store;