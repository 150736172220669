import { createSlice } from "@reduxjs/toolkit";

const name = 'settings';
const initialState = {
    app_settings: {},
    loading: false,
    show_login: false,
    show_register: false,
    show_forget_pass: false,
    show_reset_pass: false,
    show_slot_details: false,
    slot_details: []
}

const SetttingsReducer = createSlice({
    name,
    initialState,
    reducers: {
        ACTN_set_app_settings: (state, action) => {
            // console.log('action.payload', action.payload);
            state.app_settings = { ...state.app_settings, ...action.payload }
        },
        ACTN_set_loading: (state, action) => {
            state.loading = action.payload;
        },
        ACTN_show_login: state => {
            state.show_login = true;
            state.show_register = false;
            state.show_forget_pass = false;
            state.show_reset_pass = false;
        },
        ACTN_show_register: state => {
            state.show_register = true;
            state.show_login = false;
            state.show_forget_pass = false;
            state.show_reset_pass = false;
        },
        ACTN_show_forget_pass: state => {
            state.show_forget_pass = true;
            state.show_login = false;
            state.show_register = false;
            state.show_reset_pass = false;
        },
        ACTN_show_reset_pass: state => {
            state.show_reset_pass = true;
            state.show_login = false;
            state.show_register = false;
            state.show_forget_pass = false;
        },
        ACTN_hide_login: state => {
            state.show_login = false;
        },
        ACTN_hide_register: state => {
            state.show_register = false;
        },
        ACTN_hide_forget_pass: state => {
            state.show_forget_pass = false;
        },
        ACTN_hide_reset_pass: state => {
            state.show_reset_pass = false;
        },
        ACTN_show_slot_details: (state, action) => {
            state.show_slot_details = action.payload
        },
        ACTN_set_slot_details: (state, action) => {
            state.slot_details = action.payload
        }
    }
});

export default SetttingsReducer.reducer;

export const { ACTN_set_lang,
    ACTN_set_app_settings,
    ACTN_set_loading,
    ACTN_show_login,
    ACTN_show_register,
    ACTN_hide_login,
    ACTN_hide_register,
    ACTN_show_forget_pass,
    ACTN_show_reset_pass,
    ACTN_hide_forget_pass,
    ACTN_hide_reset_pass,
    ACTN_show_slot_details,
    ACTN_set_slot_details,
} = SetttingsReducer.actions;