// react bootstrap
import { Container, ListGroup, ListGroupItem } from 'react-bootstrap'
import Header from '../Components/Header'

export default function Privacy() {
    return (
        <>
            <Header />
            <Container className='pb-5 mb-5'>
                <h1>Privacy Policy</h1>
                <p>We collect and manage user data according to the following Privacy Policy.</p>
                <h4 className='my-3'>Data Collected</h4>
                <ListGroup>
                    <ListGroupItem>
                        We collect information when you create an account, subscribe, participate in any interactive features of our website’s services, fill out a form, request customer support or otherwise communicate with us.

                    </ListGroupItem>
                    <ListGroupItem>
                        The types of information we may collect include name, mobile phone number, email address, postal address, credit card information and other contact or identifying information you accept to provide.
                    </ListGroupItem>
                    <ListGroupItem>
                        We collect anonymous data from every visitor of the Website to monitor traffic, fix potential bugs, and improve user experience.
                    </ListGroupItem>
                    <ListGroupItem>
                        We may collect for example, information like web requests, the data sent in response to such requests, the Internet Protocol address, the browser type, the browser language, and a timestamp for the request.
                    </ListGroupItem>
                    <ListGroupItem>
                        We also use various technologies to collect information, and this may include sending cookies to your computer. Cookies are small data files stored on your hard drive or in your device memory that helps us to improve our services and your experience, see which areas and features of our services are popular and count visits.
                    </ListGroupItem>
                    <ListGroupItem>
                        We may also collect information using web beacons (also known as "tracking pixels"). Web beacons are electronic images that may be used in our services or emails and to track count visits or understand usage and campaign effectiveness.
                    </ListGroupItem>
                </ListGroup>
                <h4 className='my-3'>Use of the Data</h4>
                <ListGroup>
                    <ListGroupItem>
                        We only use your personal information to help us in providing to you our services or to communicate with you about the Website or the services we offer.
                    </ListGroupItem>
                    <ListGroupItem>
                        Our Privacy Policy was created with the help of the Privacy Policy Template.
                    </ListGroupItem>
                    <ListGroupItem>
                        We employ industry standard techniques to protect against unauthorized access of data about you that we store, including personal information.
                    </ListGroupItem>
                    <ListGroupItem>
                        We do not share personal information you have provided to us with any third-parties without your consent, unless: Doing so is appropriate to carry out your own request.
                    </ListGroupItem>
                    <ListGroupItem>
                        We believe it's needed to enforce our legal agreements or that is legally required.
                    </ListGroupItem>
                    <ListGroupItem>
                        We believe it's needed to detect, prevent or address fraud, security or technical issues Sharing of Data.
                    </ListGroupItem>
                    <ListGroupItem>
                        Aggregated, anonymized data is periodically transmitted to external services to help us improve the Website and service.
                    </ListGroupItem>
                    <ListGroupItem>
                        We may allow third parties to provide analytics services.
                    </ListGroupItem>
                    <ListGroupItem>
                        These third parties may use cookies, web beacons and other technologies to collect information about your use of the services and other websites, including your IP address, web browser, pages viewed, time spent on pages, links clicked and conversion information.
                    </ListGroupItem>
                    <ListGroupItem>
                        We may also use social buttons provided by services like Twitter, Google+, LinkedIn and Facebook.
                    </ListGroupItem>
                    <ListGroupItem>
                        Your use of these third-party services is entirely optional.
                    </ListGroupItem>
                    <ListGroupItem>
                        We are not responsible for the privacy policies and/or practices of these third party services, and you are responsible for reading and understanding those third party services' privacy policies.
                    </ListGroupItem>
                </ListGroup>
                <h4 className='my-3'>Security</h4>
                <ListGroup>
                    <ListGroupItem>
                        We take reasonable steps to protect personally identifiable information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction, but, you should keep in mind that no Internet transmission is ever completely secure or error-free; In particular, email sent to or from the Sites may not be secure.
                    </ListGroupItem>
                </ListGroup>
                <h4 className='my-3'>About Children</h4>
                <ListGroup>
                    <ListGroupItem>
                        The Website is not intended for children under the age of 13 without adult supervision.
                    </ListGroupItem>
                    <ListGroupItem>
                        We do not knowingly collect personally identifiable information via the Website from visitors in this age group.
                    </ListGroupItem>
                </ListGroup>
                <h4 className='my-3'>Changes to the Privacy Policy</h4>
                <ListGroup>
                    <ListGroupItem>
                        We may amend this Privacy Policy from time to time.
                    </ListGroupItem>
                    <ListGroupItem>
                        Use of information we collect now is subject to the Privacy Policy in effect at the time such information is used.
                    </ListGroupItem>
                    <ListGroupItem>
                        If we make major changes in the way we collect or use information, we will notify you by posting an announcement on the Website or sending you an email.
                    </ListGroupItem>
                </ListGroup>
            </Container>
        </>
    )
}
