import { useEffect, useState } from 'react'

// APIs
import { API_get_active_categories, API_get_active_courses_by_category } from '../apis'

// React Bootstrap
import { Alert, Container } from 'react-bootstrap';

// React Router DOM
import { useNavigate } from 'react-router-dom';

// Components
import Skeleton from '../Components/Skeleton'
import CategoriesList from '../Components/CategoriesList';
import CoursesList from '../Components/CoursesList';

// Icons
import { IoMdArrowRoundBack } from 'react-icons/io';

// Redux
import { useDispatch } from 'react-redux';
import { ACTN_set_current_course } from '../Redux/CoursesReducer';

export default function OurCourses() {

    const [ready, setReady] = useState(false);
    const [allCategories, setAllCategories] = useState([]);
    const [parentCategories, setParentCategories] = useState([]);
    const [childCategories, setChildCategories] = useState([]);
    const [courses, setCourses] = useState([]);
    const [selectedParentCategory, setSelectedParentCategory] = useState('');
    const [selectedChildCategory, setSelectedChildCategory] = useState('');

    const navigate = useNavigate();

    const dispatch = useDispatch()

    useEffect(() => {
        fnc_get_all_categories();
    }, []);

    const fnc_get_all_categories = async () => {

        setReady(false);

        await API_get_active_categories()
            .then(res => {
                // console.log('API_get_active_categories res', res)
                setAllCategories(res);

                setReady(true);

                const parent_categories = res.filter(category => category.level == 'parent');

                setParentCategories(parent_categories);
            });
    }

    const handle_parent_category_changed = (category_id, category_name) => {

        setSelectedParentCategory(category_name);

        setChildCategories(allCategories.filter(category => category.category_parent == category_id));

    }

    const handle_child_category_changed = async (category_id, category_name) => {
        setSelectedChildCategory(category_name);

        setReady(false);

        await API_get_active_courses_by_category(category_id)
            .then(res => {
                setCourses(res)
                setReady(true);
            })

    }

    const handle_course_changed = (course_id, course_name) => {

        dispatch(ACTN_set_current_course({ course_id, course_name }))
        navigate(`/teachers/${course_id}`)

    }

    // if child category clicked
    if (courses.length > 0) {
        return (
            <Container>
                <h4 className='hand txt-dark_orange py-4' onClick={() => handle_child_category_changed('')}>
                    <IoMdArrowRoundBack />
                    {selectedChildCategory}
                </h4>
                {
                    courses.length === 0 ?
                        [...Array(4)].map((_, i) => <Skeleton key={i} />) :
                        <CoursesList courses={courses} handle_course_changed={handle_course_changed} />
                }
            </Container>
        )
    }

    // if parent categories clicked
    if (childCategories.length > 0) {
        return (
            <Container>
                <h4 className='hand txt-dark_orange py-4' onClick={() => handle_parent_category_changed('')}>
                    <IoMdArrowRoundBack />
                    {selectedParentCategory}
                </h4>
                {
                    childCategories.length === 0 ?
                        [...Array(4)].map((_, i) => <Skeleton key={i} />) :
                        <div>
                            <CategoriesList categories={childCategories} handle_category_changed={handle_child_category_changed} />
                        </div>
                }
            </Container>
        )
    }

    if (ready && (parentCategories.length === 0 && courses.length === 0)) {
        return <Alert variant='warning'>
            No courses available at the moment
        </Alert>
    }

    // Default to parent categories
    return (
        <Container className='mt-4'>
            <h1 className='text-center txt-tomato'>Our Courses</h1>
            {
                parentCategories.length === 0 ?
                    [...Array(4)].map((_, i) => <Skeleton key={i} />) :
                    <div>
                        {/* <ParentCategories categories={parentCategories} handle_category_changed={handle_parent_category_changed} /> */}
                        <CategoriesList categories={parentCategories} handle_category_changed={handle_parent_category_changed} />
                    </div>
            }
        </Container>
    )
}
