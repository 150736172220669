import React, { useEffect, useState } from 'react'

// Redux
import { ACTN_hide_forget_pass, ACTN_show_login } from '../../Redux/SettingsReducer';
import { useDispatch } from 'react-redux';

// API
// import { API_request_reset_password } from '../../apis';

export default function ForgetPassForm() {

    const [email, setEmail] = useState('');
    const [error, setError] = useState('');


    const dispatch = useDispatch();

    useEffect(() => {

    }, [])


    const handle_reset_pass = async () => {

        // Validate Email
        const email_regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

        if (!email_regex.test(email.toLowerCase())) {
            setError('Invalid Email');
            return;
        }

        const data = { email }

        // const res = await API_request_reset_password(data);

        setError('A message was sent to reset password, please check your email and junk folder within 15 minutes');

    }

    return (

        <form action="" method="post" className="was-validated login_form" >
            <div className='form_inner'>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                    <span className='text-danger'></span>
                    <p className="text-center text-dark p-0 m-0">Reset Your Password</p>
                    <span className='text-danger display-3 hand' onClick={() => dispatch(ACTN_hide_forget_pass())}>&times;</span>
                </div>

                <div className="form-group m-3">
                    <label htmlFor="email">Email</label>
                    <input type="text" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" required />
                </div>

                <div className="form-group m-3">
                    <p className='text-danger'>{error}</p>
                </div>

                <div className="btn-group m-3 d-flex justify-content-between">
                    <input type="button" className="btn btn-primary" value='Send Reset Link' onClick={handle_reset_pass} />
                </div>

                <div className="btn-group m-3 d-flex justify-content-between">
                    <button type="button" className="btn btn-warning" onClick={() => dispatch(ACTN_show_login())}>I Know My Password</button>
                </div>
            </div>
        </form>

    )
}
