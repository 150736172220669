import { createSlice } from "@reduxjs/toolkit";
import { hlpr_get_localstorage } from '../Helpers/Functions';

const name = 'user';
const initialState = {
    id: 0,
    personal_token: '',
    last_used_at: '',
    name: '',
    email: '',
    mobile: '',
    gender: '',
    role: 'guest',
    isAdmin: false,
    isTeacher: false,
    isPendingTeacher: false,
    loggedIn: false,
    profile_completed: false,
    current_teacher: {},
    msgs_count: {
        student_all: 0,
        student_new: 0,
        teacher_all: 0,
        teacher_new: 0
    }
}

export const UserReducer = createSlice({
    name,
    initialState,
    reducers: {
        ACTN_logged_in: (state, action) => {
            // debugger
            // console.log('user reducer logged in', action.payload);

            const { user_data } = action.payload;

            // console.log('user reducer user_data', user_data);
            // console.log('user_data == false || user_data.user_id == 0', (user_data == false || user_data.user_id == 0));

            state.id = user_data.user_id;
            state.personal_token = user_data.personal_token;
            state.last_used_at = user_data.last_used_at;
            state.name = user_data.user_f_name;
            state.email = user_data.user_email;
            state.mobile = user_data.user_mobile;
            state.gender = user_data.user_gender == 0 ? 'f' : 'm';
            state.role = user_data.user_role;
            state.isAdmin = user_data.user_role == 'admin';
            state.isTeacher = user_data.user_role == 'instructor';
            state.isPendingTeacher = user_data.user_role == 'pending instructor';
            state.loggedIn = true;
            state.profile_completed = user_data.profile_completed;

            // const data = user_data;
            user_data['isPendingTeacher'] = state.isPendingTeacher

            const remember = user_data.remember;

            delete user_data.remember;

            if (remember) {
                localStorage.setItem('svbh_loggedin', JSON.stringify(user_data));
            }
            sessionStorage.setItem('temp_svbh_loggedin', JSON.stringify(user_data));

        },
        ACTN_logged_out: state => {
            state.id = 0;
            state.personal_token = '';
            state.last_used_at = '';
            state.name = '';
            state.email = '';
            state.mobile = '';
            state.gender = '';
            state.role = 'guest';
            state.isAdmin = false;
            state.isTeacher = false;
            state.isPendingTeacher = false;
            state.loggedIn = false;
            state.profile_completed = false;
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = '/';

            // console.log('logged out successfully.')
        },
        ACTN_update_user: (state, action) => {
            state.name = action.payload.name;
            state.gender = action.payload.gender;
        },
        ACTN_set_current_teacher: (state, action) => {
            state.current_teacher = action.payload
        },
        ACTN_set_msgs_count: (state, action) => {
            state.msgs_count = action.payload
        },
        ACTN_update_user_keys: (state, action) => {
            // console.log('action:', action);

            const new_entries = action.payload;

            // console.log('ACTN_update_user_keys ->new_entries:', new_entries);

            state = {
                ...state, ...new_entries
            }

            let { user_data, type } = hlpr_get_localstorage();

            // localstorage = JSON.parse(localstorage);

            // // console.log('localstorage before:', localstorage);

            // let user_data = localstorage.user_data;

            let new_user_data = { ...user_data, ...new_entries }

            // console.log('type', type)
            // console.log('new_entries', new_entries)
            // console.log('user_data after adding new_entries', new_user_data)

            // localstorage = { ...localstorage, ...new_entries };

            new_user_data = JSON.stringify(new_user_data);

            // console.log('new_user_data after JSON.stringify:', new_user_data);

            switch (type) {
                case 'localstorage':
                    localStorage.removeItem('svbh_loggedin');
                    localStorage.setItem('svbh_loggedin', new_user_data);
                    break;
                case 'sessionstorage':
                    // console.log('type is sessionstorage')
                    sessionStorage.removeItem('temp_svbh_loggedin');
                    sessionStorage.setItem('temp_svbh_loggedin', new_user_data);
                // sessionStorage.setItem('temp2_svbh_loggedin', new_user_data);
            }

            // // console.log('state', state);
        }
    }
});

export default UserReducer.reducer;
export const {
    ACTN_logged_in,
    ACTN_logged_out,
    ACTN_update_user,
    ACTN_set_current_teacher,
    ACTN_set_msgs_count,
    ACTN_update_user_keys
} = UserReducer.actions;
