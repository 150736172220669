// react-router-dom
import { useNavigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Imgs
import instructors_img from '../../assets/imgs/instructors_01.jpg';

// Animation
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default function SectionInstructors() {
	const navigate = useNavigate();

	const { loggedIn, isAdmin } = useSelector((state) => state.user);

	const { instructors_label } = useSelector(
		(state) => state.settings.app_settings
	);

	return (
		<div className="container bg-slate-200 -mt-14">
			<div className="flex gap-4 flex-col md:flex-row-reverse py-5">
				<div className="">
					<AnimationOnScroll animateIn="animate__zoomIn" duration={1}>
						<div className="hand">
							<img className="mw-100 rounded" src={instructors_img} />
						</div>
					</AnimationOnScroll>
				</div>

				<div className="md:w-8/12 md:text-right">
					<div>
						<AnimationOnScroll animateIn="animate__zoomIn" duration={1}>
							<h3 className="text-4xl text-blue-700">Our Teachers</h3>
						</AnimationOnScroll>

						<AnimationOnScroll animateIn="animate__zoomIn" duration={1}>
							<h6>Meet Our Educators</h6>
						</AnimationOnScroll>

						<AnimationOnScroll animateIn="animate__zoomIn" duration={1}>
							<h2 className="text-2xl font-extrabold">Inspire, Mentor, Lead</h2>
						</AnimationOnScroll>

						<AnimationOnScroll animateIn="animate__zoomIn" duration={1}>
							<p>
								Our dedicated educators are the heart of our Learning Management System. With a passion for teaching and a commitment to excellence, they inspire, mentor, and lead our students toward their goals. Our teachers bring a wealth of knowledge and real-world experience to the classroom, creating an engaging and supportive learning environment. They are here to guide you every step of the way on your educational journey. Discover more about our talented educators and how they can help you achieve your full potential.
							</p>
						</AnimationOnScroll>

						{/* <AnimationOnScroll animateIn="animate__zoomIn" duration={1}>
							<button disabled
								className="px-5 py-2 rounded-md shadow text-white bg-blue-500"
								onClick={() =>
									navigate(
										loggedIn && !isAdmin ? '/account/teachers' : '/teachers'
									)
								}
							>
								Know about our teachers
							</button>
						</AnimationOnScroll> */}
					</div>
				</div>
			</div>
		</div>
	);
}
