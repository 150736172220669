import { useEffect, useState } from 'react'

// APIs
import { API_cancel_slot_by_teacher, API_get_teacher_sessions, API_get_booking_details, API_get_booking_slot_chat, API_send_booking_slot_chat, API_save_attendance } from '../../apis'

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ACTN_set_loading, ACTN_show_slot_details, ACTN_set_slot_details } from '../../Redux/SettingsReducer';
import { ACTN_set_msgs_count } from '../../Redux/UserReducer';

// React Bootstrap
import { Alert, Container, Form } from 'react-bootstrap';

// Helpers
import { hlpr_sort_dates } from '../../Helpers/Functions';
import { Link } from 'react-router-dom';

// React Router DOM
import { useNavigate } from 'react-router-dom';

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

// Images
import female from '../../assets/imgs/female.png'
import male from '../../assets/imgs/male.png'

// Helpers
import { hlpr_from_to_slot, hlpr_get_user_messages, hlpr_sort_array_by_ref } from '../../Helpers/Functions';

// Icons
import { FaRegEye } from 'react-icons/fa'
import { GiCancel } from 'react-icons/gi'
import { SiGooglemeet } from 'react-icons/si'
import { BsFillChatLeftDotsFill } from 'react-icons/bs'

// react-bootstrap
import { Button, ButtonGroup, CloseButton, FormControl, FormGroup, FormLabel, ListGroup, ListGroupItem, Modal } from 'react-bootstrap';

// react toastify
import { toast } from 'react-toastify';

export default function Sessions() {

    const [viewChatModal, setViewChatModal] = useState(false);
    const [chats, setChats] = useState([]);
    const [chatMsg, setChatMsg] = useState('');
    const [sessions, setSessions] = useState([]);

    const [sessionNotes, setSessionNotes] = useState('');
    const [sessionLinks, setSessionLinks] = useState({});

    const [bookingDetailId, setBookingDetailId] = useState('');
    const [chatBoxInfo, setChatBoxInfo] = useState({});
    const [cancelInfo, setCancelInfo] = useState({});

    const [showSessionsModal, setShowSessionsModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [reload, setReload] = useState(true);
    const [startCountdown, setStartCountdown] = useState(false);
    const [countdown, setCountdown] = useState(5);

    const dispatch = useDispatch();

    const user_id = useSelector(state => state.user.id)

    const { show_slot_details } = useSelector(state => state.settings)

    useEffect(() => {
        if (reload) {
            fnc_get_teacher_sessions()
        }

        if (startCountdown) {
            setTimeout(() => {
                setCountdown(countdown - 1)
                if (countdown <= 0) {
                    setStartCountdown(false)
                }
            }, 1000)
        }

    }, [reload, startCountdown, countdown])

    const fnc_get_teacher_sessions = async () => {
        await API_get_teacher_sessions(user_id).then(data => {

            const sorted_sessions = hlpr_sort_dates(data)

            const schdl = {};

            sorted_sessions.forEach(book => {
                if (Array.isArray(schdl[book.slot_date])) {
                    schdl[book.slot_date].splice(0, 0, book);
                } else {
                    schdl[book.slot_date] = [book];
                }

            })

            setSessions(schdl);
        })
        setReload(false)
    }

    const handle_view_slot_info = async (booking_id, booking_detail_id, student_id) => {
        dispatch(ACTN_set_loading(true))

        await API_get_booking_details(booking_id, booking_detail_id, student_id).then(res => {
            dispatch(ACTN_set_loading(false))

            if (res == false) {
                return
            }

            dispatch(ACTN_set_slot_details(res));
            dispatch(ACTN_show_slot_details(true));

            // console.log('res', res)
        })
    }

    const handle_view_chat_modal = async (booking_id, booking_detail_id, course_name, student_name) => {

        setBookingDetailId(booking_detail_id);
        setChatBoxInfo({ course_name, student_name })

        await API_get_booking_slot_chat(booking_id, booking_detail_id, user_id, 'T').then(res => {
            hlpr_get_user_messages(dispatch, ACTN_set_msgs_count, user_id)

            setChats(res);

        });
        setViewChatModal(true)
    }

    const handle_view_cancel_slot_confirm = (booking_detail_id, course_name, student_name, student_id) => {
        setCancelInfo({ booking_detail_id, course_name, student_name, student_id })
        setShowCancelModal(true)
        setCountdown(5)
        setStartCountdown(true)

    }

    const handle_cancel_booking = async () => {
        dispatch(ACTN_set_loading(true))
        await API_cancel_slot_by_teacher(cancelInfo.booking_detail_id, user_id, cancelInfo.student_id).then(res => {
            dispatch(ACTN_set_loading(false))
            if (res) {
                toast.success('Booked session canceled successfully');
            }
            setReload(true);
            handle_close_modal();
        })
    }

    const handle_close_modal = () => {
        setCancelInfo({});
        setShowCancelModal(false)
        setStartCountdown(false)
        setCountdown(5)
    }

    const handle_send_msg = async () => {
        await API_send_booking_slot_chat(chatMsg, user_id, 'T', bookingDetailId).then(res => {
            if (res) {
                setChats([...chats, { user_role: 'T', chat_on: 'NOW', msg: chatMsg }])
            }
        })
    }

    const handle_open_session_link = async ({ booking_detail_id, booking_id, course_id, course_name, hour, new_msgs, sessions_alt_link, sessions_link, student_id }) => {

        setBookingDetailId(booking_detail_id);
        setSessionLinks({ sessions_alt_link, sessions_link });

        const data = { booking_detail_id }

        await API_save_attendance(data)
            .then(res => {
                if (res) {
                    setShowSessionsModal(true);
                } else {
                    toast.error('Something went wrong, please reload the page and try again or contact system administrator.')
                    return;
                }
            });
    }

    const handle_add_session_notes = async () => {

        const data = { notes: sessionNotes, booking_detail_id: bookingDetailId }

        await API_save_attendance(data)
            .then(res => {
                if (res) {
                    toast.success('Notes added successfully.')
                } else {
                    toast.success('Notes did not added successfully, please try again.')
                }
                setShowSessionsModal(false)
                setSessionNotes('')
            })
    }

    return (
        <>
            <h2>My Upcoming Sessions</h2>
            {
                Object.keys(sessions).length == 0 &&
                <Alert>
                    <h2>You don't have any sessions yet!</h2>
                </Alert>
            }

            {/* Sessions Modal */}
            <Modal show={showSessionsModal}>
                <Modal.Header closeButton>Start Your Session</Modal.Header>
                <Modal.Body>

                    <div target='_blank' className='text-decoration-none txt-deep_sky_blue'>
                        <a target='_blank' href={sessionLinks.sessions_link}>Link 1<SiGooglemeet className='txt-deep_sky_blue m-2 hand' /></a>
                        <a target='_blank' href={sessionLinks.sessions_alt_link}>Link 2<SiGooglemeet className='txt-deep_sky_blue m-2 hand' /></a>
                    </div>

                    <Form.Label>Want to add some notes?</Form.Label>
                    <Form.Floating>
                        <Form.Control as='textarea' value={sessionNotes} onChange={e => setSessionNotes(e.target.value)} />
                    </Form.Floating>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='success' onClick={handle_add_session_notes}>Save notes and close</Button>
                    <Button variant='danger' onClick={() => setShowSessionsModal(false)}>No, just close</Button>
                </Modal.Footer>
            </Modal>

            {/* Cancel Modal */}
            <Modal show={showCancelModal}>
                <Modal.Header>Confirm Cancel Session</Modal.Header>
                <Modal.Body>
                    <p>You are about to cancel the booked session for Course <strong>{cancelInfo.course_name}</strong> with <strong>{cancelInfo.student_name}</strong></p>
                    <p>Are you sure?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={handle_cancel_booking} disabled={countdown > 0 && 'disabled'}>{countdown > 0 ? `${countdown} Yes, Cancel Booking` : 'Yes, Cancel Booking'}</Button>
                    <Button onClick={handle_close_modal}>No, do not cancel</Button>
                </Modal.Footer>
            </Modal>

            {/* Chat Modal */}
            <Modal show={viewChatModal}>
                <Modal.Header>
                    {chatBoxInfo.course_name} - {chatBoxInfo.student_name}
                    <CloseButton onClick={() => setViewChatModal(false)}></CloseButton>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <FormLabel>New Message</FormLabel>
                        <FormControl as='textarea' value={chatMsg} onChange={e => setChatMsg(e.target.value)} />
                    </FormGroup>
                    <ButtonGroup className='my-3'>
                        <Button onClick={handle_send_msg}>Send</Button>
                    </ButtonGroup>
                    <ListGroup style={{ overflow: 'auto', height: '100%' }}>
                        {
                            chats.map((chat, i) =>
                                <ListGroupItem key={i} className='mb-3 d-flex flex-column shadow-sm rounded'>
                                    <div className='d-flex flex-column border-bottom'>
                                        <div className='d-flex flex-row '>
                                            <div className='d-flex flex-column align-items-center'>
                                                <img src={chat.gender == 'Female' ? female : male} alt={chat.gender} className='img-32' />
                                                <small>{chat.user_role == 'T' ? 'Me' : chat.user_role == 'S' ? 'Student' : 'Admin'}</small>

                                            </div>
                                            <small className='ms-2'>{chat.user_name}</small>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column flex-grow-1'>
                                        <p>{chat.msg}</p>
                                        <div className='d-flex flex-row justify-content-between'>
                                            <small>
                                                SEEN BY: <span>{chat.admin_seen == 1 && 'Admin'} - {chat.teacher_seen == 1 && 'Teacher'}</span>
                                            </small>
                                            <small className='fs-05 align-self-end'>{chat.chat_on}</small>
                                        </div>
                                    </div>

                                    <div>
                                    </div>
                                </ListGroupItem>
                            )
                        }
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setViewChatModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Swiper
                slidesPerView={3}
                spaceBetween={5}

                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="rounded d-flex flex-row"
            >

                {
                    Object.entries(sessions).map((session, i) => {

                        const session_date = session[0];
                        const session_day = new Date(session_date).setHours(0, 0, 0, 0);
                        const today = new Date().setHours(0, 0, 0, 0);

                        const can_cancel = session_day > today;
                        const can_start = session_day == today;

                        // HACK to TEST
                        // const can_start = true;

                        // console.log('session_day', session_day)
                        // console.log('today', today)
                        let slots = [];

                        return (
                            <SwiperSlide key={i} className='d-flex flex-grow-1 flex-column pb-3 bg-light my-2 rounded'>
                                <h4 className='bg-dark text-light p-2'>{session_date}</h4>
                                <div>
                                    {
                                        session[1].map(book => {

                                            let booked_slots = book.booked_slots.split(',');

                                            booked_slots.forEach(slt => {
                                                slots.push({
                                                    hour: slt,
                                                    course_name: book.course_name,
                                                    course_id: book.course_id,
                                                    student_name: book.student,
                                                    student_id: book.student_id,
                                                    booking_detail_id: book.booking_detail_id,
                                                    booking_id: book.booking_id,
                                                    new_msgs: book.new_msgs,
                                                    sessions_link: book.sessions_link,
                                                    sessions_alt_link: book.sessions_alt_link
                                                })
                                            })

                                            slots = hlpr_sort_array_by_ref(slots, 'hour')
                                        })
                                    }
                                    {
                                        slots.map((slot, j) => {

                                            return (
                                                <div
                                                    key={j}
                                                    className={`mb-2 px-2 border d-flex flex-column justify-content-start`}
                                                >
                                                    <small>{hlpr_from_to_slot(slot.hour)}</small>
                                                    <small className='txt-light_gray'>{slot.course_name}</small>
                                                    <small className='txt-light_dim_gray'>{slot.student_name}</small>
                                                    <div className='d-flex justify-content-between'>
                                                        {
                                                            can_start && (slot.sessions_link || slot.sessions_alt_link) ?
                                                                <div className='d-flex hand' onClick={() => handle_open_session_link(slot)}>
                                                                    <span target='_blank' className='text-decoration-none txt-deep_sky_blue'>
                                                                        Session Link<SiGooglemeet className='txt-deep_sky_blue m-2 hand' />
                                                                    </span>
                                                                </div>
                                                                :
                                                                <span></span>
                                                        }

                                                        <div className='d-flex justify-content-end'>
                                                            {
                                                                can_cancel &&
                                                                <GiCancel className='txt-light_salmon m-2 hand' onClick={() => handle_view_cancel_slot_confirm(slot.booking_detail_id, slot.course_name, slot.student_name, slot.student_id)} />
                                                            }
                                                            <FaRegEye className='txt-purple m-2 hand' onClick={() => handle_view_slot_info(slot.booking_id, slot.booking_detail_id, slot.student_id)} />
                                                            <div className={`hand ${slot.new_msgs && 'notification'}`} onClick={() => handle_view_chat_modal(slot.booking_id, slot.booking_detail_id, slot.course_name, slot.student_name)}>
                                                                <BsFillChatLeftDotsFill className='txt-goldenrod m-2' />
                                                                <small>{slot.new_msgs}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </SwiperSlide>

                        )
                    })
                }
            </Swiper>

        </>
    )
}
