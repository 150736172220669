// Imgs
import courses_img from '../../assets/imgs/courses_01.jpg';

// Animation
import { AnimationOnScroll } from 'react-animation-on-scroll';

// react-router-dom
import { useNavigate } from 'react-router-dom';

export default function SectionCourses() {
	const navigate = useNavigate();

	return (
		<div className="container mt-16">
			<h2 className="text-4xl text-center">Discover</h2>
			<div className="flex gap-4 flex-col md:flex-row py-5">

				<div className="">
					<AnimationOnScroll animateIn="animate__zoomIn" duration={1}>
						<div className="hand">
							<img className="mw-100 rounded" src={courses_img} />
						</div>
					</AnimationOnScroll>
				</div>

				<div className="md:w-8/12">
					<div>
						<AnimationOnScroll animateIn="animate__zoomIn" duration={1}>
							<h3 className="text-4xl text-blue-700">Our Courses</h3>
						</AnimationOnScroll>

						<AnimationOnScroll animateIn="animate__zoomIn" duration={1}>
							<h6>Unlock Your Potential</h6>
						</AnimationOnScroll>

						<AnimationOnScroll animateIn="animate__zoomIn" duration={1}>
							<h2 className="text-2xl font-extrabold">Learn, Grow, Succeed</h2>
						</AnimationOnScroll>

						<AnimationOnScroll animateIn="animate__zoomIn" duration={1}>
							<p>
								Our dedicated educators are the heart of our Learning Management System. With a passion for teaching and a commitment to excellence, they inspire, mentor, and lead our students toward their goals. Our teachers bring a wealth of knowledge and real-world experience to the classroom, creating an engaging and supportive learning environment. They are here to guide you every step of the way on your educational journey. Discover more about our talented educators and how they can help you achieve your full potential.
							</p>
						</AnimationOnScroll>

						{/* <AnimationOnScroll animateIn="animate__zoomIn" duration={1}>
							<button disabled
								className="px-5 py-2 rounded-md shadow text-white bg-blue-500"
								onClick={() => navigate('/courses')}
							>
								See all courses
							</button>
						</AnimationOnScroll> */}
					</div>
				</div>
			</div>
		</div>
	);
}
