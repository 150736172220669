// React Bootstrap
import { Container } from 'react-bootstrap'

// Components
import AddTeacherForm from '../../Components/Admin/AddTeacherForm';

export default function AddTeacher() {

    return (
        <Container className='mt-4 p-4 bg-cool_gray shadow-sm rounded-3'>
            <AddTeacherForm />
        </Container>
    )
}
