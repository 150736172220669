// Components
import TopNavbar from './TopNavbar'

export default function Header() {
  return (
    <header>
      <TopNavbar />
    </header>
  )
}
