import React, { useState } from 'react';

import { AiFillStar, AiOutlineStar } from 'react-icons/ai'

export default function Stars({ stars, rating }) {

    const [rate, setRate] = useState(0);

    const icons = [];

    const empty_stars = 5 - stars;

    for (let i = 0; i < stars; i++) {
        icons.push(<AiFillStar className='text-warning' key={Math.random() * 1520} />);
    }

    for (let i = 0; i < empty_stars; i++) {
        icons.push(<AiOutlineStar className='text-warning' key={Math.random() * 1520} />);
    }

    return icons;
}
