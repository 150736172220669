import { useState, useEffect } from 'react'

// react-bootstrap
import { Container, ListGroup, ListGroupItem } from 'react-bootstrap'

// APIs
import { API_daily_attendance } from '../../apis'

// function
import { hlpr_from_to_slot } from '../../Helpers/Functions'

export default function Attendance() {

    const [sessions, setSessions] = useState([])

    useEffect(() => {
        fnc_daily_attendance()
    }, [])


    const fnc_daily_attendance = async () => {
        await API_daily_attendance()
            .then(res => setSessions(res))
    }

    const today = new Date();

    return (
        <Container>
            <h2>Attendance</h2>
            {`Today: ${today.getDate()} - ${today.getMonth() + 1} - ${today.getFullYear()}`}
            <ListGroup>
                {
                    sessions.map(({
                        booked_slots,
                        booking_detail_id,
                        booking_id,
                        course_id,
                        course_name,
                        instructor_id,
                        instructor_name,
                        instructor_photo,
                        slot_date,
                        slot_id,
                        slot_times,
                        student_id,
                        student_name,
                        student_photo
                    }) => {
                        const hours = booked_slots.split(',');
                        // console.log('hours', hours)
                        return (
                            <ListGroupItem className='d-flex flex-column'>
                                <div className='border-bottom'>
                                    <h4>{course_name}</h4>
                                </div>
                                <div>
                                    <p>
                                        Instructor: {instructor_name}
                                        <br />
                                        Client: {student_name}
                                    </p>
                                    <p className='d-flex gap-2'>
                                        <span className='p-1'>Slots:</span>
                                        {
                                            hours.map(hour =>
                                                <span className='border p-1 text-danger'>{hlpr_from_to_slot(hour)}</span>
                                            )
                                        }
                                    </p>
                                </div>
                            </ListGroupItem>
                        )
                    }
                    )
                }
            </ListGroup>
        </Container>
    )
}