import React, { useEffect, useState, useRef, useMemo } from 'react'

// React Bootstrap
import { Button, ButtonGroup, Container, Form, FormControl, FormFloating, FormGroup, FormSelect } from 'react-bootstrap'

// APIs
import { API_add_category, API_upload_category_image } from '../../apis';

// Libraries
import { toast } from 'react-toastify';

// Redux
import { useSelector, useDispatch } from 'react-redux';

import { ACTN_add_category } from '../../Redux/CoursesReducer';


// CSS
import '../../scss/Admin/AddCategory.scss'


export default function AddCategory({ default_img }) {

    const [categoryName, setCategoryName] = useState('');

    const [parentId, setParentId] = useState(0);

    const [parentName, setParentName] = useState('');

    const [categoryDescription, setCategoryDescription] = useState('');

    const [categoryImage, setCategoryImage] = useState(null);

    const [categoryImagePreview, setCategoryImagePreview] = useState(default_img);

    const dispatch = useDispatch();

    const { categories } = useSelector(state => state.courses)

    const cat_img = useRef();

    useEffect(() => {

        if (categoryImage) {
            const reader = new FileReader();

            reader.onloadend = e => {
                setCategoryImagePreview(reader.result)
            };

            reader.readAsDataURL(categoryImage);

        } else {
            setCategoryImagePreview('')
        }
    }, [categoryImage]);

    const handle_parent_changed = e => {
        setParentId(e.target.value)
        setParentName(e.target.options[e.target.selectedIndex].text)
    }

    const handle_image_selected = e => {
        const file = e.target.files[0];

        if (file && file.type.substr(0, 5) === 'image') {
            // console.log('YES')
            // console.log(file)
            setCategoryImage(file)
        } else {
            // console.log('NO')
            setCategoryImage(null)
        }
    }

    const handle_add_category = async () => {

        if (categoryName == '') {
            toast.error('Category Name is Required');
            return;
        }

        if (categoryDescription == '') {
            toast.error('Category Description is Required');
            return;
        }

        if (!categoryImage) {
            toast.error('Category Image is Required as a PNG image');
            return;
        }

        // Check if the category is exists
        if (categories.find(category => category.category_name === categoryName)) {
            toast.error('This category is exists. You may want to update it');
            return;
        }

        await API_add_category(categoryName, parentId, categoryDescription)
            .then(async res => {
                if (res) {
                    const category = {
                        category_id: res,
                        category_name: categoryName,
                        category_parent: parentId,
                        parent_name: parentName,
                        category_description: categoryDescription,
                        childs_count: 0,
                        courses_count: 0
                    }

                    setCategoryName('');
                    setParentId(0);
                    setParentName('');
                    setCategoryDescription('');
                    setCategoryImage(null);
                    setCategoryImagePreview(default_img);
                    cat_img.current.value = '';

                    const upload_img = categoryImage;
                    upload_img.upload_name = res;


                    await API_upload_category_image(upload_img)
                        .then(async upload_res => {
                            dispatch(ACTN_add_category(category));
                            if (upload_res) {
                                toast.success('Category Added successfully');
                            }
                        })
                }
            })


    }

    return (

        <Form className='my-3 bg-cool_gray p-3 shadow-sm was-validated' >

            <Container className='bg-white p-4 mt-3 shadow-sm rounded-3'>
                <h3>Add New Category</h3>
            </Container>

            <div className='bg-white p-4 mt-3 shadow-sm rounded-3'>
                <FormFloating className='my-3'>
                    <FormControl type="text" placeholder="Category Name" value={categoryName} onChange={e => setCategoryName(e.target.value)} required />
                    <label>Category Name</label>
                </FormFloating>

                <FormFloating className='my-3'>
                    <FormSelect value={parentId} onChange={e => handle_parent_changed(e)}>
                        <option>Parent Category</option>
                        {categories.map(({ category_id, category_name, category_parent, parent_name }, index) =>
                            category_parent == 0 &&
                            <option key={index} value={category_id}>{category_name}</option>
                        )
                        }
                    </FormSelect>
                    <label>Parent</label>
                </FormFloating>

                <span>Category Description</span>
                <FormFloating className='mb-3'>
                    <FormControl as="textarea" value={categoryDescription} onChange={e => setCategoryDescription(e.target.value)} required />
                </FormFloating>

                <FormGroup>
                    <label>Category Image</label>
                    <FormControl type="file" ref={cat_img} accept='image/png' onChange={handle_image_selected} required />
                </FormGroup>

                {
                    categoryImagePreview &&
                    <div className='category_img_preview'>
                        <img src={categoryImagePreview} />
                    </div>
                }

                <ButtonGroup className='my-3'>
                    <Button variant="primary" type="button" onClick={handle_add_category}>Add</Button>
                </ButtonGroup>

            </div>
        </Form>
    )
}
