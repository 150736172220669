import React from "react"
import ContentLoader from "react-content-loader"

const Skeleton = (props) => (
    <ContentLoader
        speed={5}
        width={'100%'}
        height={124}
        // viewBox="0 0 100% 124"
        backgroundColor="#c2c2c2"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="48" y="8" rx="3" ry="3" width="30%" height="6" />
        <rect x="48" y="26" rx="3" ry="3" width="30%" height="6" />
        <rect x="0" y="56" rx="3" ry="3" width="80%" height="6" />
        <rect x="0" y="72" rx="3" ry="3" width="80%" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="80%" height="6" />
        <circle cx="20" cy="20" r="20" />
    </ContentLoader>
)

export default Skeleton