import { useEffect, useState } from 'react'

// APIs
import { API_get_teacher_students } from '../../apis';

// React Bootstap
import { Alert, Container } from 'react-bootstrap';

// Redux
import { useSelector, useDispatch } from 'react-redux'


// Components
import StudentsList from '../../Components/StudentsList';

export default function Students() {
    const [students, setStudents] = useState([])

    const { id } = useSelector(state => state.user)

    useEffect(() => {
        fnc_get_teacher_students();
    }, [])

    const fnc_get_teacher_students = async () => {
        await API_get_teacher_students(id).then(students => {
            // console.log('students', students)
            setStudents(students)
        })
    }


    if (students.lenght == 0) {
        return <Alert>
            <h2>No Students Yet!!</h2>
        </Alert>
    }

    return (
        <>
            <h2>My Students</h2>
            <StudentsList students={students} />
        </>
    )
}
