import React from 'react'

// react-bootstrap
import { Alert } from 'react-bootstrap'

// Icons
import { AiOutlineArrowLeft } from 'react-icons/ai';

// React Router DOM
import { useLocation, useNavigate } from 'react-router-dom';

export default function IsAdminNotify({ msg }) {

    const navigate = useNavigate();

    return (
        <Alert variant='warning'>
            <h2>You are an admin, this page only for users.</h2>
            <p>{msg}</p>
            <small className='hand alert-link' onClick={() => { navigate(-1) }}><AiOutlineArrowLeft /> Go Back</small>

        </Alert>
    )
}
