import React, { useState, useEffect, useRef } from 'react'
import { Container, FormGroup, Button } from 'react-bootstrap'

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

// Helpers
import { Hours } from "../../Helpers/Hours";
import { date_add, formatDate } from '../../Helpers/Functions';

// APIs
import { API_update_slots } from '../../apis';

// Libraries
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ACTN_set_loading } from '../../Redux/SettingsReducer';

export default function TeacherAvailability({ availableScheduleData }) {

    const [availableSchedule, setAvailableSchedule] = useState([]);

    const teacher_id = useParams()['id'];

    const { app_settings: { payment__general_section_expire_after_days, booking__scheduale_section_availabilty_long } } = useSelector(state => state.settings)

    const dispatch = useDispatch();

    useEffect(() => {
        // console.log('availableScheduleData:::->>>', availableScheduleData)

        if (availableScheduleData.length > 0) {
            availableScheduleData.sort((a, b) => {
                return new Date(a.slot_date) - new Date(b.slot_date);
            })
        }

        const full_schedule = [];

        // const dates_arr = [];

        for (let i = parseInt(payment__general_section_expire_after_days) + 1; i <= parseInt(booking__scheduale_section_availabilty_long); i++) {
            const [d] = formatDate(date_add(i)).split(' ');

            // dates_arr.push(d)

            let slot = availableScheduleData.find(av => av.slot_date == d);

            if (slot == undefined) {
                slot = { slot_date: d, slot_times: null, busy_slots: null, booked_slots: null };
            }

            full_schedule.push(slot)
        }
        // console.log('full_schedule::--::', full_schedule)

        setAvailableSchedule(full_schedule);

        // bookedHours

        // let booked_hours_obj = {};

        // bookedHours
        // bookedSchedule.forEach(book => {
        //     const booked_slots = book.booked_slots.split(',');

        //     if (Array.isArray(booked_hours_obj[book['slot_date']])) {
        //         booked_hours_obj[book['slot_date']].splice(0, 0, booked_slots);
        //     } else {
        //         booked_hours_obj[book['slot_date']] = [booked_slots];
        //     }
        // })

        // setBookedHours(booked_hours_obj)

        // console.log('booked_hours_obj:::...->>>', booked_hours_obj)

    }, [availableScheduleData, payment__general_section_expire_after_days, booking__scheduale_section_availabilty_long]);

    const handle_save_availability = async () => {

        dispatch(ACTN_set_loading(true));

        const slots = JSON.stringify(availableSchedule);

        await API_update_slots(teacher_id, slots).then(res => {

            dispatch(ACTN_set_loading(false));

            if (res == true) {
                toast('Saved Successfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }

    const fnc_sort_array = arr => {
        const sorted = arr.sort((a, b) => {
            return a - b;
        }
        );
        return sorted
    };

    const handle_change = (was_selected, date, hour) => {

        if (!date) {
            debugger
        }

        let new_availableSchedule = availableSchedule.map(({ slot_date, slot_times, booked_slots, busy_slots }) => {

            if (slot_date == date) {

                let times_arr = slot_times ? slot_times.split(',') : [];

                if (was_selected) {
                    times_arr = times_arr.filter(time => time != hour)
                } else {
                    times_arr.push(hour)
                    times_arr = fnc_sort_array(times_arr);
                }

                const newSet = new Set(times_arr)
                times_arr = Array.from(newSet);

                slot_times = times_arr.toString();

            }
            return { slot_date, slot_times, booked_slots, busy_slots }

        })

        setAvailableSchedule(new_availableSchedule);
    };

    // if (availableSchedule.lenght == 0) {
    //     return (<>No Content</>)
    // }

    return (
        <Container>
            <FormGroup className='border shadow-sm py-2 px-3 mx-0 my-3 rounded'>
                <div className='my-3 d-flex justify-content-between align-items-center'>
                    <span>Available Hours</span>
                    <Button variant='primary' onClick={handle_save_availability}>Save</Button>
                </div>

                <Swiper
                    slidesPerView={3}
                    spaceBetween={5}
                    draggable={false}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="rounded d-flex flex-row"
                >
                    {
                        // console.log('availableSchedule....', availableSchedule)
                    }
                    {
                        availableSchedule.map(({ slot_date, slot_times, booked_slots, busy_slots }, i) => {

                            const booked_slots_arr = booked_slots ? booked_slots.split(',') : [];
                            const busy_slots_arr = busy_slots ? busy_slots.split(',') : []

                            const blocked = Array.from(new Set([...booked_slots_arr, ...busy_slots_arr]))

                            const times = slot_times ? slot_times.split(',') : [];

                            return (
                                <SwiperSlide key={i} className='d-flex flex-grow-1 flex-column pb-3 bg-light my-2 rounded'>
                                    <h4>{slot_date}</h4>
                                    <div>

                                        {
                                            Hours.map(([hour, name]) => {

                                                let selected = 'bg-light_grey txt-light_dim_gray',
                                                    active = false,
                                                    booked = false;

                                                // debugger
                                                // const disabled = booked_times.indexOf(hour) >= 0 ? 'disabled' : '';
                                                if (times.indexOf(hour) >= 0) {
                                                    selected = 'bg-primary text-light';
                                                    active = true;
                                                }

                                                if (blocked.indexOf(hour) >= 0) {
                                                    selected = 'bg-disabled-striped text-dark';
                                                    active = false;
                                                }

                                                return (
                                                    <div
                                                        className={`mb-2 px-2 border d-flex flex-column justify-content-start ${booked !== false && 'opacity-50'} ${selected}`}
                                                        style={{ height: '50px' }}
                                                        key={hour}
                                                        onClick={() => handle_change(active, slot_date, hour)}
                                                        data-active={active}
                                                        data-date={slot_date}
                                                        data-hour={hour}
                                                    >

                                                        <small>
                                                            {name}
                                                        </small>

                                                        <small className='align-self-center txt-tomato'>
                                                            {booked}
                                                        </small>
                                                    </div>
                                                )
                                            })}

                                    </div>
                                </SwiperSlide>

                            )
                        })
                    }
                </Swiper>
            </FormGroup>
        </Container >

    )
}
