import { useEffect, useState } from 'react'

// React Bootstrap
import { ListGroupItem, Navbar, Container, Nav } from 'react-bootstrap'

// Icons
import { AiFillMessage, AiTwotoneCalendar, AiOutlineClockCircle, AiOutlineClose, AiFillCreditCard } from 'react-icons/ai'
import { BiCategory } from 'react-icons/bi'
import { BsCardList, BsCardChecklist, BsFillGearFill } from 'react-icons/bs'
import { CgPushChevronRight } from 'react-icons/cg'
import { FaChalkboardTeacher, FaUsers, FaInfo } from 'react-icons/fa'
import { ImUserPlus } from 'react-icons/im'
import { IoIosPeople } from 'react-icons/io'
import { MdPassword, MdLaptopChromebook } from 'react-icons/md'
import { SiDiscourse } from 'react-icons/si'
import { RiListCheck2 } from 'react-icons/ri'

// React Router DOM
import { Link } from 'react-router-dom'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { ACTN_show_login, ACTN_show_register } from '../Redux/SettingsReducer';
import { ACTN_logged_out } from '../Redux/UserReducer';



export default function Sidenav() {

    const [active, setActive] = useState(false);

    const { app_settings } = useSelector(state => state.settings)

    const { name, msgs_count, loggedIn, role, isAdmin, isTeacher } = useSelector(state => state.user)

    const dispatch = useDispatch();


    return (

        <nav className={`sidebar flex justify-between ${active && 'active '}`}>

            <div className="position-sticky flex flex-col justify-between gap-4">

                <div>
                    <div onClick={() => { setActive(!active) }} className='hand sidebar-toggler'>
                        {
                            active ?
                                <AiOutlineClose /> :
                                <CgPushChevronRight />
                        }
                    </div>

                    <div className='sidebar-items flex flex-col px-2 gap-3'>

                        <div className='text-center nav-item'>
                            <Link className='navbar-brand' to="/">{app_settings.app_name}</Link>
                        </div>

                        <div className='text-center nav-item'>
                            <h6 className='navbar-text txt-goldenrod'>{app_settings.greeting_word} {name} 💪</h6>
                        </div>

                        {
                            // For Students
                            !isAdmin &&
                            <>
                                <ListGroupItem>
                                    <Link to='teachers-messages' className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <AiFillMessage />
                                        <h6 className='p-0 my-0 ms-2'>From Teachers {msgs_count.student_new}</h6>
                                    </Link>
                                </ListGroupItem>

                                <ListGroupItem onClick={() => setActive(false)}>
                                    <Link to='/account' className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <AiTwotoneCalendar />
                                        <h6 className='p-0 my-0 ms-2'>My Calendar</h6>
                                    </Link>
                                </ListGroupItem>

                                <ListGroupItem onClick={() => setActive(false)}>
                                    <Link to='/account/bookings' className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <BsCardList />
                                        <h6 className='p-0 my-0 ms-2'>My Bookings</h6>
                                    </Link>
                                </ListGroupItem>

                                <ListGroupItem onClick={() => setActive(false)}>
                                    <Link to='/account/courses' className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <MdLaptopChromebook />
                                        <h6 className='p-0 my-0 ms-2'>Our Courses</h6>
                                    </Link>
                                </ListGroupItem>

                                <ListGroupItem onClick={() => setActive(false)}>
                                    <Link to='/account/teachers' className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <FaUsers />
                                        <h6 className='p-0 my-0 ms-2'>Our Teachers</h6>
                                    </Link>
                                </ListGroupItem>

                                <ListGroupItem onClick={() => setActive(false)}>
                                    <Link to='/account/info' className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <FaInfo />
                                        <h6 className='p-0 my-0 ms-2'>My Info</h6>
                                    </Link>
                                </ListGroupItem>

                                <ListGroupItem onClick={() => setActive(false)}>
                                    <Link to='/account/cards' className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <AiFillCreditCard />
                                        <h6 className='p-0 my-0 ms-2'>My Cards</h6>
                                    </Link>
                                </ListGroupItem>

                                <ListGroupItem onClick={() => setActive(false)}>
                                    <Link to='/account/password' className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <MdPassword />
                                        <h6 className='p-0 my-0 ms-2'>Change Password</h6>
                                    </Link>
                                </ListGroupItem>
                            </>
                        }


                        {
                            // Only Admins
                            isAdmin
                            &&
                            <>
                                <ListGroupItem onClick={() => setActive(false)}>
                                    <Link to='/admin' className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <BsCardList />
                                        <h6 className='p-0 my-0 ms-2'>Bookings</h6>
                                    </Link>
                                </ListGroupItem>

                                <ListGroupItem onClick={() => setActive(false)}>
                                    <Link to='/admin/attendance' className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <RiListCheck2 />
                                        <h6 className='p-0 my-0 ms-2'>Attendance</h6>
                                    </Link>
                                </ListGroupItem>

                                <ListGroupItem onClick={() => setActive(false)}>
                                    <Link to='/admin/categories' className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <BiCategory />
                                        <h6 className='p-0 my-0 ms-2'>Categories</h6>
                                    </Link>
                                </ListGroupItem>

                                <ListGroupItem onClick={() => setActive(false)}>
                                    <Link to='/admin/courses' className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <SiDiscourse />
                                        <h6 className='p-0 my-0 ms-2'>Courses</h6>
                                    </Link>
                                </ListGroupItem>

                                <ListGroupItem onClick={() => setActive(false)}>
                                    <Link to='/admin/teachers' className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <FaChalkboardTeacher />
                                        <h6 className='p-0 my-0 ms-2'>Teachers</h6>
                                    </Link>
                                </ListGroupItem>

                                <ListGroupItem onClick={() => setActive(false)}>
                                    <Link to='/admin/teachers/add' className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <ImUserPlus />
                                        <h6 className='p-0 my-0 ms-2'>Add Teacher</h6>
                                    </Link>
                                </ListGroupItem>

                                <ListGroupItem onClick={() => setActive(false)}>
                                    <Link to='/admin/settings' className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <BsFillGearFill />
                                        <h6 className='p-0 my-0 ms-2'>Settings</h6>
                                    </Link>
                                </ListGroupItem>
                            </>
                        }

                        {
                            // Only Teacher
                            isTeacher &&
                            <>
                                <ListGroupItem onClick={() => setActive(false)}>
                                    <Link to="/account/sessions" className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <BsCardList />
                                        <h6 className='p-0 my-0 ms-2'>My Sessions</h6>
                                    </Link>
                                </ListGroupItem>

                                <ListGroupItem onClick={() => setActive(false)}>
                                    <Link to='/account/students' className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <IoIosPeople />
                                        <h6 className='p-0 my-0 ms-2'>My Students</h6>
                                    </Link>
                                </ListGroupItem>

                                <ListGroupItem onClick={() => setActive(false)}>
                                    <Link to="/account/sessions" className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <AiFillMessage />
                                        <h6 className='p-0 my-0 ms-2'>From Students {msgs_count.teacher_new}</h6>
                                    </Link>
                                </ListGroupItem>

                                <ListGroupItem onClick={() => setActive(false)}>
                                    <Link to="/account/my-skills" className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <BsCardChecklist />
                                        <h6 className='p-0 my-0 ms-2'>My Skills</h6>
                                    </Link>
                                </ListGroupItem>

                                <ListGroupItem onClick={() => setActive(false)}>
                                    <Link to="/account/my-availability" className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                                        <AiOutlineClockCircle />
                                        <h6 className='p-0 my-0 ms-2'>My Availability</h6>
                                    </Link>
                                </ListGroupItem>
                            </>
                        }

                        {/* Not Sorted */}

                        {/* <ListGroupItem onClick={() => setActive(false)}>
                        <Link to='/admin/our-teachers' className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                            <FaUsers />
                            <h6 className='p-0 my-0 ms-2'>Our Teachers</h6>
                        </Link>
                    </ListGroupItem> */}

                        {/* <ListGroupItem onClick={() => setActive(false)}>
                        <Link to='/admin/our-teachers' className='text-decoration-none p-1 d-flex flex-row align-items-center text-dark'>
                            <FaUsers />
                            <h6 className='p-0 my-0 ms-2'>Courses</h6>
                        </Link>
                    </ListGroupItem> */}


                    </div>


                </div>

                <footer className='px-2 mt-3 text-center nav-item'>
                    {
                        loggedIn ?
                            <>
                                <a className='nav-link text-white bg-red-800 p-1 w-10/12 m-auto hand' onClick={() => dispatch(ACTN_logged_out())}>Logout</a>
                            </> :
                            <>
                                <a className='nav-link' onClick={() => dispatch(ACTN_show_login())}>Login</a>
                                <a className='nav-link' onClick={() => dispatch(ACTN_show_register())}>Register</a>
                            </>
                    }


                </footer>
            </div>

            {/* <div onClick={() => { setActive(!active) }} className='hand sidebar-toggler'>
                {
                    active ?
                        <AiOutlineClose /> :
                        <CgPushChevronRight />
                }
            </div> */}
        </nav>


    )
}
