import { useEffect, useState } from 'react'

// react-bootstrap
import { Button, Container, Form } from 'react-bootstrap'

// toastify
import { toast } from 'react-toastify';

// APIs
import { API_change_password } from '../apis';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { ACTN_set_loading } from '../Redux/SettingsReducer';

// consts
import { password_Regex } from '../Helpers/consts';

export default function ChangePassword() {

    const [passwordData, setPasswordData] = useState({
        current: '',
        new: '',
        repeat: '',
    });

    const { id, email, isAdmin } = useSelector(state => state.user)

    const dispatch = useDispatch();

    useEffect(() => {

        return () => {
            setPasswordData({
                current: '',
                new: '',
                repeat: '',
            })
        }
    }, [])

    const handle_data_change = e => {
        const k = e.target.name;
        const v = e.target.value;

        const nv = {};

        nv[k] = v;

        setPasswordData(prev => {
            return {
                ...prev,
                ...nv
            }
        })
    }

    const handle_save_password = async () => {

        const arr = Object.keys(passwordData);
        const errors = [];
        const curPasswordData = passwordData;

        // All required and not Empty
        if (arr.length < 3 || arr.includes('')) {
            errors.push('All Fields are required!');
        }

        // Validate passwords
        // arr.map(k => {
        //     if (!password_Regex.test(passwordData[k])) {
        //         errors.push(`${k} password is not in correct format, Invalid Password, password must contain upper and lower case letters, numbers, and symbols`)
        //     }
        // });

        // Match passwords
        if (passwordData.new !== passwordData.repeat) {
            errors.push('Passwords do not match.');
        }

        // Is new password
        if (passwordData.new === passwordData.current) {
            errors.push('New password is same as current!');
        }

        // display errors if any
        if (errors.length > 0) {
            errors.map(err => {
                toast.error(err);
            })
            return;
        }

        passwordData['isAdmin'] = isAdmin;
        passwordData['email'] = email;
        passwordData['id'] = id;

        dispatch(ACTN_set_loading(true));

        await API_change_password(passwordData).then(res => {
            dispatch(ACTN_set_loading(false));
            if (res === 1) {
                toast.success('Password Updated Successfully');
            } else {
                toast.error(res);
            }
            setPasswordData(curPasswordData);
        })


    }

    return (
        <Container fluid>
            <h2>Change My Password</h2>

            <Form>
                <Form.Floating className='my-3'>
                    <Form.Control type='password' autoComplete='false' name='current' value={passwordData.current} onChange={handle_data_change} placeholder=' ' />
                    <Form.Label>Current Password <span className='text-danger'>*</span></Form.Label>
                </Form.Floating>

                <Form.Floating className='my-3'>
                    <Form.Control type='password' autoComplete='false' name='new' value={passwordData.new} onChange={handle_data_change} placeholder=' ' />
                    <Form.Label>New Password <span className='text-danger'>*</span></Form.Label>
                </Form.Floating>

                <Form.Floating className='my-3'>
                    <Form.Control type='password' autoComplete='false' name='repeat' value={passwordData.repeat} onChange={handle_data_change} placeholder=' ' />
                    <Form.Label>Re-type New Password <span className='text-danger'>*</span></Form.Label>
                </Form.Floating>

                <Button onClick={handle_save_password}>Save New Password</Button>
            </Form>
        </Container>
    )
}
