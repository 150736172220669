export const Hours = [
    ['9', '09 AM'],
    ['10', '10 AM'],
    ['11', '11 AM'],
    ['12', '12 PM'],
    ['13', '01 PM'],
    ['14', '02 PM'],
    ['15', '03 PM'],
    ['16', '04 PM'],
    ['17', '05 PM'],
    ['18', '06 PM'],
    ['19', '07 PM'],
    ['20', '08 PM'],
    ['21', '09 PM'],
    ['22', '10 PM']
];