// Components
import MainHeader from '../Components/MainHeader';
import SectionHero from '../Components/home/SectionHero';
import SectionGoal from '../Components/home/SectionGoal';
import SectionStatistics from '../Components/home/SectionStatistics';
import SectionCourses from '../Components/home/SectionCourses';
import SectionInstructors from '../Components/home/SectionInstructors';
import SectionObjectives from '../Components/home/SectionObjectives';
import ApplyAsInstructor from '../Components/ApplyAsInstructor';
import Faq from '../Components/home/Faq';

export default function Home() {

	return (
		<div className="container-fluid flex gap-4 flex-col p-0">

			{/* Main Navbar */}
			<MainHeader />

			{/* Hero Section */}
			<SectionHero />

			{/* Courses */}
			<SectionCourses />

			{/* Instructors */}
			<SectionInstructors />

		</div>
	);
}
