import { useEffect, useState } from 'react'

// APIs
import { API_get_teacher_student } from '../../apis';

// React Bootstap
import { Alert, Container, ListGroup, ListGroupItem, Table } from 'react-bootstrap';

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { ACTN_set_loading } from '../../Redux/SettingsReducer'

// Images
import female from '../../assets/imgs/female.png'
import male from '../../assets/imgs/male.png'
import { Link } from 'react-router-dom';

export default function Student() {
    const [student, setStudent] = useState(null)
    const [bookings, setBookings] = useState([])

    const dispatch = useDispatch();

    const { id } = useSelector(state => state.user)
    const { current_student: { student_id } } = useSelector(state => state.teacher);

    useEffect(() => {
        fnc_get_teacher_student();
    }, [])

    const fnc_get_teacher_student = async () => {

        dispatch(ACTN_set_loading(true));

        await API_get_teacher_student(id, student_id).then(student => {
            dispatch(ACTN_set_loading(false));
            if (student && student.length > 0) {
                setStudent(student[0])

                let booked = {};

                student.forEach(booking => {
                    if (Array.isArray(booked[booking.booking_id])) {
                        booked[booking.booking_id].splice(0, 0, booking);
                    } else {
                        booked[booking.booking_id] = [booking];
                    }
                })
                // console.log('booked', booked)
                setBookings(booked)
            }

            // console.log('student', student)
        })
    }

    const Booking = ({ details: { book_date, course_id, course_name, hours, rate, notes, booking_id, booking_details_id }, slots }) =>
    (
        <tbody key={booking_details_id}>
            <tr>
                <td data-link={course_id}>{course_name}</td>
                <td>{book_date}</td>
                <td>{hours}</td>
                <td>{rate}</td>
            </tr>
            <tr>
                <td>{notes}</td>
            </tr>
            <tr>
                <th colSpan='1' rowSpan={slots.length + 1} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Schedule</th>
                <th>Date</th>
                <th colSpan='2'>Hours</th>
            </tr>
            <Details slots={slots} />
        </tbody>
    )
    const Details = ({ slots }) =>
        slots.map((slot, i) =>
            <tr key={i}>
                <td>{slot.slot_date}</td>
                <td colSpan='2'>{slot.booked_slots}</td>
            </tr>
        )


    if (student == null)
        return (
            <Alert variant='danger'>
                Sorry, no data to display for this page!!!
                <Link to='/account/students' className='alert-link text-decoration-none'> Go Back</Link>
            </Alert>
        )

    return (
        <>
            <ListGroup>

                <ListGroupItem className='d-flex flex-row align-items-center'>
                    <div className='me-2'>
                        <img className='img-32' src={student.gender == 'Female' ? female : male} alt={student.student_name} />
                    </div>
                    <span>{student.student_name}</span>
                </ListGroupItem>

                <ListGroupItem>
                    {student.nationality}
                </ListGroupItem>
                <ListGroupItem>
                    <p>{student.bio}</p>
                </ListGroupItem>

            </ListGroup>

            <hr />
            <div className='table-responsive'>

                <Table striped>
                    <thead>
                        <tr>
                            <th>Course</th>
                            <th>Booked on</th>
                            <th>Hours</th>
                            <th>Rate</th>
                        </tr>
                    </thead>

                    {
                        Object.entries(bookings).map((booking, i) => {
                            return (
                                <Booking key={i} details={booking[1][0]} slots={booking[1]} />
                            )
                        }
                        )
                    }

                </Table>
            </div>
        </>
    )
}
/*










*/