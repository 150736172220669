import { useEffect, useState } from 'react'

// React Router DOM
import { Outlet, useParams } from 'react-router-dom'

// Components
import Bookings from '../../Components/Admin/Bookings/Bookings'
import Sidenav from '../../Components/Sidenav'

export default function Home() {

    const [page, setPage] = useState('');

    const cpage = useParams();

    useEffect(() => {
        setPage(cpage['*']);
    }, [cpage])

    return (
        <>
            <Sidenav />
            <div className='account-main'>

                {
                    page != '' ?
                        <Outlet />
                        :
                        <Bookings />

                }
            </div>
        </>
    )
}
