import { useState, useEffect } from 'react'

// APIs
import { API_get_student_msgs } from '../../apis'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { ACTN_set_loading } from '../../Redux/SettingsReducer'
import { ACTN_set_chat_details, ACTN_show_chat_modal } from '../../Redux/ChatReducer'

// Icons
import { AiOutlineEye } from 'react-icons/ai';

// react-bootstrap
import { Alert, Container, Table } from 'react-bootstrap';

// Helpers
import { html_table_head } from '../../Helpers/html';

// Images
import female from '../../assets/imgs/female.png';
import male from '../../assets/imgs/male.png';

export default function StudentMsgs() {

    const [msgs, setMsgs] = useState({});
    const [ready, setReady] = useState(false);

    const dispatch = useDispatch();

    const student_id = useSelector(state => state.user.id);

    useEffect(() => {
        fnc_get_student_msgs()
    }, [])

    const fnc_get_student_msgs = async () => {

        dispatch(ACTN_set_loading(true));

        await API_get_student_msgs(student_id).then(res => {
            dispatch(ACTN_set_loading(false));

            // console.log('res', res)

            let slots = {};

            res.forEach(msg => {
                if (Array.isArray(slots[[msg.slot_date]])) {
                    slots[[msg.slot_date]].push(msg);
                } else {
                    slots[[msg.slot_date]] = [msg]
                }
            })

            // console.log('slots', slots)


            setReady(true);
            setMsgs(slots);

        });
    }

    const handle_view_chat_modal = async (booking_id, booking_detail_id) => {
        dispatch(ACTN_set_chat_details({ booking_id, booking_detail_id }));
        dispatch(ACTN_set_loading(true));
        dispatch(ACTN_show_chat_modal(true));
    }

    if (ready && Object.keys(msgs).length == 0) {
        return <Alert variant='info'>
            <h2>No Messages Yet!</h2>
        </Alert>
    }

    return (
        <>
            {
                Object.entries(msgs).map((slot, i) => {
                    // console.log(slot)
                    return (
                        <div key={i} className='border mb-3 shadow mx-4'>
                            <Table>
                                {html_table_head(['Course Name', 'Teacher Name', 'Session Date'])}
                                <tbody>
                                    <tr>
                                        <td>{slot[1][0].course_name}</td>
                                        <td>
                                            <img className='img-32 me-2' src={slot[1][0].gender == 'Female' ? female : male} />
                                            {slot[1][0].teacher_name}
                                        </td>
                                        <td>{slot[0]}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3}>
                                            <Table>
                                                {html_table_head(['From', 'Send Time', 'Full Chat'])}
                                                {
                                                    slot[1].map(({ user_role, sender_name, msg, chat_on, booking_id, booking_detail_id }, j) =>
                                                        <tbody key={j}>

                                                            <tr key={j}>
                                                                <td>
                                                                    <span>{user_role == 'T' ? 'Teacher' : 'Admin'}</span>
                                                                    <img className='img-32 mx-2' src={slot[1][0].gender == 'Female' ? female : male} />
                                                                    <span>{sender_name}</span>
                                                                </td>
                                                                <td>{chat_on}</td>
                                                                <td className=''>
                                                                    <AiOutlineEye className='hand text-primary' onClick={() => handle_view_chat_modal(booking_id, booking_detail_id)} />
                                                                </td>
                                                            </tr>
                                                            <tr className='bg-sky_blue'>
                                                                <td className='bg-sky_blue' colSpan={3}>{msg}</td>
                                                            </tr>
                                                        </tbody>
                                                    )
                                                }
                                            </Table>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    )
                }
                )
            }
        </>
    )
}
