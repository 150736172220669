import Header from "../Components/Header";
import OurCourses from "../Components/OurCourses";
import MainHeader from '../Components/MainHeader'

export default function Courses() {
    return (
        <>
            {/* <Header /> */}
            <MainHeader />
            <OurCourses />
        </>
    )
}

