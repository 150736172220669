import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Routes
import Home from './Home';
import JoinAsTeacher from './JoinAsTeacher';
import Account from './account';
import Teachers from './Teachers';
import Teacher from './Teacher';
import Courses from './Courses';
import TaC from './TaC';
import Privacy from './Privacy';
import ResetPassword from './account/ResetPassword';

import Admin from './Admin';

export default function index() {

    return (
        <Routes>
            <Route path="/join-as-instructor" element={<JoinAsTeacher />} />
            <Route path="/account" element={<Account />} />
            <Route path="/account/*" element={<Account />} />
            <Route path="/account/reset-password/:activationkey" element={<ResetPassword />} />
            <Route path="/teachers/:c_id" element={<Teachers />} />
            <Route path="/teachers" element={<Teachers />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/teacher/:id/:c_id" element={<Teacher />} />
            <Route path="/teacher/:id" element={<Teacher />} />
            <Route path="/admin/*" element={<Admin />} />
            <Route path="/terms-conditions" element={<TaC />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="*" element={<Home />} />
        </Routes>
    )
}
