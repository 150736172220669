import React, { useEffect, useState } from 'react'

// React Bootstrap
import { Button, ButtonGroup, Form, FormControl, FormFloating, FormSelect } from 'react-bootstrap'

// APIs
import { API_add_course } from '../../apis';

// Libraries
import { toast } from 'react-toastify';

// Redux
import { useDispatch } from 'react-redux';

import { ACTN_add_course } from '../../Redux/CoursesReducer';

export default function AddCourse({ childCategories, courses }) {

    const [courseName, setCourseName] = useState('');

    const [categoryId, setCategoryId] = useState(0);

    const [categoryName, setCategoryName] = useState('');

    const [courseDescription, setCourseDescription] = useState('');

    const dispatch = useDispatch();

    useEffect(() => { }, [childCategories]);

    const handle_category_changed = e => {
        setCategoryId(e.target.value)
        setCategoryName(e.target.options[e.target.selectedIndex].text)
    }

    const handle_add_course = async () => {

        if (courseName == '') {
            toast.error('Course name is required');
            return;
        }

        if (categoryId == 0) {
            toast.error('Course category is required');
            return;
        }

        if (courseDescription == '') {
            toast.error('Course description is required');
            return;
        }

        // Check if the course is exists
        if (courses.find(course => course.category_id === categoryId && course.course_name == courseName)) {
            toast.error('This course is exists. You may want to update it');
            return;
        }


        const res = await API_add_course(courseName, categoryId, courseDescription);

        if (res) {
            const course = {
                course_id: res,
                course_name: courseName,
                course_category: categoryId,
                category_name: categoryName,
                course_description: courseDescription
            }

            setCourseName('');
            setCategoryId(0);
            setCategoryName('');
            setCourseDescription('');

            dispatch(ACTN_add_course(course));

            toast.success('Course Added Successfully');
        }

    }

    return (

        <Form className='my-3 bg-light p-5 was-validated'>

            <h3>Add New Course</h3>

            <FormFloating className='my-3'>
                <FormControl required type="text" placeholder="Course Name" value={courseName} onChange={e => setCourseName(e.target.value)} />
                <label>Course Name</label>
            </FormFloating>

            <FormFloating className='my-3'>
                <FormSelect required value={categoryId} onChange={handle_category_changed}>
                    <option value=''>--Select Course Category --</option>
                    {childCategories.map(({ category_id, category_name }, index) => {
                        return (
                            <option key={index} value={category_id}>{category_name}</option>
                        )
                    })
                    }
                </FormSelect>
                <label>Category</label>
            </FormFloating>

            <label>Course Description</label>
            <FormFloating className='mb-3'>
                <FormControl as="textarea" required rows={3} value={courseDescription} onChange={e => setCourseDescription(e.target.value)} />
            </FormFloating>

            <ButtonGroup className='my-3'>
                <Button variant="primary" type="button" onClick={handle_add_course}>Add</Button>
            </ButtonGroup>

        </Form>
    )
}
