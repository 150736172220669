import { createSlice } from '@reduxjs/toolkit';

const name = 'teacher';
const initialState = {
    current_student: {}
}

export const TeacherReducer = createSlice({
    name,
    initialState,
    reducers: {
        ACTN_set_current_student: (state, action) => {
            state.current_student = action.payload
        }
    }
})


export default TeacherReducer.reducer;

export const {
    ACTN_set_current_student
} = TeacherReducer.actions;