import { useEffect, useState } from 'react'

// React Router DOM
import { Outlet, useParams } from 'react-router-dom'

// Redux
import { useSelector } from 'react-redux'

// Components
import Calendar from '../../Components/account/Calendar'
import Sidenav from '../../Components/Sidenav'
import { Alert } from 'react-bootstrap'

export default function Home() {

    const [page, setPage] = useState('');

    const cpage = useParams();

    const { isPendingTeacher } = useSelector(state => state.user)

    useEffect(() => {
        setPage(cpage['*']);
    }, [cpage])


    return (
        <>
            <Sidenav />
            <div className='account-main mt-5'>
                {
                    isPendingTeacher &&
                    <Alert variant='info' className='mt-5'>
                        Your request to join as a teacher is pending.
                    </Alert>
                }
                {
                    page != undefined ?
                        <Outlet />
                        :
                        <Calendar />
                }
            </div>

        </>
    )
}
