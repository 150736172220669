import Header from '../Components/Header';
import OurTeachers from '../Components/OurTeachers';

export default function Teachers() {

    return (
        <>
            <Header />
            <OurTeachers />
        </>
    )
}






