import { useState } from 'react';

// APIs
import { API_get_booking_details, API_get_booking_slot_chat, API_send_booking_slot_chat, API_save_attendance } from '../../apis';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ACTN_show_slot_details, ACTN_set_slot_details } from '../../Redux/SettingsReducer';
import { ACTN_set_msgs_count } from '../../Redux/UserReducer';

// React Router DOM
import { useNavigate } from 'react-router-dom';

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

// Images
import female from '../../assets/imgs/female.png'
import male from '../../assets/imgs/male.png'

// react toastify
import { toast } from 'react-toastify';

// Helpers
import { hlpr_from_to_slot, hlpr_get_user_messages, hlpr_sort_array_by_ref } from '../../Helpers/Functions';

// Icons
import { FaRegEye } from 'react-icons/fa'
import { SiGooglemeet } from 'react-icons/si'
import { BsPencilFill, BsFillChatLeftDotsFill } from 'react-icons/bs'

// React Bootstrap
import { Button, ButtonGroup, CloseButton, Form, FormControl, FormGroup, FormLabel, ListGroup, ListGroupItem, Modal } from 'react-bootstrap';


export default function Schedule({ bookings }) {

    const [viewChatModal, setViewChatModal] = useState(false);
    const [chats, setChats] = useState([]);
    const [chatMsg, setChatMsg] = useState('');
    const [bookingDetailId, setBookingDetailId] = useState('');
    const [sessionNotes, setSessionNotes] = useState('');
    const [sessionLinks, setSessionLinks] = useState({});
    const [showSessionsModal, setShowSessionsModal] = useState(false);

    // console.log('bookings', bookings)

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const user_id = useSelector(state => state.user.id)

    const { show_slot_details } = useSelector(state => state.settings)

    const handle_view_slot_info = async (booking_id, booking_detail_id) => {

        await API_get_booking_details(booking_id, booking_detail_id, user_id).then(res => {

            if (res == false) {
                return
            }

            dispatch(ACTN_set_slot_details(res));
            dispatch(ACTN_show_slot_details(true));

            // console.log('res', res)
        })
    }

    const handle_view_chat_modal = async (booking_id, booking_detail_id) => {

        setBookingDetailId(booking_detail_id);

        await API_get_booking_slot_chat(booking_id, booking_detail_id, user_id, 'S').then(res => {
            hlpr_get_user_messages(dispatch, ACTN_set_msgs_count, user_id)

            setChats(res);


            // console.log('res', res);
        });
        setViewChatModal(true)
    }

    const handle_reschedule_booking = (course_id, teacher_id) => {
        navigate(`/teacher/${teacher_id}/${course_id}`)
    }

    const handle_send_msg = async () => {
        await API_send_booking_slot_chat(chatMsg, user_id, 'S', bookingDetailId).then(res => {
            if (res) {
                setChats([...chats, { user_role: 'S', chat_on: 'NOW', msg: chatMsg }])
            }
        })
    }

    const handle_open_session_link = async ({ booking_detail_id, booking_id, course_id, course_name, hour, new_msgs, sessions_alt_link, sessions_link, student_id }) => {

        setBookingDetailId(booking_detail_id);
        setSessionLinks({ sessions_alt_link, sessions_link });

        const data = { booking_detail_id }

        await API_save_attendance(data)
            .then(res => {
                if (res) {
                    setShowSessionsModal(true);
                } else {
                    toast.error('Something went wrong, please reload the page and try again or contact system administrator.')
                    return;
                }
            });
    }

    const handle_add_session_notes = async () => {

        const data = { notes: sessionNotes, booking_detail_id: bookingDetailId }

        await API_save_attendance(data)
            .then(res => {
                if (res) {
                    toast.success('Notes added successfully.')
                } else {
                    toast.success('Notes did not added successfully, please try again.')
                }
                setShowSessionsModal(false)
                setSessionNotes('')
            })
    }


    return (
        <>
            {/* Chat Modal */}
            <Modal show={viewChatModal}>
                <Modal.Header>
                    <CloseButton onClick={() => setViewChatModal(false)}></CloseButton>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <FormLabel>New Message</FormLabel>
                        <FormControl as='textarea' value={chatMsg} onChange={e => setChatMsg(e.target.value)} />
                    </FormGroup>
                    <ButtonGroup className='my-3'>
                        <Button onClick={handle_send_msg}>Send</Button>
                    </ButtonGroup>
                    <ListGroup style={{ overflow: 'auto', height: '100%' }}>
                        {
                            chats.map((chat, i) =>
                                <ListGroupItem key={i} className='mb-3 d-flex flex-column shadow-sm rounded'>
                                    <div className='d-flex flex-column border-bottom'>
                                        <div className='d-flex flex-row '>
                                            <div className='d-flex flex-column align-items-center'>
                                                <img src={chat.gender == 'Female' ? female : male} alt={chat.gender} className='img-32' />
                                                <small>{chat.user_role == 'T' ? 'Teacher' : chat.user_role == 'S' ? 'Me' : 'Admin'}</small>

                                            </div>
                                            <small className='ms-2'>{chat.user_name}</small>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column flex-grow-1'>
                                        <p>{chat.msg}</p>
                                        <div className='d-flex flex-row justify-content-between'>
                                            <small>
                                                SEEN BY: <span>{chat.admin_seen == 1 && 'Admin'} - {chat.teacher_seen == 1 && 'Teacher'}</span>
                                            </small>
                                            <small className='fs-05 align-self-end'>{chat.chat_on}</small>
                                        </div>
                                    </div>

                                    <div>
                                    </div>
                                </ListGroupItem>
                            )
                        }
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setViewChatModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>


            {/* Sessions Modal */}
            <Modal show={showSessionsModal}>
                <Modal.Header closeButton>Start Your Session</Modal.Header>
                <Modal.Body>

                    <div target='_blank' className='text-decoration-none txt-deep_sky_blue'>
                        <a target='_blank' href={sessionLinks.sessions_link}>Link 1<SiGooglemeet className='txt-deep_sky_blue m-2 hand' /></a>
                        <a target='_blank' href={sessionLinks.sessions_alt_link}>Link 2<SiGooglemeet className='txt-deep_sky_blue m-2 hand' /></a>
                    </div>

                    <Form.Label>Want to add some notes?</Form.Label>
                    <Form.Floating>
                        <Form.Control as='textarea' value={sessionNotes} onChange={e => setSessionNotes(e.target.value)} />
                    </Form.Floating>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='success' onClick={handle_add_session_notes}>Save notes and close</Button>
                    <Button variant='danger' onClick={() => setShowSessionsModal(false)}>No, just close</Button>
                </Modal.Footer>
            </Modal>

            <Swiper
                slidesPerView={3}
                spaceBetween={5}

                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="rounded d-flex flex-row"
            >

                {
                    Object.entries(bookings).map((booking, i) => {

                        const session_date = booking[0];
                        const session_day = new Date(session_date).setHours(0, 0, 0, 0);
                        const today = new Date().setHours(0, 0, 0, 0);

                        const can_cancel = session_day > today;
                        const can_start = session_day == today;

                        let slots = [];

                        return (
                            <SwiperSlide key={i} className='d-flex flex-grow-1 flex-column pb-3 bg-light my-2 rounded'>
                                <h4>{booking[0]}</h4>
                                <div>
                                    {
                                        booking[1].map((book, j) => {

                                            let booked_slots = book.booked_slots.split(',');

                                            booked_slots.forEach(slt => {
                                                slots.push({
                                                    hour: slt,
                                                    course_name: book.course_name,
                                                    course_id: book.course_id,
                                                    teacher: book.teacher,
                                                    teacher_id: book.teacher_id,
                                                    booking_detail_id: book.booking_detail_id,
                                                    booking_id: book.booking_id,
                                                    new_msgs: book.new_msgs,
                                                    sessions_link: book.sessions_link,
                                                    sessions_alt_link: book.sessions_alt_link
                                                })
                                            })

                                            slots = hlpr_sort_array_by_ref(slots, 'hour')
                                        })
                                    }
                                    {
                                        slots.map((slot, j) => {

                                            return (
                                                <div
                                                    key={j}
                                                    className={`mb-2 px-2 border d-flex flex-column justify-content-start`}
                                                >
                                                    <small>{hlpr_from_to_slot(slot.hour)}</small>
                                                    <small className='txt-light_gray'>{slot.course_name}</small>
                                                    <small className='txt-light_dim_gray'>{slot.teacher}</small>
                                                    <div className='d-flex justify-content-between'>
                                                        {
                                                            can_start && (slot.sessions_link || slot.sessions_alt_link) ?
                                                                <div className='d-flex hand' onClick={() => handle_open_session_link(slot)}>
                                                                    <span target='_blank' className='text-decoration-none txt-deep_sky_blue'>
                                                                        Session Link<SiGooglemeet className='txt-deep_sky_blue m-2 hand' />
                                                                    </span>
                                                                </div>
                                                                :
                                                                <span></span>
                                                        }
                                                        <div className='d-flex justify-content-end'>
                                                            {
                                                                (Date.parse(booking[0]) > Date.parse(new Date()) + 1000 * 60 * 60 * 24) &&
                                                                <BsPencilFill className='txt-light_salmon m-2 hand' onClick={() => handle_reschedule_booking(slot.course_id, slot.teacher_id)} />
                                                            }
                                                            <FaRegEye className='txt-purple m-2 hand' onClick={() => handle_view_slot_info(slot.booking_id, slot.booking_detail_id)} />
                                                            <div className={`hand ${slot.new_msgs && 'notification'}`} onClick={() => handle_view_chat_modal(slot.booking_id, slot.booking_detail_id)}>
                                                                <BsFillChatLeftDotsFill className='txt-goldenrod m-2' />
                                                                <small>{slot.new_msgs}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </SwiperSlide>

                        )
                    })
                }
            </Swiper>
        </>
    )
}