// react-bootstrap
import { Container } from 'react-bootstrap'

// Components
import ChangePasswordForm from '../../Components/ChangePasswordForm'

export default function ChangePassword() {

    return (
        <ChangePasswordForm />
    )
}
