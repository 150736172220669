import React, { useEffect, useState } from 'react'

// Components
import Stars from './Stars';

// Icons
import { BsCameraVideoFill } from 'react-icons/bs'
import { MdDoubleArrow } from 'react-icons/md'

// Images
import female from '../assets/imgs/female.png'
import male from '../assets/imgs/male.png'

// Helpers
import { get_video_part } from '../Helpers/Functions'

// Redux
import { useSelector } from 'react-redux';
import store from '../Redux/store';
import { ACTN_show_login } from '../Redux/SettingsReducer';

// Libraries
import { useNavigate } from 'react-router-dom';

export default function TeacherCard({ teacher, courses, show_enroll_button = true }) {

    const [longBio, setLongBio] = useState(true)

    const [readMore, setReadMore] = useState(true)

    const [skills, setSkills] = useState([]);

    const [video, setVideo] = useState('');

    const navigate = useNavigate();

    const { loggedIn } = useSelector(state => state.user)

    const handle_view_profile = () => {
        const url = `${window.location.href.indexOf('account') >= 0 ? '/account' : ''}/teacher/${teacher.member_id}`;
        navigate(url)
    }

    const bioLength = 130;

    useEffect(() => {

        if (undefined != teacher) {

            // Skills
            let skills = '';
            try {
                skills = JSON.parse((teacher.skills).replace(/&quot;/g, '"'));
            } catch (e) {
                skills = [];
            }

            const skills_arr = [];

            skills.map(skl => {
                const skl_id = Object.keys(skl)[0];

                const course = courses.find(({ course_id, course_name }) => {

                    if (skl_id == course_id) {
                        return course_name
                    }
                });

                skills_arr.push(course.course_name);
            });

            setSkills(skills_arr);

            // Bio
            setLongBio(teacher.bio.length > bioLength);

            // promo_video

            setVideo(get_video_part(teacher.promo_video))


        }

    }, []);

    return (
        <div className='teacher_box col-12' >
            <div className='teacher'>
                <div className='avatar'>
                    <img src={teacher.gender == 'Female' ? female : male}
                        className='' alt='teacher' />
                </div>

                <div className='about' id={teacher.instructor_id}>
                    <h3> {teacher.first_name} {teacher.middle_name} {teacher.last_name}</h3>
                    <Stars stars={4} />
                    <h4>{teacher.nationality}</h4>
                </div>
            </div>

            <div className='courses'>
                <div className='courses_inner'>
                    {
                        skills.map((skill, i) =>
                            <p key={i}>
                                <span>
                                    {skill}
                                </span>
                            </p>
                        )
                    }
                </div>
            </div>
            {
                teacher.promo_video &&
                <details className='detials-video'>
                    <summary><BsCameraVideoFill /> Video</summary>
                    <div className='ratio ratio-16x9 my-3 '>

                        <iframe className='embed-responsive-itemx' src={`https://www.youtube.com/embed/${video}`} title={teacher.first_name} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                        </iframe>
                    </div>
                </details>
            }
            <div className='bio'>
                <div>
                    {

                        readMore ?
                            (
                                <>
                                    {(teacher.bio).substr(0, bioLength)}
                                    {longBio && <span className='text-success fw-bolder hand' onClick={() => setReadMore(!readMore)} > Read More...</span>}
                                </>
                            ) : (
                                <>
                                    {teacher.bio}
                                    {longBio && <span className='text-warning fw-bolder hand' onClick={() => setReadMore(!readMore)} > Read Less</span>}
                                </>
                            )

                    }
                </div>
            </div>

            {
                (loggedIn && show_enroll_button) &&
                <div className='btns'>
                    <button className='btn_login_to_enroll' onClick={handle_view_profile}>
                        <span>Enroll Now</span>
                        <span><MdDoubleArrow /></span>
                    </button>
                </div>
            }
            {!loggedIn &&
                <div className='btns'>
                    <button className='btn_login_to_enroll' onClick={() => store.dispatch(ACTN_show_login(true))}>
                        <span>Login to Enroll</span>
                        <span><MdDoubleArrow /></span>
                    </button>
                </div>
            }
        </div>
    )
}

