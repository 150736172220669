import { useEffect, useState } from 'react';

// APIs
import { API_student_info, API_save_member_info } from '../../apis';

// React-bootstrap
import { Button, Form } from 'react-bootstrap';

// Toast
import { toast } from 'react-toastify';

// Redux
import { useSelector, useDispatch } from 'react-redux';
// import { ACTN_set_loading } from '../../Redux/SettingsReducer';
import { ACTN_update_user } from '../../Redux/UserReducer';
import IsAdminNotify from '../../Components/Admin/IsAdminNotify';

export default function Info() {
    const [info, setInfo] = useState({});

    const [error, setError] = useState('');

    const member_id = useSelector(state => state.user.id)

    const { email, isAdmin, isTeacher } = useSelector(state => state.user)

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isAdmin) {
            // dispatch(ACTN_set_loading(true));

            fnc_member_info();
        }
    }, [member_id])

    const fnc_member_info = async () => {

        if (!member_id) {
            setError('Cannot verify your account, please reload the page and try again.')
            return
        }

        await API_student_info(member_id, isTeacher)
            .then(res => {

                if (res == false) {
                    setError('Something went wrong, please reload the page and try again.')
                    return;
                }
                setInfo(res);
            })
    }

    const handle_update_info = async e => {

        const k = e.target.name;

        const nv = {};

        nv[k] = e.target.value;

        setInfo(prevInfo => {
            return {
                ...prevInfo,
                ...nv
            }
        });
    }

    const handle_save_info = async () => {

        if (isAdmin) {
            toast.success('This is the after save message')
            return
        }

        // Check if any is empty
        if (Object.values(info).includes('')) {
            toast.error('All Fields are required!');
            return;
        }

        // dispatch(ACTN_set_loading(true));
        // Send to save API
        await API_save_member_info({ ...info, isTeacher })
            .then(res => {
                // dispatch(ACTN_set_loading(false));
                if (res) {
                    toast.success('Your info saved successfully!')
                    return;
                }
                toast.error('Something went wrong, please reload the page and try again!')
            })

        // Update Redux user info
        dispatch(ACTN_update_user({
            name: info['First Name'],
            gender: info['Gender']
        }));
    }

    {
        if (Object.keys(info).length == 0) {
            return <>Nothing to show</>
        }
    }

    return (
        <>
            <h2>My Info</h2>

            {
                isAdmin &&
                <IsAdminNotify msg='The page will look like this' />
            }

            <Form >
                <Form.Floating className="my-2">
                    <p className='form-control bg-light'>{email}</p>
                    <Form.Label>Email</Form.Label>
                </Form.Floating>

                <hr />

                <Form.Floating className="my-2">
                    <Form.Control name='first_name' onChange={handle_update_info} value={info['first_name']} placeholder=" " />
                    <Form.Label>First Name</Form.Label>
                </Form.Floating>

                <Form.Floating className="my-2">
                    <Form.Control name='middle_name' onChange={handle_update_info} value={info['middle_name']} placeholder=" " />
                    <Form.Label>Middle Name</Form.Label>
                </Form.Floating>

                <Form.Floating className="my-2">
                    <Form.Control name='last_name' onChange={handle_update_info} value={info['last_name']} placeholder=" " />
                    <Form.Label>Last Name</Form.Label>
                </Form.Floating>

                <Form.Floating className="my-2">
                    <Form.Control name='nationality' onChange={handle_update_info} value={info['nationality']} placeholder=" " />
                    <Form.Label>Nationality</Form.Label>
                </Form.Floating>

                <Form.Floating className="my-2">
                    <Form.Select name='gender' onChange={handle_update_info} value={info['gender']}>
                        <option value=''>Please Select Your Gender</option>
                        <option value='Female'>Female</option>
                        <option value='Male'>Male</option>
                    </Form.Select>
                    <Form.Label>Gender</Form.Label>
                </Form.Floating>

                {
                    isTeacher &&
                    <Form.Floating className="my-2">
                        <textarea name='bio' role='textarea' className='form-control' style={{ minHeight: '200px' }} onChange={handle_update_info} value={info['bio']} placeholder=" "></textarea>
                        <Form.Label>Bio</Form.Label>
                    </Form.Floating>
                }

                <p>{error}</p>

                <Form.Group>
                    <Button onClick={handle_save_info}>Save</Button>
                </Form.Group>

            </Form>
        </>
    )
}
