import { createSlice } from '@reduxjs/toolkit';

const name = 'vars';
const initialState = {
	app_title: 'Learn 4 Skills',
	app_description: 'Learn 4 Skills',
	app_keywords: 'Learn 4 Skills',
	app_author: 'MWJB WEB SOLUTIONS',
	app_copyright: 'Learn 4 Skills',
};

const VarsReducer = createSlice({
	name,
	initialState,
	reducers: {},
});

export default VarsReducer.reducer;
