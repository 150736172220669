import { useState, useEffect } from 'react'

// react-bootstrap
import { Alert, Button, Container, FormControl, FormLabel, Modal } from 'react-bootstrap'

// APIs
import { API_get_vodafone_pay_settings, API_create_new_booking, API_save_vodafone_payment_reference } from '../../apis'

// react router DOM
import { useNavigate } from 'react-router-dom'

// Redux
import { useSelector } from 'react-redux';

// images
import vfLogo from '../../assets/imgs/vfcash.png';

export default function VodafoneForm({ totalFees, booking_data, hide_vodafone_form, showVodafonePaymentModal, setShowVodafonePaymentModal }) {

    const [vfSettings, setVfSettings] = useState([]);
    const [booked, setBooked] = useState(false);
    const [paymentReference, setPaymentReference] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [done, setDone] = useState(false);

    useEffect(() => {
        fnc_get_vodafone_pay_settings()
    }, [])

    const navigate = useNavigate();

    const { id, name } = useSelector(state => state.user);
    const { app_settings: { payment__general_section_expire_after_days, payment__fawry_section_enable_fawry, payment__fawry_section_merchant_code } } = useSelector(state => state.settings);

    const amount = parseFloat(totalFees).toFixed(2).toString();

    const fnc_get_vodafone_pay_settings = async () => {
        await API_get_vodafone_pay_settings()
            .then(settings => setVfSettings(settings))
    }

    const handle_book_now = async () => {

        let initData = { ...booking_data, ...{ id, name, amount, paymentOption: 2 } }

        await API_create_new_booking(initData)
            .then(async data => {

                const booking_id = data.booking_id;

                if (booking_id) {
                    setBooked(true);
                }
            })
    }

    const handle_save_payment_reference = async () => {
        if (paymentReference == '') {
            setErrorMsg('Add the payment reference number to approve the payment.')
            return;
        }

        let initData = { ...booking_data, ...{ id, name, amount, paymentOption: 2, paymentReference } }
        await API_save_vodafone_payment_reference(initData)
            .then(res => {
                if (res) {
                    setDone(true);
                }
            })
    }

    const handle_reload_the_page = () => {
        window.location.reload();
    }

    return (
        <Modal scrollable={true} show={showVodafonePaymentModal} backdrop='static' onHide={() => setShowVodafonePaymentModal(false)} keyboard={false} >
            <Modal.Header closeButton={!booked && !done} className='d-flex align-items-center justify-content-between'>
                <img src={vfLogo} width='30' />
                {booking_data.update ?
                    <span>Confirm Payment</span>
                    : <span>Pay Your Booking Fees by Vodafone</span>
                }
            </Modal.Header>
            {
                done &&
                <Container fluid className='py-5'>
                    <Alert variant='info'>Payment reference added successfully, you will receive a payment confirmation soon.</Alert>
                    <Button onClick={handle_reload_the_page}>OK</Button>
                </Container>
            }
            {
                !done &&
                <Modal.Body className='d-flex flex-column align-items-center text-center'>
                    {
                        booking_data.update ?
                            <p>Confirm payment a total of</p>
                            : <p>You should pay a total of</p>
                    }
                    <h4>EGP {totalFees}</h4>
                    <p>to wallet number</p>
                    <h3>{vfSettings.vf_account}</h3>
                    <img src={vfLogo} />
                    {
                        !booking_data.update &&
                        <p>then navigate to your booking page to add the payment reference code</p>
                    }
                    <Alert variant='warning'>Booking will expire after {payment__general_section_expire_after_days} day(s) from booking</Alert>
                    {
                        booked &&
                        <>
                            <p className='text-success'>Your Booking succussfully created and is pending for {payment__general_section_expire_after_days} day(s)</p>
                            <Button onClick={() => navigate('/account/bookings')}>Check My Bookings</Button>
                        </>
                    }
                    {
                        booking_data.update &&
                        <div className='my-3'>
                            <FormLabel>Payment Reference Code</FormLabel>
                            <FormControl value={paymentReference} onChange={e => setPaymentReference(e.target.value)} />
                            <p className='text-danger'>{errorMsg}</p>
                            {
                                paymentReference &&
                                <Button variant='success' onClick={handle_save_payment_reference}>Save Payment Reference Code</Button>
                            }
                        </div>
                    }
                    {
                        (!booked && !booking_data.update) &&
                        <Button onClick={handle_book_now}>Complete Booking</Button>
                    }
                </Modal.Body>
            }
        </Modal>
    )
}
