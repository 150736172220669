import { useEffect, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

// Images
import female from '../../assets/imgs/female.png'
import male from '../../assets/imgs/male.png'

// Helpers
import { get_video_part } from '../../Helpers/Functions'

// Redux
import { useSelector } from 'react-redux'


export default function TeacherSummary() {

    const [video, setVideo] = useState('');

    const { first_name, middle_name, last_name, gender, bio, nationality, promo_video } = useSelector(state => state.user.current_teacher)

    useMemo(() => {
        setVideo(get_video_part(promo_video));
    }, [promo_video])

    return (
        <Container className='mt-3'>
            <Row className='border-bottom mb-5'>
                <Col md={2} className='d-flex flex-column align-items-center'>
                    <div className='avatar'>
                        <img src={gender == 'Female' ? female : male}
                            className='' alt='teacher' />
                    </div>
                    <h5 className='text-center mt-3'>{gender}</h5>
                    {
                        promo_video &&
                        <div className='ratio ratio-16x9 my-3 '>
                            <iframe className='embed-responsive-itemx' src={`https://www.youtube.com/embed/${video}`} title={first_name} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                            </iframe>
                        </div>
                    }

                </Col>
                <Col md={9} className='d-flex flex-column mt-4 mt-md-0'>
                    <h1>{first_name} {middle_name} {last_name}</h1>

                    <hr className='m-0' />
                    <h6 className='align-self-end'>{nationality}</h6>
                    <p className='mt-3'>
                        {bio}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>

                </Col>
            </Row>

            <Row>

            </Row>
        </Container>
    )
}
