import { useEffect } from 'react';

// react-router-dom
import { Routes, Route } from 'react-router-dom';

// Routes
import Home from './Home';
import StudentMsgs from './StudentMsgs';
import Info from './Info';
import PaymentCards from './PaymentCards';
import Students from './Students';
import Student from './Student';
import Bookings from './Bookings';
import Teachers from './Teachers';
import Courses from './Courses';
import Teacher from './Teacher';
import Sessions from './Sessions';
import Availability from './Availability';
import Skills from './Skills';
import ChangePassword from './ChangePassword';

//Redux
import { useSelector, useDispatch } from 'react-redux'
import { ACTN_set_msgs_count } from '../../Redux/UserReducer';

// Components
import LoginRequest from '../../Components/User/LoginRequest';
import IsAdminNotify from '../../Components/Admin/IsAdminNotify';
import { hlpr_get_user_messages } from '../../Helpers/Functions';
import Header from '../../Components/Header';
import { Container } from 'react-bootstrap';

export default function Index() {

    const { loggedIn, isAdmin, id } = useSelector(state => {
        // console.log('Account index page->>>state.user:::', state.user)
        return state.user
    });


    const dispatch = useDispatch();

    useEffect(() => {
        // console.log('index in account-> user (id)=', id)
        // debugger
        hlpr_get_user_messages(dispatch, ACTN_set_msgs_count, id)
    }, [])

    if (!loggedIn) {
        return (
            <>
                <Header />
                <LoginRequest msg="Please login First" />
            </>
        )
    }

    if (isAdmin) return <IsAdminNotify msg='' />

    return (
        <Container>
            <Routes>
                <Route path="/" element={<Home />} >
                    <Route path="/teachers-messages" element={<StudentMsgs />} />
                    <Route path="/sessions" element={<Sessions />} />
                    <Route path="/students" element={<Students />} />
                    <Route path="/student" element={<Student />} />
                    <Route path="/bookings" element={<Bookings />} />
                    <Route path="/teachers" element={<Teachers />} />
                    <Route path="/courses" element={<Courses />} />
                    <Route path="/teacher/:id" element={<Teacher />} />
                    <Route path="/info" element={<Info />} />
                    <Route path="/cards" element={<PaymentCards />} />
                    <Route path="/my-skills" element={<Skills />} />
                    <Route path="/my-availability" element={<Availability />} />
                    <Route path="/password" element={<ChangePassword />} />
                    <Route path="*" element={<Home />} />
                </Route>
            </Routes >
        </Container>

    )
}
